import { Axios } from "../lib/axios";
export const BenchmarkServices = {
  getBenchmarks,
  getCategories,
  getBenchmarkDetails,
  getBenchmarkContent,
};
function getBenchmarks(url) {
  return Axios.get(url);
}
function getCategories(url) {
  return Axios.get(
    "https://jsonblob.com/api/4fc0ad9c-77d8-11ea-aec7-fb5b61093b79"
  );
}
function getBenchmarkDetails(benchmarkId) {
  return Axios.get(`benchmark/${benchmarkId}`);
}
function getBenchmarkContent(benchmarkId) {
  return Axios.get(`benchmark/${benchmarkId}/content`);
}
