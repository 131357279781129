import momentWithZone from "moment-timezone";
import moment from "moment";
function ComapnyDetailsDateTimeFormatter(date, showTimeZone = true) {
  let userTimezone = moment.tz.guess();
  let userTimezoneShortCode = moment().tz(userTimezone).zoneAbbr();
  return `${momentWithZone(date)
    .tz(userTimezone)
    .format("MMMM DD, YYYY HH:mm A")} ${
    showTimeZone ? userTimezoneShortCode : ""
  }`;
}

export default ComapnyDetailsDateTimeFormatter;
