import React from "react";
import { DropdownList } from "react-widgets";
import "react-widgets/dist/css/react-widgets.css";

const CustomDropdownList = (props) => {
  return (
    <DropdownList
      filter={props.filter === false ? false : true}
      data={props.data}
      valueField="id"
      textField="name"
      onChange={(value) => props.handleChange(value)}
      defaultValue={
        props.data.find((d) => d.is_selected === true)
          ? props.data.find((d) => d.is_selected === true).id
          : null
      }
      value={
        props.data.find((d) => d.is_selected === true)
          ? props.data.find((d) => d.is_selected === true).id
          : null
      }
      placeholder={props.placeholder ? props.placeholder : ""}
      disabled={props.isDisabled ? props.isDisabled : false}
      allowCreate={props.allowCreate ? props.allowCreate : false}
      onCreate={(name) => props.handleCreate(name)}
      containerClassName={props.className ? props.className : ""}
      itemComponent={props.itemComponent ? props.itemComponent : null}
    />
  );
};

export default CustomDropdownList;
