import { downloadProgress as DETAILS } from "../Reducers/actionConstants";

const downloadingProgressModel = (payload) => {
  return {
    type: DETAILS.DOWNLOAD_PROGRESS_MODEL,
    payload,
  };
};

const downloadFileDetails = (payload) => {
  return {
    type: DETAILS.DOWNLOAD_FILE_DETAILS,
    payload,
  };
};

const downloadingProgress = (payload) => {
  return {
    type: DETAILS.DOWNLOADING_PROGRESS,
    payload,
  };
};

const downloadingProgressId = (payload) => {
  return {
    type: DETAILS.DOWNLOADING_PROGRESS_ID,
    payload,
  };
};

export {
  downloadingProgressModel,
  downloadFileDetails,
  downloadingProgress,
  downloadingProgressId,
};
