import decodeJWT from "../lib/decodeJWT";

function CheckUserRole(role) {
  let roleDetails = decodeJWT(localStorage.getItem("token")).role;
  if (roleDetails.short_name === role) {
    return true;
  }
  return false;
}
export default CheckUserRole;
