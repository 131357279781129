import { Axios } from "../lib/axios";
export const ProjectServices = {
  getProjects,
  getCategories,
  getProjectDetails,
  getProjectContent,
};
function getProjects(url) {
  return Axios.get(url);
}
function getCategories(url) {
  return Axios.get(
    "https://jsonblob.com/api/4fc0ad9c-77d8-11ea-aec7-fb5b61093b79"
  );
}
function getProjectDetails(projectId) {
  return Axios.get(`project/${projectId}`);
}
function getProjectContent(projectId) {
  return Axios.get(`project/${projectId}/content`);
}
