import { notification as NOTIFICATION } from "../Reducers/actionConstants";

const notifications = (payload) => {
  return {
    type: NOTIFICATION.NOTIFICATION_LIST,
    payload,
  };
};

const notificationItem = (payload) => {
  return {
    type: NOTIFICATION.NOTIFICATION_ITEM,
    payload,
  };
};

export { notifications, notificationItem };
