import constants from "../Utils/constants";

function CustomEllipsis(message) {
  return {
    text:
      message?.length > constants.ELLIPSIS.TEXT_MAX_LENGTH
        ? `${message.substring(0, constants.ELLIPSIS.TEXT_MAX_LENGTH)}...`
        : message,
    ellipsis: message?.length > constants.ELLIPSIS.TEXT_MAX_LENGTH,
    fullText: message,
  };
}

export default CustomEllipsis;
