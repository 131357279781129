import React, { Component } from "react";
import constants from "../Utils/constants";
import CustomPagination from "./Pagination";

class DynamicTableComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startRowCount: 1,
      endRowCount: 10,
    };
    this.scrollRef = React.createRef();
    this.tableContainerRef = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => {
      let rowDatalength =
        this.props?.rowData && this.props?.rowData?.length < 10
          ? this.props?.rowData?.length
          : 10;
      this.setState({ endRowCount: rowDatalength });
    }, 500);
  }

  handleScrollOnGesture = () => {
    let startRow = 1;
    let endRow =
      this.props?.rowData && this.props?.rowData?.length < 10
        ? this.props?.rowData?.length
        : 10;
    const scrollOffset = this.tableContainerRef.current.scrollTop;
    const scrollRowCount = Math.floor(scrollOffset / 40);
    let startRowIndex = startRow + scrollRowCount;
    let endRowIndex = endRow + scrollRowCount;
    this.setState({ startRowCount: startRowIndex, endRowCount: endRowIndex });
  };

  totalPages = () => {
    if (this.props.count === 0) {
      return 1;
    }
    let totPages = parseInt(Math.ceil(this.props.count / this.props.pageSize));
    return Number.isNaN(totPages) ? 1 : totPages;
  };
  getPagingRange = (current, { min = 1, total = 20, length = 5 } = {}) => {
    if (length > total) length = total;
    let start = current - Math.floor(length / 2);
    start = Math.max(start, min);
    start = Math.min(start, min + total - length);
    return Array.from({ length: length }, (el, i) => start + i);
  };
  handlePageSize = (e) => {
    this.props.handlePageSize(e);
  };
  render() {
    return (
      <>
        <div
          ref={this.tableContainerRef}
          onScroll={this.handleScrollOnGesture}
          className={`responsive-table scroll-table-wrapper ${this.props.tableHeight}`}
        >
          <table
            className={`${
              this.props.table && this.props.table.className
                ? this.props.table.className
                : ""
            } responsive nowrap`}
            cellSpacing="0"
            width="100%"
          >
            <thead>
              <tr role="column">
                {this.props?.columnData?.map((column, key) => (
                  <th
                    style={column.style ? column.style : {}}
                    key={key}
                    className={`${column.className ? column.className : ""} ${
                      this.props.sortColumn === column.id
                        ? this.props.sortOrder
                        : column.sortable
                        ? "default"
                        : null
                    } ${column.headerClass ? column.headerClass : null}`}
                    onClick={() => {
                      column.sortable && this.props.handleSort(column.id, key);
                    }}
                    data-testid="cells"
                  >
                    <div className="th-content">{column.title}</div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {this.props.rowData?.length > 0 &&
                this.props.rowData?.map((row, index) => (
                  <tr
                    ref={this.scrollRef}
                    role="rows"
                    key={index}
                    className={
                      row.custom_classes ? row.custom_classes.join(" ") : ""
                    }
                  >
                    {this.props?.columnData?.map((val, i) => (
                      <td
                        style={val.rowStyle ? val.rowStyle : {}}
                        key={`${index + "-" + i}`}
                        className={val.className ? val.className : ""}
                      >
                        {row[val.id]}
                      </td>
                    ))}
                  </tr>
                ))}
              {this.props.rowData?.length === 0 ? (
                this.props.isFileTable ? (
                  <tr>
                    <td
                      className={`no-records text-center`}
                      colSpan={this.props.columnData.length}
                    >
                      <img
                        className="mt-4"
                        src="https://tbr-ggk.s3.us-east-2.amazonaws.com/development+/FilesNoRecordFound.svg"
                        alt="Data not available"
                      />
                      <h3 className="files-no-record-found-text">
                        No record found
                      </h3>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td
                      className={`no-records text-center`}
                      colSpan={this.props?.columnData?.length}
                    >
                      {constants.PAGINATION.NO_RECORDS_FOUND}
                    </td>
                  </tr>
                )
              ) : (
                ""
              )}
            </tbody>
          </table>
        </div>

        {this.props.showPagination && (
          <>
            <CustomPagination
              className={this.props.paginationClass}
              handlePage={this.props.handlePage}
              totalPages={this.totalPages}
              handlePageSize={this.handlePageSize}
              pageSize={this.props.pageSize}
              pageNo={this.props.pageNo}
              startRowCount={this.state.startRowCount}
              endRowCount={this.state.endRowCount}
              rowData={this.props.rowData}
            />
          </>
        )}
      </>
    );
  }
}

export default DynamicTableComponent;
