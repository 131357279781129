import React, { Component } from "react";
import login_building from "../Images/login_building.png";
import login_map from "../Images/login_map.png";
import logo from "../Images/INSIGHTCENTER@2x.png";
import TBRLogo from "../Images/TBRLogo";
import constants from "../Utils/constants";
import { Container, Row, Form } from "reactstrap";
import { UserServices } from "../Services/User";
import Notification from "../Components/Notification";
import { formatRoute } from "react-router-named-routes";
import DocumentTitle from "../Utils/DocumentTitle";
import Toast from "../Utils/Toast";

class VerifyEmail extends Component {
  state = {
    apiError: null,
    isVerified: false,
    successMessage: "",
  };

  redirectTimeout = null;

  componentDidMount() {
    DocumentTitle("Account Verification");
    this.handleEmailVerification();
  }

  componentWillUnmount() {
    if (this.redirectTimeout) {
      clearTimeout(this.redirectTimeout);
    }
  }

  handleEmailVerification = () => {
    const code = this.props.match.params.code;

    // Log the user out if a token is present
    if (localStorage.getItem("token")) {
      this.logoutUser();
    }

    // Verify the email with the provided code
    UserServices.verifyEmail(code)
      .then(() => {
        this.setState({
          isVerified: true,
          successMessage: constants.VERIFY_EMAIL.SUCCESS,
        });
        this.redirectAfterDelay(
          constants.APPLICATION_ROUTE.RESET_PASSWORD.ROUTE,
          { code }
        );
      })
      .catch((error) => {
        const message =
          error?.data?.error || constants.ERROR.SOMETHING_WENT_WRONG;

        if (error.status === 451) {
          // User is active but hasn't set a password
          this.setState({
            isVerified: true,
            successMessage: constants.VERIFY_EMAIL.SUCCESS2,
          });
          this.redirectAfterDelay(
            constants.APPLICATION_ROUTE.RESET_PASSWORD.ROUTE,
            { code }
          );
        } else if (error.status === 450) {
          // User has already set a password
          this.setState({ apiError: message });
          this.redirectAfterDelay(constants.APPLICATION_ROUTE.LOGIN.ROUTE);
        } else {
          this.setState({ apiError: message });
        }
      });
  };

  logoutUser = () => {
    UserServices.getUserLogout()
      .then(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
      })
      .catch((error) => {
        const message =
          error?.data?.error || constants.ERROR.SOMETHING_WENT_WRONG;
        Notification.show({ message, type: "error" });
      });
  };

  redirectAfterDelay = (route, params = {}) => {
    this.redirectTimeout = setTimeout(() => {
      this.props.history.push(formatRoute(route, params));
    }, 3000);
  };

  render() {
    const { apiError, isVerified, successMessage } = this.state;

    return (
      <div className="wrapper login_page">
        <main>
          <Container fluid>
            <Row>
              {/* Left Sidebar */}
              <aside className="col-md-6 px-0 hide-in-mobille">
                <div className="login_left">
                  <figure className="login_banner">
                    <img
                      src={login_building}
                      className="img-fluid"
                      alt="login banner"
                      title="login banner"
                    />
                  </figure>
                  <a href="/">
                    <TBRLogo />
                    <span>{constants.PAGE.LOGIN.LOGO_TEXT}</span>
                  </a>
                </div>
              </aside>

              {/* Right Content */}
              <aside className="col-md-6 px-0">
                <div className="login_right">
                  <figure>
                    <img
                      src={login_map}
                      className="img-fluid"
                      alt="map Banner"
                    />
                  </figure>
                  <Form method="post">
                    <span className="login_formlogos">
                      <img src={logo} className="img-fluid" alt="logo" />
                    </span>
                    {/* API Error Notification */}
                    {apiError && (
                      <Notification color="danger" message={apiError} />
                    )}
                    {/* Success Message */}
                    {isVerified && (
                      <h2 className="verify_email_success">{successMessage}</h2>
                    )}
                  </Form>
                </div>
              </aside>
            </Row>
          </Container>
        </main>
      </div>
    );
  }
}

export default VerifyEmail;
