import axios from "axios";

let baseURL;
baseURL = process.env.REACT_APP_TBRI_CLIENT_API_URL;

let AxiosTBR;

const init = () => {
  AxiosTBR = axios.create({
    baseURL: baseURL,
    timeout: 60000,
  });
  AxiosTBR.interceptors.request.use(handleSuccessRequest, handleErrorRequest);
  AxiosTBR.interceptors.response.use(handleSuccess, handleError);
};

const handleSuccessRequest = (request) => {
  document.body.classList.add("loading-indicator");
  if (localStorage.getItem("tbr_token"))
    request.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "tbr_token"
    )}`;
  return request;
};

const handleErrorRequest = (error) => {
  document.body.classList.remove("loading-indicator");
  return Promise.reject(error);
};

const handleSuccess = (response) => {
  document.body.classList.remove("loading-indicator");
  return response;
};

const handleError = (error) => {
  document.body.classList.remove("loading-indicator");
  if (error.message === "Network Error") {
    // The user doesn't have internet
    return Promise.reject(error);
  }
  try {
    switch (error.response.status) {
      case 400:
        break;
      case 401:
        //Un authorized
        // localStorage.removeItem("tbr_token")
        // window.location.href = formatRoute(constants.APPLICATION_ROUTE.LOGIN.ROUTE, {})
        break;
      case 404:
        // Show 404 page
        break;
      case 500:
        // Serveur Error redirect to 500
        break;
      default:
        // Unknow Error
        break;
    }
  } catch (e) {}
  return Promise.reject(error);
};

init();

export { AxiosTBR, baseURL, init };
