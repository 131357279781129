import { Axios } from "../lib/axios";
export const VisualizationServicesV2 = {
  getSegments,
  getCompanies,
  getSegmentsByCompany,
  getMetricsBycompanyAndSegment,
  getGeoByCompanySegmentAndMetrics,
  getVerticalByCompanySegmentAndMetrics,
  fetchChartRules,
  getCompanyBySegment,
  getMetrics,
  getChartData,
  saveOrPublishChart,
  getChartDataByid,
  fetchExportData,
  getTexonomyByCompany,
};

function getSegments() {
  return Axios.post("/data-visualization/segments");
}
function getCompanies() {
  return Axios.post("/data-visualization/company");
}

function getMetrics() {
  return Axios.post("/data-visualization/metrics");
}
function getSegmentsByCompany(body) {
  return Axios.post("/data-visualization/segments", body);
}
function getTexonomyByCompany(body) {
  return Axios.post("/metric-groups/segmented-views/list", body);
}

function getMetricsBycompanyAndSegment(body) {
  return Axios.post("/data-visualization/metrics", body);
}

function getGeoByCompanySegmentAndMetrics(body) {
  return Axios.post("/data-visualization/geos", body);
}

function getVerticalByCompanySegmentAndMetrics(body) {
  return Axios.post("/data-visualization/verticals", body);
}

function fetchChartRules(body) {
  return Axios.post("/data-visualization/chart-rules", body);
}

function getCompanyBySegment(body) {
  return Axios.post("/data-visualization/company", body);
}

function getChartData(url, body) {
  return Axios.post(url, body);
}
function saveOrPublishChart(input) {
  return Axios.post("/visualization_chart/saveChartData", input);
}

function getChartDataByid(id) {
  return Axios.get(`visualization_chart/${id}`);
}

function fetchExportData(body) {
  return Axios.post("/visualization_chart/exportChartData", body);
}
