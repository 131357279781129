import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { activeMenuTab } from "../../Store/Actions/User";
import constants from "../../Utils/constants";
import Resources from "./Resources";
import Faqs from "./Faqs";
import "./ResourceAndFaq.scss";

export const ResourcesAndFaqs = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      activeMenuTab(constants.APPLICATION_ROUTE.RESOURCES_AND_FAQS.LIST.ROUTE)
    );
  }, []);
  return (
    <aside className="main_content my-boards">
      <div className="m-3 mb-0 bg-white br_8 main_content_height main_div">
        <Resources />
        <Faqs />
      </div>
    </aside>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ResourcesAndFaqs);
