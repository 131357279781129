import { combineReducers } from "redux";
import * as DETAILS_REF from "./reducerReference/clientReference";

const clientRoles = DETAILS_REF.clientRoles;

const clientReducer = combineReducers({
  clientRoles,
});

export default clientReducer;
