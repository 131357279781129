import { combineReducers } from "redux";
import * as DETAILS_REF from "./reducerReference/marketLandscapeDetailsReference";

const marketLandscapeCategories = DETAILS_REF.marketLandscapeCategories;
const marketLandscapeDetails = DETAILS_REF.marketLandscapeDetails;
const categories = DETAILS_REF.categories;
const marketLandscapeContent = DETAILS_REF.marketLandscapeContent;

const marketLandscapeDetailsReducer = combineReducers({
  marketLandscapeCategories,
  marketLandscapeDetails,
  categories,
  marketLandscapeContent,
});

export default marketLandscapeDetailsReducer;
