import React, { useState, useEffect, useRef } from "react";
import CustomDigestButton from "../Components/CustomDigestButton";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import PreferenceCenterReportSettings from "./PreferenceCenterReportSettings";
import constants from "../Utils/constants";
import { PreferenceServices } from "../Services/PreferenceServices";
import Toast from "../Utils/Toast";
import { BenchmarkServices } from "../Services/Benchmark";
import { MarketForecastServices } from "../Services/MarketForecast";
import { MarketLandscapeServices } from "../Services/MarketLandscape";
import { SpecialReportsServices } from "../Services/SpecialReports";
import { CustomerStudiesServices } from "../Services/CustomerStudies";
import { EcosystemServices } from "../Services/Ecosystem";
import { each, map } from "lodash";
import decodeJWT from "../lib/decodeJWT";

const PreferenceCenterSettings = ({
  reportTabSections,
  setReportTabSections,
  multiSelectReportOptions,
  setMultiSelectReportOptions,
  preventUnsavedChangesModal,
  moveToNextPage,
  cancelDialog,
  enableSaveButton,
  setEnableSaveButton,
  showUnsubscribeModal,
  hideUnsubscribeModal,
  setChangesIsHappened,
}) => {
  const [frequencyType, setFrequencyType] = useState(constants.PC.IMMEDIATE);
  const [frequency, setFrequency] = useState(0); //1-daily, 7-weekly, 0-none
  const prevFrequency = useRef(null);
  const prevFrequencyType = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [prevFreqState, setPrevFreqState] = useState("");
  const [prevFreqTypeState, setPrevFreqTypeState] = useState("");
  const [segDataWithPermission, setSegDataWithPermission] = useState([]);

  useEffect(() => {
    prevFrequency.current = frequency;
    prevFrequencyType.current = frequencyType;
  }, [frequency, frequencyType]);

  useEffect(() => {
    fetchAllReportSegmentData();
  }, []);

  useEffect(() => {
    updateSegmentDataWithPreferences();
  }, [segDataWithPermission]);

  const fetchAllReportSegmentData = async () => {
    try {
      const companiesSegment = await PreferenceServices.getAllSegments();
      const modifiedCmpSegment = modifiedAPIDataToSegmentData(
        companiesSegment?.data?.data
      );

      const benchmarkSegment = await BenchmarkServices.getBenchmarks(
        "/benchmarks"
      );
      const modifiedBmSegment = combineSameSegmentData(
        benchmarkSegment?.data?.data
      );

      const marketForecastSegment =
        await MarketForecastServices.getMarketForecasts("/marketforecasts");
      const modifiedMFSegment = combineSameSegmentData(
        marketForecastSegment?.data?.data
      );

      const marketLandscapeSegment =
        await MarketLandscapeServices.getMarketLandscapes("/marketlandscapes");
      const modifiedMLSegment = combineSameSegmentData(
        marketLandscapeSegment?.data?.data
      );

      const customerStudySegment =
        await CustomerStudiesServices.getCustomerStudies(
          "/customer-study/list"
        );
      const modifiedCSSegment = combineSameSegmentData(
        customerStudySegment?.data?.data
      );

      const ecosystemSegment = await EcosystemServices.getEcosystem(
        "/ecosystem/list"
      );
      const modifiedECOSegment = combineSameSegmentData(
        ecosystemSegment?.data?.data
      );

      const specialReportSegment =
        await SpecialReportsServices.getSpecialReports("/specialreports");
      const modifiedSRSegment = combineSameSegmentData(
        specialReportSegment?.data?.data
      );

      const allSegmentsData = {
        company: modifiedCmpSegment,
        benchmark: modifiedBmSegment,
        market_forecast: modifiedMFSegment,
        market_landscape: modifiedMLSegment,
        customer_study: modifiedCSSegment,
        ecosystem: modifiedECOSegment,
        special_report: modifiedSRSegment,
      };

      checkSegmentAccessByUserPermission(allSegmentsData);
    } catch (error) {
      Toast(
        error && error.data && error.data.error
          ? error.data.error
          : constants.ERROR.SOMETHING_WENT_WRONG,
        "error"
      );
    }
  };

  const modifiedAPIDataToSegmentData = (data = []) => {
    return data.map((item) => ({
      id: item.id,
      name: item.name,
      is_checked: false,
      is_arrow_selected: false,
      is_access: true,
      reports: item.companies.map((comp) => ({
        name: comp.name,
        id: comp.id,
        is_selected: true,
        is_access: true,
      })),
    }));
  };

  const combineSameSegmentData = (reportData = []) => {
    const uniqueSegments = {};
    reportData.forEach((report) => {
      const { id, name } = report.segment;
      if (!uniqueSegments[id]) {
        uniqueSegments[id] = {
          id,
          name,
          companies: [],
        };
      }
      uniqueSegments[id].companies.push({
        id: report.id,
        name: report.name,
      });
    });
    const result = Object.values(uniqueSegments);
    return modifiedAPIDataToSegmentData(result);
  };

  const checkSegmentAccessByUserPermission = async (segmentsData) => {
    const permissionResponse = await PreferenceServices.getClientPermissions();
    const userPermission = permissionResponse.data;
    const updatedSegDataWithPermission = map(
      segmentsData,
      (segmentData, reportName) => {
        if (reportName === "company") {
          return updateSegmentAccessForCompanies(segmentData, userPermission);
        } else {
          const specificReportData =
            reportName === "customer_study" || reportName === "ecosystem"
              ? userPermission[`${reportName}_mappings`][`${reportName}`]
              : userPermission[`${reportName}_mappings`][`${reportName}s`];
          return updateSegmentAccessForOtherReport(
            segmentData,
            userPermission[`${reportName}_mappings`],
            specificReportData
          );
        }
      }
    );
    setSegDataWithPermission(updatedSegDataWithPermission);
  };

  const updateSegmentAccessForOtherReport = (
    segmentData,
    permission,
    specificReportData
  ) => {
    let tokenDetails = decodeJWT(localStorage.getItem("token"));
    let isAdmin = tokenDetails?.user?.is_admin;
    if (permission?.allow_all || isAdmin) {
      return segmentData;
    } else {
      const segData1 = combineSameSegmentData(specificReportData);
      const segData2 = segmentData.filter((segment) =>
        permission.segments.some((seg) => seg.id === segment.id)
      );
      return comparedAndProvideAccess(segmentData, [...segData1, ...segData2]);
    }
  };

  const updateSegmentAccessForCompanies = (companiesSegment, permission) => {
    if (permission.allow_all_companies && permission.allow_all_segments) {
      return companiesSegment;
    } else {
      let updatedCompaniesSegment = companiesSegment.map((seg) => ({
        ...seg,
        is_access: false,
        reports: seg.reports.map((report) => ({
          ...report,
          is_access: false,
        })),
      }));
      // Updating Companies [Segment & Report] Permission (3 Cases take place)
      updatedCompaniesSegment = updatedCompaniesSegment.map((segment) => {
        // Case 1: segment -> all || report -> adding limited
        if (permission.companies.length > 0) {
          segment.reports = segment.reports.map((report) => {
            const company = permission.companies.find(
              (comp) => comp.id === report.id
            );
            if (company) {
              report.is_access = true;
              segment.is_access = true;
            }
            return report;
          });
        }
        // Case 2: segment -> one || report -> adding all
        if (permission.segments.length > 0) {
          updateCompaniesPermission(segment, permission.segments, false);
        }
        // Case 3: segment -> limited || report -> adding limited
        if (permission.company_segment.length > 0) {
          updateCompaniesPermission(segment, permission.company_segment, true);
        }
        return segment;
      });
      return updatedCompaniesSegment;
    }
  };

  const updateCompaniesPermission = (segment, permissionData, condition) => {
    const matchingSegment = permissionData.find((seg) => seg.id === segment.id);
    if (matchingSegment) {
      segment.is_access = true;
      segment.reports = segment.reports.map((report) => {
        if (condition) {
          const matchingReport = matchingSegment.companies.find(
            (cmp) => cmp.id === report.id
          );
          if (matchingReport) {
            report.is_access = true;
          }
        } else {
          report.is_access = true;
        }
        return report;
      });
    }
  };

  const comparedAndProvideAccess = (originalData, accessData) => {
    const updatedAccessData = originalData.map((orgData) => {
      const matchingAccessData = accessData.find(
        (accData) => orgData.id === accData.id
      );
      if (matchingAccessData) {
        orgData.is_access = true;
        orgData.reports.forEach((orgReport) => {
          const matchingReport = matchingAccessData.reports.find(
            (accReport) => orgReport.id === accReport.id
          );
          orgReport.is_access = matchingReport ? true : false;
        });
      } else {
        orgData.is_access = false;
        orgData.reports.forEach((orgReport) => {
          orgReport.is_access = false;
        });
      }
      return orgData;
    });
    return updatedAccessData;
  };

  const updateSegmentDataWithPreferences = async () => {
    const preferenceResponse = await PreferenceServices.getClientPreferences();
    const preferenceData = preferenceResponse.data.data;
    setFrequency(preferenceData.frequency);
    setFrequencyType(preferenceData.type);
    const updatePreferenceData = reportTabSections.map((tab, index) => {
      return addSegmentDataToOriginalObject(tab, index, preferenceData);
    });
    setReportTabSections(updatePreferenceData);
  };

  const addSegmentDataToOriginalObject = (tab, index, preferenceData) => {
    const preferenceRelation = preferenceData.preferences.find(
      (pref) => pref.relation === tab.slug
    );
    const selectedPreference =
      preferenceRelation === undefined
        ? []
        : JSON.parse(preferenceRelation?.entities_json);
    const segmentData = segDataWithPermission[index];
    const updatedSegmentData = segmentData?.map((segment) => {
      const prefObject = selectedPreference.find(
        (pref) => pref.lob_id === segment.id
      );
      if (prefObject && segment.is_access) {
        segment.is_checked = true;
        segment.reports = segment.reports.map((report) => {
          const prefReport = prefObject?.relation_ids?.find(
            (id) => id === report.id
          );
          if (prefReport && report.is_access) {
            report.is_selected = true;
          } else report.is_selected = false;
          return report;
        });
      } else segment.is_checked = false;

      return segment;
    });
    // updating preference_action
    tab.preference_actions = tab.preference_actions.map((action) => ({
      ...action,
      is_selected: preferenceRelation?.published || false,
    }));
    tab.segmentData =
      updatedSegmentData === undefined ? [] : updatedSegmentData;
    return tab;
  };

  // save user preference data on save button
  const saveUserPreferences = () => {
    if (multiSelectReportOptions.length > 0) {
      applyPreferenceToSelectedReports();
    }
    setTimeout(() => {
      const packet = {
        type: frequencyType,
        frequency: frequencyType === constants.PC.IMMEDIATE ? 0 : frequency,
        preferences: convertToSavePreferencesData(),
      };
      PreferenceServices.setClientPreferences(packet)
        .then((res) => {
          setMultiSelectReportOptions([]);
          updateSegmentDataWithPreferences();
          setChangesIsHappened(false);
          Toast(res.data.message, "success");
        })
        .catch((error) => {
          Toast(
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
            "error"
          );
        });
    }, 500);
  };

  // convert to body data (set preferences)
  const convertToSavePreferencesData = () => {
    const preferenceData = reportTabSections.map((report) => {
      const entitiesJson = report?.segmentData
        ?.filter((segment) => segment.is_checked)
        .map((segment) => {
          const companies = segment.reports
            .filter((comp) => comp.is_access && comp.is_selected)
            .map((comp) => comp.id);

          return {
            lob_id: segment.id,
            relation_ids: companies,
          };
        });
      return {
        relation: report.slug,
        published: report.preference_actions[0].is_selected ? 1 : 0,
        entities_json: entitiesJson,
      };
    });
    return preferenceData;
  };

  // set the preferences for below selected report
  const applyPreferenceToSelectedReports = () => {
    const currentSelectedReport = reportTabSections.find(
      (item) => item.is_selected === true
    );
    const updatedReportTab = reportTabSections.map((reportTab) => {
      multiSelectReportOptions.forEach((selectedReport) => {
        if (selectedReport.label === reportTab.name) {
          reportTab.preference_actions[0].is_selected =
            currentSelectedReport.preference_actions[0].is_selected;
          currentSelectedReport.segmentData.forEach((segment) => {
            if (segment.is_checked) {
              const segmentItem = reportTab.segmentData.find(
                (seg) => seg.id === segment.id
              );
              if (segmentItem && segmentItem.is_access) {
                segmentItem.is_checked = true;
              }
            }
          });
        }
      });
      return reportTab;
    });
    setReportTabSections(updatedReportTab);
  };

  const resetPreferencesSetting = () => {
    updateSegmentDataWithPreferences();
    setMultiSelectReportOptions([]);
    setChangesIsHappened(false);
  };

  // reset the preferences data on reset button
  const resetUserPreferences = () => {
    setTimeout(() => {
      const packet = {
        type: "immediate",
        frequency: 0,
        preferences: convertToSavePreferencesData(),
      };
      PreferenceServices.setClientPreferences(packet)
        .then((res) => {
          updateSegmentDataWithPreferences();
          Toast(res.data.message, "success");
        })
        .catch((error) => {
          Toast(
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
            "error"
          );
        });
    }, 500);
  };

  const saveModalFrequency = () => {
    setFrequencyType(constants.PC.DIGEST);
    setOpenModal(!openModal);
  };

  const closeModal = () => {
    if (prevFreqTypeState === constants.PC.IMMEDIATE) {
      setFrequencyType(constants.PC.IMMEDIATE);
    } else {
      setFrequency(prevFreqState);
    }
    setOpenModal(!openModal);
  };

  const setTheFrequency = (event) => {
    setPrevFreqState(prevFrequency.current);
    setFrequency(event);
  };

  const setTheFrequencyType = (payload) => {
    if (payload === constants.PC.DIGEST) {
      setOpenModal(!openModal);
    }
    setPrevFreqTypeState(prevFrequencyType.current);
    setFrequencyType(payload);
  };

  //handle selection of multiple option.
  const handleSelectMultipleReport = (selectedOption) => {
    setEnableSaveButton(true);
    setChangesIsHappened(true);
    setMultiSelectReportOptions([...selectedOption]);
  };

  const moveToNextReportTab = () => {
    moveToNextPage("not-direct", "");
    setMultiSelectReportOptions([]);
    resetPreferencesSetting();
  };

  return (
    <div className="preference-content">
      <div className="pref-content-header">
        <div>
          <h3 className="pref-email-head">Email Notifications</h3>
          <p className="pref-email-para">
            Receive a compiled email notifications.
          </p>
        </div>
        <CustomDigestButton
          frequencyType={frequencyType}
          setTheFrequencyType={setTheFrequencyType}
          openModal={openModal}
          closeModal={closeModal}
          frequency={frequency}
          setTheFrequency={setTheFrequency}
          saveModalFrequency={saveModalFrequency}
        />
      </div>
      <div className="pref-header-divider"></div>

      <div className="pref-scroller">
        {reportTabSections.map(
          (report) =>
            report.is_selected && (
              <PreferenceCenterReportSettings
                reportTabSections={reportTabSections}
                setReportTabSections={setReportTabSections}
                selectedReportTab={report}
                multiSelectReportOptions={multiSelectReportOptions}
                handleSelectMultipleReport={handleSelectMultipleReport}
                setEnableSaveButton={setEnableSaveButton}
                setChangesIsHappened={setChangesIsHappened}
              />
            )
        )}
      </div>
      <div className="d-flex justify-content-end mt-3">
        <Button
          color="primary"
          className="modal-btn btn-outline-primary"
          onClick={resetPreferencesSetting}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          className="modal-btn"
          type="submit"
          disabled={!enableSaveButton}
          onClick={saveUserPreferences}
        >
          Save
        </Button>
      </div>

      <Modal
        isOpen={preventUnsavedChangesModal}
        className={`modal-confirm modal-sm custom-modal`}
      >
        <ModalHeader>
          <div className="">
            <span
              onClick={cancelDialog}
              style={{ color: "#555555", float: "right", cursor: "Pointer" }}
              className="material-icons"
            >
              close
            </span>
          </div>
          <h2 className="text-center">You want to move</h2>
        </ModalHeader>
        <ModalBody>
          <h3>Changes you made may not be saved.</h3>
        </ModalBody>

        <ModalFooter>
          <Button
            className="btn-outline-primary modal-btn"
            onClick={cancelDialog}
          >
            Cancel
          </Button>{" "}
          <Button
            color="primary"
            className="modal-right-btn modal-btn"
            onClick={moveToNextReportTab}
          >
            Confirm
          </Button>
        </ModalFooter>
      </Modal>

      {/* unsubscribe modal */}
      <Modal
        isOpen={showUnsubscribeModal}
        className={"modal-sm modal-w-header custom-modal primary-modal"}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <ModalHeader>Warning</ModalHeader>
          <span
            onClick={hideUnsubscribeModal}
            style={{ color: "#555555", float: "right", cursor: "Pointer" }}
            className="material-icons"
          >
            close
          </span>
        </div>
        <ModalBody>
          <div className="pref-modal-content">
            <div
              style={{
                backgroundColor: "#fff3cd",
                border: "1px solid #ffeeba",
                borderRadius: "0.25rem",
                padding: "0.55rem 1.15rem",
                maxWidth: "100%",
              }}
            >
              <p
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  maxWidth: "100%",
                  marginBottom: "0px",
                  color: "#856404",
                }}
              >
                Are you sure you want to Unsubscribe?
              </p>
            </div>
          </div>
        </ModalBody>
        <div className=" d-flex justify-content-center">
          <ModalFooter>
            <Button
              className="modal-btn btn-outline-primary"
              onClick={hideUnsubscribeModal}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                resetUserPreferences();
                hideUnsubscribeModal();
              }}
              className="modal-btn"
              type="submit"
              style={{
                backgroundColor: "#856404",
                color: "white",
                border: "1px solid #856404",
              }}
            >
              Yes
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </div>
  );
};

export default PreferenceCenterSettings;
