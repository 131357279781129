import React, { Component } from "react";
import { connect } from "react-redux";
import { formatRoute } from "react-router-named-routes";
import {
  Button,
  ButtonToggle,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import Notification from "../Components/Notification";
import logo from "../Images/INSIGHTCENTER@2x.png";
import login_building from "../Images/login_building.png";
import login_map from "../Images/login_map.png";
import { UserServices } from "../Services/User";
import {
  loginUserFailure,
  loginUserSuccess,
  openSearchBar,
} from "../Store/Actions/User";
import constants from "../Utils/constants";
import DocumentTitle from "../Utils/DocumentTitle";
import ReactGA from "react-ga4";
import React_GA_Intialise from "../Utils/InitializeGa";
import decodeJWT from "../lib/decodeJWT.js";
import { FeedBackService } from "../Services/FeedBack.js";
import axios from "axios";
import { acquireTokenByCode, callMsGraph } from "../Auth/graph.js";
import {
  downloadFileDetails,
  downloadingProgressId,
  downloadingProgressModel,
} from "../Store/Actions/DownloadProgress.js";
const clientId = process.env.REACT_APP_SSO_CLIENT_ID;
const tenantID = process.env.REACT_APP_SSO_TENANT_ID;
const redirectURI = process.env.REACT_APP_SSO_REDIRECT_URI;

class Login extends Component {
  constructor(props) {
    super(props);
    this.getUserInfo = this.getUserInfo.bind(this);
  }
  state = {
    loginEmail: "",
    loginPassword: "",
    apiErrors: "",
    sso_flag: false,
  };

  componentDidMount() {
    DocumentTitle("Login");
  }

  async getUserInfo(input) {
    localStorage.removeItem("show_feedback_form");
    UserServices.getUserLogin(input)
      .then(async (data) => {
        localStorage.setItem("clientId", data.data.data.clientId);
        localStorage.setItem("token", data.data.data.token.access_token);
        localStorage.setItem(
          "refresh_token",
          data.data.data.token.refresh_token
        );
        localStorage.setItem(
          "display_name",
          data?.data?.data?.display_name ?? data?.data?.data?.first_name
        );
        this.props.loginSuccess(data.data.data);
        this.props.downloadingProgressModel(false);
        this.props.downloadingProgressId(null);
        this.props.downloadFileDetails({});

        let feedBackFromRes = await FeedBackService.showFeedBack();
        if (feedBackFromRes.data.data) {
          localStorage.setItem(
            "show_feedback_form",
            feedBackFromRes?.data?.data
          );
        }

        // check if he is admin
        // set the initialisation with user Id
        let is_admin = data.data.data.is_admin;

        if (!is_admin) {
          React_GA_Intialise(data.data.data.id);
          ReactGA.event(constants.GA.CUSTOM_EVENTS.LOGIN);
        }

        const logindata = this.props?.home?.loginUserSuccess;
        const tokenDetails = decodeJWT(localStorage.getItem("token"));
        const urls = [
          "https://test.tbrinsightcenter.com",
          "http://localhost:3000",
          "https://staging.tbrinsightcenter.com",
        ];
        if (urls.includes(window.location.href)) {
          window.Produktly.identifyUser(logindata?.id, {
            name: `${logindata?.first_name} ${logindata?.last_name}`,
            email: logindata?.email,
            companyId: tokenDetails?.client?.name,
          });
        }

        const searchParams = new URLSearchParams(document.location.search);
        if (searchParams.get("redirect_url")) {
          window.location.href = searchParams.get("redirect_url");
        } else {
          this.props.history.push(
            formatRoute(constants.APPLICATION_ROUTE.DASHBOARD_LIST.ROUTE, {})
          );
        }
      })
      .catch((error) => {
        if (
          error &&
          error?.data &&
          error?.data?.error_detail === "Error: Invalid user!!!"
        ) {
          this.setState({ sso_flag: true });
        } else {
          this.setState({
            apiErrors:
              error && error.data && error.data.error_detail
                ? error.data.error_detail
                : error.data.error
                ? error.data.error
                : constants.ERROR.SOMETHING_WENT_WRONG,
          });
        }
      });
  }
  getMicrosoftCode() {
    const microsoftParam = new URLSearchParams(
      new URL(window.location.href).search
    );
    const redirectUrl = microsoftParam.get("redirect_url");
    if (redirectUrl) {
      const redirectParams = new URLSearchParams(new URL(redirectUrl).search);
      return redirectParams.get("code") ?? null;
    }
    return null;
  }

  handleCallback = async () => {
    // Extract query parameters from the URL
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code"); // Get the 'code' parameter from the URL (used for Google OAuth flow)
    const isMicrosoft = params.get("is_microsoft"); // Check if the login flow is Microsoft SSO

    // If the user is logging in via Microsoft SSO, call the microsoftLoginHandler function
    if (isMicrosoft) {
      this.microsoftLoginHandler();
    }

    // Debugging: Retrieve any Microsoft code that might be stored
    let microsoftCode = this.getMicrosoftCode();

    // If a 'code' is available (Google OAuth flow), proceed with exchanging it for user information
    if (code) {
      // Send a GET request to your backend to exchange the Google authorization code for user information
      const { data } = await axios.get(
        `${
          process.env.REACT_APP_TBRI_GOOGLE_CALLBACK_URL
        }?code=${code}&redirect_uri=${window.location.origin + "/login"}`
      );

      // Close the search bar in the UI
      this.props.openSearchBar(false);

      // Fetch and store user information using the data received from the backend
      await this.getUserInfo(data.user);
    }
    // If there is a Microsoft code available (Microsoft OAuth flow), proceed with token acquisition
    else if (microsoftCode) {
      // Acquire an access token using the Microsoft code
      const tokenResponse = await acquireTokenByCode({
        code: microsoftCode,
      });

      // If the token response contains an access token, use it to call the Microsoft Graph API
      if (tokenResponse?.access_token) {
        await callMsGraph(tokenResponse.access_token).then(async (response) => {
          // Fetch and store the user information from Microsoft Graph response
          await this.getUserInfo({
            ...response,
            email: response.userPrincipalName, // Set email from the Microsoft Graph response
            fromMicrosoftSSO: true, // Mark that the user logged in via Microsoft SSO
          });
        });
      }
    }
  };

  componentDidMount() {
    this.handleCallback();
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.props.openSearchBar(false);
    let input = {
      email: this.state.loginEmail,
      password: this.state.loginPassword,
    };
    await this.getUserInfo(input);
  };
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  microsoftLoginHandler = async () => {
    // Function to generate a random string, used as the code_verifier
    function generateRandomString(length) {
      const charset =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
      let result = "";
      // Loop through the desired length and generate a random character from the charset for each position
      for (let i = 0; i < length; i++) {
        result += charset.charAt(Math.floor(Math.random() * charset.length));
      }
      return result; // Return the generated random string
    }

    // Function to generate a SHA-256 hash from a plain text string (used to create the code_challenge)
    async function sha256(plainText) {
      const encoder = new TextEncoder(); // Create a new TextEncoder to convert text to bytes
      const data = encoder.encode(plainText); // Encode the plain text into bytes
      const hashBuffer = await crypto.subtle.digest("SHA-256", data); // Generate the SHA-256 hash from the byte data
      return hashBuffer; // Return the resulting hash as an ArrayBuffer
    }

    // Function to base64-url encode the hash (converts the hash to a URL-safe base64 format)
    function base64urlEncode(arrayBuffer) {
      const base64String = btoa(
        String.fromCharCode.apply(null, new Uint8Array(arrayBuffer))
      ); // Convert the ArrayBuffer to a binary string, then to base64
      // Replace characters to make the base64 string URL-safe and remove any trailing '=' padding
      return base64String
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=+$/, "");
    }

    // Function to generate the code_challenge from the code_verifier using SHA-256 and base64-url encoding
    async function generateCodeChallenge(codeVerifier) {
      const hash = await sha256(codeVerifier); // Get the SHA-256 hash of the code_verifier
      return base64urlEncode(hash); // Return the base64-url encoded hash as the code_challenge
    }

    const codeVerifier = generateRandomString(128); // Generate a code_verifier of length 128
    const codeChallenge = await generateCodeChallenge(codeVerifier); // Generate the corresponding code_challenge

    // Store the code_verifier securely in session storage for later use during token exchange
    sessionStorage.setItem("code_verifier", codeVerifier);

    // Construct the Microsoft authorization URL with required query parameters
    const authorizationUrl = `https://login.microsoftonline.com/${tenantID}/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${encodeURIComponent(
      redirectURI
    )}&response_mode=query&scope=https://graph.microsoft.com/.default&code_challenge=${codeChallenge}&code_challenge_method=S256&prompt=select_account`;

    // Redirect the user to the Microsoft login page to initiate the OAuth flow
    window.location.href = authorizationUrl;
  };

  handleGoogleSSO = () => {
    window.location.href = `${
      process.env.REACT_APP_TBRI_GOOGLE_REDIRECT_URL
    }?redirect_uri=${window.location.origin + "/login"}`;
  };
  render() {
    return (
      <div className="wrapper login_page">
        <main>
          <Container fluid>
            <Row>
              <aside className="col-md-6 px-0 hide-in-mobille">
                <div className="login_left">
                  <figure className="login_banner">
                    <img
                      src={login_building}
                      className="img-fluid"
                      alt="login banner"
                      title="login banner"
                    />
                  </figure>
                  <a href="/">
                    <img
                      style={{ width: "120px", marginLeft: "20px" }}
                      src="https://tbr-ggk.s3.us-east-2.amazonaws.com/production/TBR_2color_tagline.svg"
                      alt="TBR logo"
                    />
                  </a>
                </div>
              </aside>
              <aside className="col-md-6 px-0">
                <div className="login_right">
                  <figure>
                    <img
                      src={login_map}
                      className="img-fluid"
                      alt="map Banner"
                    />
                  </figure>
                  <Form>
                    <span className="login_formlogos">
                      <img src={logo} className="img-fluid" alt="logo" />
                    </span>
                    {this.state.sso_flag ? (
                      <div className="invalid_email_div">
                        <span className="span_1">
                          This account does not exist.
                        </span>
                        <span className="span_2">
                          Apologies, but it appears that this account does not{" "}
                          <br />
                          exist in our system.
                        </span>
                        <Button
                          className="btn_1"
                          onClick={this.handleGoogleSSO}
                        >
                          Continue with other mail
                        </Button>
                        <Button
                          className="btn_2"
                          onClick={() => this.setState({ sso_flag: false })}
                        >
                          Try another options
                        </Button>
                      </div>
                    ) : (
                      <>
                        <span className="login_text_span">Log In</span>
                        {this.state.apiErrors.length > 0 && (
                          <Notification
                            color={"danger"}
                            message={this.state.apiErrors}
                            className="mt-2"
                          />
                        )}
                        <FormGroup
                          style={{ marginBottom: "35px", marginTop: "30px" }}
                        >
                          <Label htmlFor="loginEmail">Email</Label>
                          <Input
                            type="email"
                            placeholder="Email"
                            id="loginEmail"
                            name="loginEmail"
                            onChange={this.handleInputChange}
                          />
                        </FormGroup>
                        <FormGroup style={{ marginBottom: "0px" }}>
                          <Label htmlFor="loginPassword">Password</Label>
                          <Input
                            type="password"
                            placeholder="Password"
                            id="loginPassword"
                            name="loginPassword"
                            onChange={this.handleInputChange}
                          />
                        </FormGroup>
                        <div
                          // className="text-center"
                          style={{
                            marginBottom: "20px",
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: "10px",
                          }}
                        >
                          <a href="/forgot-password">Forgot Password ?</a>
                        </div>
                        <FormGroup style={{ marginBottom: "0px" }}>
                          <ButtonToggle
                            className={
                              this.state.loginEmail && this.state.loginPassword
                                ? ""
                                : "disabled"
                            }
                            style={{ minWidth: "100%" }}
                            disabled={
                              !(
                                this.state.loginEmail &&
                                this.state.loginPassword
                              )
                            }
                            color="primary"
                            type="submit"
                            onClick={this.handleSubmit}
                          >
                            Submit
                          </ButtonToggle>
                        </FormGroup>

                        <div className="sso_div">
                          <span>Or</span>
                          <div>
                            <span style={{ fontWeight: 400, fontSize: "16px" }}>
                              Sign in with
                            </span>{" "}
                            <img
                              src="https://tbr-ggk.s3.us-east-2.amazonaws.com/development+/GoogleLogo.svg"
                              alt="google-logo"
                              onClick={this.handleGoogleSSO}
                            />
                            <img
                              src="https://tbr-ggk.s3.us-east-2.amazonaws.com/development+/MicrosoftLogo.svg"
                              alt="microsoft-logo"
                              onClick={this.microsoftLoginHandler}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </Form>

                  {/* )} */}
                  <div className="terms-conditions">
                    <a
                      className="links"
                      href="https://tbrdevfiles.s3.amazonaws.com/documents/TBR+Terms+of+Service.pdf"
                      target="_blank"
                    >
                      Terms and Conditions
                    </a>{" "}
                    |
                    <a
                      className="links"
                      href="https://tbrdevfiles.s3.amazonaws.com/documents/TBR+Privacy+Policy.pdf"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                  </div>
                </div>
              </aside>
            </Row>
          </Container>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    home: state.home,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    loginSuccess: (user) => {
      dispatch(loginUserSuccess(user));
    },
    loginFailure: (user) => {
      dispatch(loginUserFailure(user));
    },
    openSearchBar: (data) => {
      dispatch(openSearchBar(data));
    },
    downloadFileDetails: (data) => {
      dispatch(downloadFileDetails(data));
    },
    downloadingProgressModel: (data) => {
      dispatch(downloadingProgressModel(data));
    },
    downloadingProgressId: (data) => {
      dispatch(downloadingProgressId(data));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);
