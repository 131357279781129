import React from "react";
import { DropdownList } from "react-widgets";
import CustomCheckBox from "../CustomCheckBox";

function MetricsSection({
  disableMetricsBox,
  metricTypeList,
  metricsTypeId,
  handleMetricsType,
  selectedMetrics,
  setDisableSegmentBox,
  setDisabledCompanyBox,
  setDisableMetricsBox,
  handleGeoAndVertical,
  handleByMetricsTrigger,
  setSelectedMetrics,
  setMetricTypeList,
  setMetricsTypeId,
  paramId,
}) {
  return (
    <div
      className="col-md-3 add-segments-block"
      style={{
        backgroundColor: disableMetricsBox
          ? "rgba(255, 255, 255, 0.5)"
          : "rgba(243, 243, 243, 0.5)",
        cursor: disableMetricsBox ? "pointer" : "not-allowed",
      }}
    >
      <h6 className="text-grey block-title font-weight-normal mb-0">
        Select Metrics
      </h6>
      <div className="plain-checkbox-list-wrapper">
        <div
          className="select_companies_search metrics_dropdown"
          style={{
            cursor: disableMetricsBox ? "pointer" : "disabled",
          }}
        >
          <DropdownList
            data={metricTypeList}
            valueField="id"
            textField="label"
            value={metricsTypeId}
            onChange={handleMetricsType}
            placeholder="Select Metrics Type"
            disabled={!disableMetricsBox}
            style={{ border: "none" }}
            className="metrics-dropdown-list"
          />
        </div>
        <hr className="m-0" />
        {/* </div> */}
        <CustomCheckBox
          nodes={metricTypeList
            .find((i) => i.id === metricsTypeId)
            ?.metrics.map((i) => ({ ...i, value: i.id, label: i.name }))}
          checked={selectedMetrics}
          is_data={true}
          showArrow={false}
          onCheck={(checked) => handleByMetricsTrigger(checked)}
          key={"Metrics"}
          disabled={!disableMetricsBox}
        />
      </div>
      {disableMetricsBox && (
        <div
          style={{
            // position: "absolute",
            bottom: "0%",
            height: "30px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            padding: "0 10 0 10",
            justifyContent: "space-between",
            gap: "10px",
            background: "#ffffff",
          }}
        >
          <div
            style={{
              width: "50%",
              backgroundColor: "#FBFBFB",
              height: "inherit",
              display: "flex",
              alignItems: "center",
              padding: "0px 10px",
              gap: "10px",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setDisableSegmentBox(true);
              // setDisabledCompanyBox(true);
              setDisableMetricsBox(false);
              if (!paramId) {
                setSelectedMetrics([]);
              }
              setMetricTypeList([]);
              setMetricsTypeId();
            }}
          >
            <span
              className="material-icons-outlined add-icon"
              // data-testid={`checkbox-arrow-segment`}
              style={{ color: "#606B77", fontSize: "18px", width: "18px" }}
            >
              arrow_backward
            </span>
            <span
              style={{
                fontSize: "14px",
                fontWeight: 400,
                color: "#606B77",
              }}
            >
              Back
            </span>
          </div>
          <div
            style={{
              width: "50%",
              backgroundColor: "#DCEDFF",
              height: "inherit",
              display: "flex",
              alignItems: "center",
              padding: "0px 10px",
              gap: "10px",
              justifyContent: "center",
              cursor: selectedMetrics.length ? "pointer" : "not-allowed",
            }}
            onClick={(e) =>
              selectedMetrics.length > 0 && handleGeoAndVertical(e)
            }
          >
            <span
              style={{
                fontSize: "14px",
                fontWeight: 400,
                color: "#2E6AD2",
              }}
            >
              Next
            </span>
            <span
              className="material-icons-outlined add-icon"
              style={{ color: "#2E6AD2", fontSize: "18px" }}
            >
              arrow_forward
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default MetricsSection;
