import { combineReducers } from "redux";
import * as DETAILS_REF from "./reducerReference/boardReference";

const boards = DETAILS_REF.boards;
const boardDetails = DETAILS_REF.boardDetails;
const boardContent = DETAILS_REF.boardContent;
const boardContentDetails = DETAILS_REF.boardContentDetails;
const boardModes = DETAILS_REF.boardModes;
const boardContentComments = DETAILS_REF.boardContentComments;
const boardGeneralComments = DETAILS_REF.boardGeneralComments;
const boardType = DETAILS_REF.boardType;

const boardReducer = combineReducers({
  boards,
  boardDetails,
  boardContent,
  boardModes,
  boardContentComments,
  boardGeneralComments,
  boardContentDetails,
  boardType,
});

export default boardReducer;
