import React from "react";
import constants from "../Utils/constants";
import { Link } from "react-router-dom";

class NotFound extends React.Component {
  render() {
    return (
      <aside className="main_content access-denied">
        <div className="m-3 mb-0 bg-white px-3 br_8 main_content_height_access_denied">
          <div className="ml-auto mr-auto text-center access-denied-wrapper">
            <h1>
              {" "}
              <i
                className="fa fa-exclamation-triangle text-danger"
                aria-hidden="true"
              ></i>{" "}
              <span>Page Not Found.</span>
            </h1>
            <p>
              Looks like you've followed a broken link or entered a URL that
              doesn't
              <br />
              exist on this site.
            </p>
            <Link to={constants.APPLICATION_ROUTE.DASHBOARD_LIST.ROUTE}>
              <h6 style={{ color: "rgb(6, 69, 173)", fontSize: "12px" }}>
                {" "}
                <i
                  className="fa fa-long-arrow-left"
                  aria-hidden="true"
                ></i>{" "}
                Back to Dashboard
              </h6>
            </Link>
          </div>
        </div>
      </aside>
    );
  }
}

export default NotFound;
