import React, { Component } from "react";
import constants from "../Utils/constants";
import DynamicTableComponent from "../Components/DynamicTableComponent";

const tableHeader = [
  {
    id: "role_name",
    title: "Role",
    sortable: false,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "first_name",
    title: "First Name",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "last_name",
    title: "Last Name",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "email",
    title: "Email",
    sortable: false,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "mobile",
    title: "Contact No.",
    sortable: false,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "address",
    title: "Address",
    sortable: false,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "message",
    title: "Comments",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left", color: "red" },
    visibility: true,
  },
];
const showActions = false;
class ListClientUploadedUserStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
    };
  }
  componentDidMount() {
    this.getThirtyRecords(1);
  }

  pagePrevious = () => {
    this.getThirtyRecords(this.state.pageNo - 1);
  };

  pageNext = () => {
    this.getThirtyRecords(this.state.pageNo + 1);
  };

  handlePage = (page) => {
    this.getThirtyRecords(page);
  };

  handleSort = (clickedColumn, key) => {};

  handlePageSize = (e) => {
    this.setState({ pageSize: e.target.value, pageNo: 1 }, () => {
      this.getThirtyRecords(1);
    });
  };

  getThirtyRecords = (page) => {
    let start = (page - 1) * Number(this.state.pageSize);
    let end = start + Number(this.state.pageSize);
    let ten_records = this.props.data.slice(start, end);
    this.setState({
      rowData: ten_records,
      pageNo: page,
    });
  };

  render() {
    return (
      <>
        <div className="card custom-card login-details-card d-inline-flex">
          <div className="card-body">
            <div className="stats-group d-flex">
              <div className="stats-block d-flex align-items-center py-1">
                <h5 className="font-weight-bold text-blue stat mr-2">
                  {this.props.totalUploadedRowsCount}
                </h5>
                <label>Uploaded</label>
              </div>
              <div className="stats-block d-flex align-items-center py-1">
                <h5 className="font-weight-bold text-green stat mr-2">
                  {this.props.totalUploadedRowsCount - this.props.data.length}
                </h5>
                <label>Success</label>
              </div>
              <div className="stats-block d-flex align-items-center py-1">
                <h5 className="font-weight-bold text-red stat mr-2">
                  {this.props.data.length}
                </h5>
                <label>Failed</label>
              </div>
            </div>
          </div>
        </div>
        {this.props.data.length ? (
          <h5 className="font-weight-semibold my-0 text-black2 mt-3">
            Please find the below comments for failed records.
          </h5>
        ) : (
          <></>
        )}
        {this.state.rowData && this.state.rowData.length ? (
          <div className={"mt-2"}>
            <div className="custom-table-block custom-table-block-2 user-upload-table-block sort-table-block nowrap-table-block th-nowrap center-th-align no-shadow">
              <DynamicTableComponent
                columnData={tableHeader}
                rowData={this.state.rowData}
                pagePrevious={this.pagePrevious}
                pageNext={this.pageNext}
                pageSize={this.state.pageSize}
                pageNo={this.state.pageNo}
                handlePage={this.handlePage}
                handleSort={this.handleSort}
                sortColumn={this.state.sortColumn}
                sortOrder={this.state.sortOrder}
                count={Number(this.props.data.length)}
                rowAction={this.rowAction}
                colSpan={tableHeader.filter((h) => h.visibility).length}
                showPagination={true}
                paginationClass={"center-pagination custom-pagination-new"}
                handlePageSize={this.handlePageSize}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default ListClientUploadedUserStatus;
