//This component is for Active Inactive and No access items
import React from "react";

const AccessReportStatus = () => {
  return (
    <div className="d-flex justify-content-end align-self-end flex-wrap ml-auto col">
      <div className="table-list-legends d-flex flex-wrap mb-18px pb-0">
        <AccessStatusItem title="Active" iconClass="allowed-access-icon" />
        <AccessStatusItem
          title="Inactive"
          iconClass="inactive-allowed-access-icon"
        />
        <AccessStatusItem
          title="No Access"
          iconClass="not-allowed-access-icon"
        />
      </div>
    </div>
  );
};

const AccessStatusItem = ({ title, iconClass }) => (
  <span className="d-inline-flex align-items-center mr-2">
    <span
      className={`material-icons-round icon ${iconClass} icon-fs-14`}
      aria-hidden="true"
    >
      circle
    </span>
    <h3 className="mb-0">{title}</h3>
  </span>
);

export default AccessReportStatus;
