import React, { Component } from "react";
import { connect } from "react-redux";
import { boards, boardModes } from "../Store/Actions/Board";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import constants from "../Utils/constants";
import Notification from "../Components/Notification";
import CustomDropdownList from "../Components/CustomDropdownList";
import CustomMultiSelect from "../Components/CustomMultiSelect";
import { VisualizationServices } from "../Services/Visualization";
import { ClientServices } from "../Services/Client";
import permissionCheck from "../lib/permissionCheck";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import Toast from "../Utils/Toast";
import DailogNew from "../Components/DailogNew";

class ShareTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boardModal: true,
      boardModalHeader: (
        <>
          <div className="d-flex align-items-center">
            <span class="material-icons mr-2 ml-n1 rotate-180 mt-1">reply</span>
            {constants.APPLICATION_ROUTE.MY_BOARDS.SHARE_BOARD.HEADER_TITLE}
          </div>
        </>
      ),
      boardName: "",
      users: [],
      sharedUsers: [],
      formErrors: {},
      apiError: null,
      boardId: null,
      showCreate: false,
      isFormSubmitted: false,
      isAllCompany: false,
      notifyAllUsers: false,
      dailogModal: false,
      isDeleteConfirmOpen: false,
      dailogModalContent: (
        <>
          <div className="info-body p-3">
            <h3 className="font-weight-semibold mb-0">
              Are you sure you want to leave without saving?
            </h3>
          </div>
        </>
      ),
      dailogModalConfig: { type: "un-save_changes" },
      dailogModalHeader: (
        <>
          <h6 className="text-darkblue2 font-weight-bold">
            Discard Unsaved Changes?
          </h6>
        </>
      ),
      dailogModalStyleType: "primary-modal",
      selectedMode: undefined,
    };
  }

  handleCheckboxChange = (e) => {
    if (e.target.checked) {
      let users = this.state.users.map((c) => {
        let modes = JSON.parse(JSON.stringify(this.props.board.boardModes));
        c.modes = modes;
        c.is_selected = true;
        return c;
      });
      this.setState(
        {
          users,
          [e.target.id]: true,
        },
        () => {
          if (this.state.isFormSubmitted === true) this.isFormValid();
        }
      );
    } else {
      let users = this.state.users.map((c) => {
        let modes = JSON.parse(JSON.stringify(this.props.board.boardModes));
        c.modes = modes;
        c.is_selected = false;
        return c;
      });
      this.setState(
        {
          users,
          [e.target.id]: false,
        },
        () => {
          if (this.state.isFormSubmitted === true) this.isFormValid();
        }
      );
    }
  };

  handleModeChange = (e) => {
    let boardModes = Object.assign([], this.props.board.boardModes);
    boardModes.map((b) => (b.is_selected = b.id === e.id));
    this.props.boardModes(boardModes);
    this.setState({
      selectedMode: e,
      formErrors: {
        ...this.state.formErrors,
        modeError: false,
      },
    });
    if (this.state.isFormSubmitted === true) this.isFormValid();
  };

  handleUserModeChange = (e) => {
    let sharedUsers = this.state.sharedUsers;
    let i = sharedUsers.findIndex((s) => s.user_id === e.user_map_id);
    sharedUsers[i].modes.map((m) => (m.is_selected = m.id === e.id));
    this.setState(
      {
        sharedUsers: sharedUsers,
        isDeleteConfirmOpen: true,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };

  handleNotityUser = (e, uid) => {
    let sharedUsers = this.state.sharedUsers;
    let i = sharedUsers.findIndex((s) => s.uid === uid);
    sharedUsers[i].isNotify = e.target.checked;
    this.setState(
      {
        sharedUsers: sharedUsers,
        isDeleteConfirmOpen: true,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };

  handleNotityUsers = (e) => {
    this.setState(
      {
        notifyAllUsers: e.target.checked,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };

  handleUserChange = (e) => {
    let selectedUsers = e.map((a) => a.id);
    let users = this.state.users.map((c) => {
      let modes = JSON.parse(JSON.stringify(this.props.board.boardModes));
      if (c?.role?.short_name === "member" || c?.role?.short_name === "user") {
        modes = modes.filter((el) => el.short_name === "view");
      }
      c.modes = modes;
      c.is_selected = selectedUsers.includes(c.id);
      return c;
    });
    this.setState(
      {
        users: users,
        formErrors: {
          ...this.state.formErrors,
          userError: false,
        },
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };

  closeShareBoardModal = () => {
    if (this.state.isDeleteConfirmOpen) {
      this.setState({
        dailogModal: true,
      });
    } else {
      this.setState(
        {
          boardModal: false,
        },
        () => {
          this.props.closeShareBoardModal();
        }
      );
    }
  };

  isFormValid = () => {
    let formError = false;
    let userError = false;
    let modeError = false;
    let modeId = !!this.props.board.boardModes.find(
      (b) => b.is_selected === true
    );
    let userId =
      this.state.isAllCompany ||
      !!this.state.users.find((u) => u.is_selected === true);
    let sharedUsers = this.state.sharedUsers;
    if (
      (modeId === true && userId === false) ||
      (modeId === false && userId === true) ||
      sharedUsers.length === 0
    ) {
      if (!modeId) {
        modeError = true;
        formError = true;
      }
      if (!userId) {
        userError = true;
        formError = true;
      }
    }
    this.setState({
      ...this.state,
      formErrors: {
        ...this.state.formErrors,
        userError: userError,
        modeError: modeError,
      },
      apiError: null,
    });
    return formError;
  };

  getShareModes = () => {
    VisualizationServices.getShareModes()
      .then((res) => {
        let modes = res?.data?.data?.modes;
        this.props.boardModes(modes);

        this.getSharedUsers(this.props.boardId);
      })
      .catch((error) => {
        Toast(
          error?.data?.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  getUsers = () => {
    let loggedInUserId = this.props.home.loginUserSuccess.id;
    let url = "/clientusers/all?is_active=1";
    ClientServices.getClientUsers(`${url}`)
      .then((res) => {
        let users = res?.data?.data;
        const sharedUserIds =
          this.props.boardDetails.shared_users?.map((u) => u.user_id) ?? [];

        const userData = [];
        for (const u of users) {
          if (u.user_id !== loggedInUserId && !sharedUserIds?.includes(u.id)) {
            userData.push({
              ...u,
              name: `${u.first_name} ${u.last_name} (${u.email})`,
              is_selected: false,
            });
          }
        }

        this.setState({
          users: userData,
        });
      })
      .catch((error) => {
        Toast(
          error?.data?.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  getSharedUsers = () => {
    let sharedUsers = [];
    let users = this.props.boardDetails.shared_users;
    users.forEach((u) => {
      let modes = JSON.parse(JSON.stringify(this.props.board.boardModes));
      if (u?.role?.short_name === "member" || u?.role?.short_name === "user") {
        modes = modes.filter((el) => el.short_name === "view");
      }
      modes.map((m) => (m.is_selected = m.id === u.mode.id));
      modes.map((m) => (m.user_map_id = u.user_id));

      let user = {
        uid: Math.random(),
        id: u.id,
        user_id: u.user_id,
        email: u.email,
        modes: modes,
        name: u.first_name + " " + u.last_name,
        isNotify: u.is_notify ? u.is_notify : false,
        is_deleted: false,
        first_name: u.first_name,
        last_name: u.last_name,
      };
      sharedUsers.push(user);
    });
    this.setState({
      sharedUsers: sharedUsers,
    });
  };

  handleRemoveSharedUserConfirmation = (e, uid) => {
    e.stopPropagation();
    this.setState({ isDeleteConfirmOpen: true });
    this.handleRemoveSharedUser(uid);
  };

  handleRemoveSharedUser = (uid) => {
    let sharedUsers = this.state.sharedUsers;
    let i = sharedUsers.findIndex((s) => s.uid === uid);
    sharedUsers[i].is_deleted = true;
    this.setState(
      {
        sharedUsers: sharedUsers,
        isDeleteConfirmOpen: true,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };

  componentDidMount = () => {
    this.getShareModes();
    this.getUsers();
  };

  handleSubmit = () => {
    let request = {};
    this.setState(
      {
        isFormSubmitted: true,
      },
      () => {
        try {
          if (
            this.state.isDeleteConfirmOpen &&
            this.state.sharedUsers?.length > 0
          ) {
            request.allow_all_users = 0;
            if (this.state.isAllCompany) request.allow_all_users = 1;

            request.notify = this.state.notifyAllUsers;
            request.mode_id = 5;
            let users = [];
            const updatedUsers = this.state.sharedUsers.filter(
              (y) => (y.id && y.is_deleted === true) || y.is_deleted === false
            );

            updatedUsers.forEach((u) => {
              users.push({
                id: u.id,
                user_id: u.user_id,
                mode_id: u.modes.find((m) => m.is_selected === true)?.id,
                notify: u.isNotify,
                is_deleted: u.is_deleted,
                email: u.email,
                first_name: u.first_name,
                last_name: u.last_name,
              });
            });
            request.shared_users = users;
            VisualizationServices.shareTemplate(this.props.boardId, request)
              .then((response) => {
                this.props.saveShareBoardSuccess(this.props.boardId, users);
              })
              .catch((error) => {
                Toast(
                  error?.data?.error
                    ? error.data.error
                    : constants.ERROR.SOMETHING_WENT_WRONG,
                  "error"
                );
              });
          } else {
            Toast(constants.ERROR.ALL_FIELDS_ERRORS, "error");
          }
        } catch (error) {
          Toast(error, "error");
        }
      }
    );
  };

  customValueComponent = ({ item }) => {
    return (
      <span
        data-for={`tooltip-${item.name}-profile-info`}
        data-tip={item.name}
        data-iscapture="true"
      >
        {item.name}
        <CustomReactTooltip
          id={`tooltip-${item.name}-mode-info`}
          multiline={true}
        />
      </span>
    );
  };

  dailogModalDecline = () => {
    this.setState(
      {
        boardModal: false,
        dailogModal: false,
      },
      () => {
        this.props.closeShareBoardModal();
      }
    );
  };

  dailogModalAccept = (config) => {
    this.setState({ dailogModal: false });
  };

  handleUser = () => {
    if (!this.isFormValid()) {
      const sharedUsers = this.state.sharedUsers;
      const users = this.state.users;
      const checkingUsers = users
        .filter((user) => user.is_selected === true)
        ?.map((u) => u.user_id);
      if (checkingUsers?.length > 0) {
        users.forEach((u) => {
          const userIndex = sharedUsers.findIndex(
            (f) => f.user_id === u.user_id
          );
          if (u.is_selected && userIndex === -1) {
            let modes = JSON.parse(JSON.stringify(this.props.board.boardModes));
            if (
              u?.role?.short_name === "member" ||
              u?.role?.short_name === "user"
            ) {
              modes = modes.filter((el) => el.short_name === "view");
            }
            const useSelectedMode = this.state.selectedMode;
            const checkingIndex = modes.findIndex(
              (el) => el.id === useSelectedMode?.id
            );
            if (checkingIndex === -1) {
              modes[0].is_selected = true;
            } else {
              modes.map((m) => (m.is_selected = m.id === useSelectedMode?.id));
            }
            modes.map((m) => (m.user_map_id = u.user_id));
            let user = {
              uid: Math.random(),
              id: null,
              user_id: u.user_id,
              email: u.email,
              modes: modes,
              name: u.first_name + " " + u.last_name,
              isNotify: this.state.notifyAllUsers,
              is_deleted: false,
              first_name: u.first_name,
              last_name: u.last_name,
            };
            sharedUsers.push(user);
          }
        });

        const updatedUser = users.filter((t) => t.is_selected === false);

        let boardModes = Object.assign([], this.props.board.boardModes);
        boardModes.map((b) => (b.is_selected = false));
        this.props.boardModes(boardModes);
        this.setState({
          sharedUsers: sharedUsers,
          users: updatedUser,
          selectedMode: undefined,
          formErrors: {
            userError: false,
            modeError: false,
          },
          isAllCompany: false,
          isDeleteConfirmOpen: true,
        });
      }
    }
  };

  getUserModes = (user) => {
    return user.modes;
  };

  render() {
    let boardModes = this.props?.board?.boardModes
      ? this.props.board.boardModes
      : [];
    let isShare = permissionCheck(
      constants.ACL.PERMISSION_KEY,
      constants.ACL.MY_BOARDS_RESOURCE.TITLE,
      constants.ACL.MY_BOARDS_RESOURCE.ACTIONS.SHARE
    );

    return (
      <>
        <Modal
          isOpen={this.state.boardModal}
          toggle={() => this.closeShareBoardModal()}
          className={"modal-lg modal-w-header custom-modal primary-modal"}
        >
          <ModalHeader toggle={this.closeShareBoardModal}>
            {this.state.boardModalHeader}
          </ModalHeader>
          <ModalBody>
            <div className="row" data-testid="shareBoardOpen">
              <form className={"col-sm-12 form-wrapper"}>
                {this.state.apiError !== null && (
                  <Notification
                    color={"danger"}
                    message={this.state.apiError}
                  />
                )}
                <div className={"row"}>
                  <div className={"col-sm-7 users-dropdown"}>
                    <h4
                      className="font-weight-semibold text-ligntblue2"
                      htmlFor="users"
                    >
                      Users <i className={"mandatory_red"}>* </i>
                      <span style={{ marginLeft: "25px" }}>
                        <input
                          className="form-check-input custom-checkbox-new"
                          style={{ top: "-1.5px" }}
                          type="checkbox"
                          id="isAllCompany"
                          value="1"
                          checked={this.state.isAllCompany}
                          onChange={this.handleCheckboxChange}
                        />
                        <label
                          className="form-check-label ml-1 font-weight-semibold text-grey"
                          htmlFor="isAllCompany"
                        >
                          All
                        </label>
                      </span>
                    </h4>
                    <div className="user-custom-multiselect">
                      <CustomMultiSelect
                        disabled={this.state.isAllCompany}
                        data={this.state.users}
                        handleChange={this.handleUserChange}
                        placeholder={"Select Users"}
                        className={
                          this.state.formErrors.userError
                            ? "invalid-tooltip-dropdown"
                            : ""
                        }
                      />
                    </div>
                    {this.state.formErrors.userError && (
                      <div
                        className="invalid-tooltip"
                        style={{ display: "block" }}
                      >
                        {constants.FORM.MANDATORY_FIELD}
                      </div>
                    )}
                  </div>
                  <div className={"col-sm-3"}>
                    <label
                      className="d-inline-flex align-items-center mb-1"
                      htmlFor="modes"
                    >
                      Mode
                      <i className={"mandatory"}>* </i>
                    </label>
                    <div className="custom-combobox-list">
                      <CustomDropdownList
                        data={boardModes}
                        value={this.state.selectedMode}
                        handleChange={this.handleModeChange}
                        placeholder={"Select Mode"}
                        allowCreate={false}
                        filter={false}
                        className={
                          this.state.formErrors.modeError
                            ? "invalid-tooltip-dropdown"
                            : ""
                        }
                        itemComponent={this.customValueComponent}
                      />
                    </div>
                    {this.state.formErrors.modeError && (
                      <div
                        className="invalid-tooltip"
                        style={{ display: "block" }}
                      >
                        {constants.FORM.MANDATORY_FIELD}
                      </div>
                    )}
                  </div>
                  <div className="col-sm-2">
                    <label className="d-block" htmlFor="modes">
                      &nbsp;
                    </label>
                    <Button
                      className="modal-btn"
                      style={{ minWidth: "65px" }}
                      color="primary"
                      onClick={() => this.handleUser()}
                      disabled={this.state.users?.length === 0}
                    >
                      Add
                    </Button>
                  </div>
                </div>
                <div className={"row mt-1"}>
                  <div className={"col-sm-3"}>
                    <label htmlFor="notify">
                      <span className="notify">
                        <input
                          className="form-check-input custom-checkbox-new"
                          type="checkbox"
                          id="notify"
                          value="1"
                          onChange={(e) => {
                            this.handleNotityUsers(e);
                          }}
                          checked={this.state.notifyAllUsers}
                        />
                        <label className="form-check-label" htmlFor="notify">
                          Notify Users
                        </label>
                      </span>
                    </label>
                  </div>
                </div>

                {this.state.sharedUsers.filter((s) => s.is_deleted !== true)
                  .length > 0 && (
                  <div className={"row mt-3"}>
                    <div className="col-sm-12 form-group position-relative share-board-users">
                      <h4 className="font-weight-semibold text-ligntblue2">
                        Shared with users
                      </h4>
                      <table className="col-sm-12 table table-condensed">
                        <tbody>
                          {this.state.sharedUsers
                            .filter((s) => s.is_deleted !== true)
                            .map((user) => (
                              <tr key={user.uid}>
                                <td>{user.name}</td>
                                <td>
                                  {
                                    <div className="custom-combobox-list">
                                      <CustomDropdownList
                                        disabled={true}
                                        key={user.uid}
                                        data={user.modes}
                                        handleChange={this.handleUserModeChange}
                                        placeholder={"Select Mode"}
                                        allowCreate={false}
                                        filter={false}
                                      />
                                    </div>
                                  }
                                </td>
                                <td className="text-center">
                                  <label htmlFor={user.uid}>
                                    <span className="d-inline-flex align-items-center notify">
                                      <input
                                        className="form-check-input custom-checkbox-new"
                                        type="checkbox"
                                        id={user.uid}
                                        value="1"
                                        checked={user.isNotify}
                                        onChange={(e) => {
                                          this.handleNotityUser(e, user.uid);
                                        }}
                                      />
                                      <label
                                        className="form-check-label mt-1"
                                        htmlFor={user.uid}
                                      >
                                        Notify
                                      </label>
                                    </span>
                                  </label>
                                </td>
                                <td className="text-center">
                                  <span
                                    class="material-icons-outlined icon-fs-18 text-grey cursor-pointer"
                                    onClick={(e) => {
                                      this.handleRemoveSharedUserConfirmation(
                                        e,
                                        user.uid
                                      );
                                    }}
                                  >
                                    delete
                                  </span>
                                </td>
                              </tr>
                            ))}
                          {this.state.sharedUsers.length === 0 && (
                            <tr>
                              <td className={"text-center"} colSpan={3}>
                                {constants.PAGINATION.NO_RECORDS_FOUND}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="modal-btn btn-outline-primary"
              onClick={this.closeShareBoardModal}
            >
              Cancel
            </Button>
            {isShare && (
              <>
                <Button
                  className="modal-btn"
                  color="primary"
                  disabled={!this.state.isDeleteConfirmOpen}
                  onClick={this.handleSubmit}
                >
                  Save
                </Button>{" "}
              </>
            )}
          </ModalFooter>
        </Modal>
        <DailogNew
          isOpen={this.state.dailogModal}
          toggle={() => this.closeShareBoardModal()}
          accept={this.dailogModalAccept}
          decline={this.dailogModalDecline}
          header={this.state.dailogModalHeader}
          content={this.state.dailogModalContent}
          config={this.state.dailogModalConfig}
          modalStyleType={this.state.dailogModalStyleType}
          cancelTitle={"Continue"}
          submitTitle={"Save"}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    home: state.home,
    board: state.board,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    boards: (payload) => {
      dispatch(boards(payload));
    },
    boardModes: (payload) => {
      dispatch(boardModes(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareTemplate);
