import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import * as echarts from "echarts";
import Toast from "../../../../Utils/Toast";
import { makeUniqueSeriesNames } from "../../../../Helpers/Helper";

const LineChart = React.memo(
  forwardRef((props, ref) => {
    const chartRef = useRef(null);

    useEffect(() => {
      const myChart = echarts.init(chartRef.current, null, {
        renderer: "canvas",
      });
      // Modify series to have unique names
      const uniqueSeries = makeUniqueSeriesNames(props.data.series);

      myChart.setOption({
        title: {
          text: "Line Chart",
        },
        tooltip: {
          trigger: "item",
        },
        legend: props.data.legend,
        grid: props.data.grid,
        xAxis: {
          type: "category",
          boundaryGap: false,
          splitLine: {
            show: true,
          },
          data: props.data.xAxis,
        },
        yAxis: {
          type: "value",
        },
        series: uniqueSeries,
      });

      return () => {
        myChart.dispose();
      };
    }, [props]);

    // Add download chart functionality here
    useImperativeHandle(ref, () => ({
      downloadChart: (format) => {
        const myChart = echarts.getInstanceByDom(chartRef.current);
        const base64 = myChart.getDataURL({
          type: format.toLowerCase(),
          pixelRatio: 2,
          backgroundColor: "#fff",
        });

        const link = document.createElement("a");
        link.href = base64;
        link.download = `Line_chart.${format.toLowerCase()}`;
        link.click();
        Toast("Line chart downloaded successfully", "success");
      },
    }));

    return <div ref={chartRef} style={{ width: "100%", height: "500px" }} />;
  })
);
export default LineChart;
