import { special_reports as DETAILS } from "../Reducers/actionConstants";

const specialreportCategories = (payload) => {
  return {
    type: DETAILS.SPECIAL_REPORT_CATEGORIES,
    payload,
  };
};

const specialreportDetails = (payload) => {
  return {
    type: DETAILS.SPECIAL_REPORT_DETAILS,
    payload,
  };
};

const categories = (payload) => {
  return {
    type: DETAILS.CATEGORIES,
    payload,
  };
};

const specialreportContent = (payload) => {
  return {
    type: DETAILS.SPECIAL_REPORT_CONTENT,
    payload,
  };
};

export {
  specialreportCategories,
  specialreportDetails,
  categories,
  specialreportContent,
};
