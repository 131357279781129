import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import PreferenceCenterSegmentReportsBox from "../Components/PreferenceCenterSegmentReportsBox";
import Select, { components } from "react-select";
import ReactTooltip from "react-tooltip";
import _ from "lodash";

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#EBF3FF";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
    marginLeft: "10px",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input className="mr-2" type="checkbox" checked={isSelected} />
      {children}
    </components.Option>
  );
};

const options = [
  { value: "Companies", label: "Companies" },
  { value: "Benchmarks", label: "Benchmarks", isSelected: false },
  { value: "Market Forecasts", label: "Market Forecasts", isSelected: false },
  { value: "Market Landscapes", label: "Market Landscapes", isSelected: false },
  { value: "Customer Studies", label: "Customer Studies", isSelected: false },
  { value: "Ecosystems", label: "Ecosystems", isSelected: false },
  { value: "Special Reports", label: "Special Reports", isSelected: false },
];

const PreferenceCenterReportSettings = ({
  reportTabSections,
  setReportTabSections,
  selectedReportTab,
  multiSelectReportOptions,
  handleSelectMultipleReport,
  setEnableSaveButton,
  setChangesIsHappened,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [optionsList, setOptionsList] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    let filterOptions = options.filter(
      (opt) => opt.label !== selectedReportTab.name
    );
    setOptionsList(filterOptions);
    // default: disabled everything if first checkbox is disabled
    for (const action of selectedReportTab.preference_actions) {
      setIsDisabled(!action.is_selected);
    }
  }, [reportTabSections]);

  const openModal = () => {
    localStorage.setItem(
      "currentSegmentData",
      JSON.stringify(selectedReportTab)
    );
    setEnableSaveButton(true);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    const currentSegmentData = JSON.parse(
      localStorage.getItem("currentSegmentData")
    );
    const updatedReportTab = reportTabSections.map((reportTab) => {
      if (reportTab.id === selectedReportTab.id) {
        return currentSegmentData;
      }
      return reportTab;
    });
    setReportTabSections(updatedReportTab);
    setModalIsOpen(false);
    setChangesIsHappened(false);
  };

  const selectTheSegmentArrow = (segmentId) => {
    const updatedReportTab = reportTabSections.map((reportTab) => {
      if (reportTab.id === selectedReportTab.id) {
        reportTab.segmentData = reportTab.segmentData.map((seg) => {
          if (seg.id === segmentId && seg.is_checked) {
            seg.is_arrow_selected = true;
          } else seg.is_arrow_selected = false;
          return seg;
        });
      }
      return reportTab;
    });
    setReportTabSections(updatedReportTab);
  };

  const handleActionCheckbox = (slug) => {
    const updatedReportTab = reportTabSections.map((reportTab) => {
      if (reportTab.id === selectedReportTab.id) {
        reportTab.preference_actions = reportTab.preference_actions.map(
          (item) => {
            if (item.slug === slug) {
              item.is_selected = !item.is_selected;
              setIsDisabled(!item.is_selected);
            }
            return item;
          }
        );
      }
      return reportTab;
    });
    setReportTabSections(updatedReportTab);
    setEnableSaveButton(true);
    setChangesIsHappened(true);
  };

  const handleModalCompaniesCheckbox = (segmentId, companyId) => {
    const updatedReportTab = reportTabSections.map((reportTab) => {
      if (reportTab.id === selectedReportTab.id) {
        reportTab.segmentData = reportTab.segmentData.map((segment) => {
          if (segment.id === segmentId) {
            const company = segment.reports.find(
              (comp) => comp.id === companyId
            );
            company.is_selected = !company.is_selected;
          }
          return segment;
        });
      }
      return reportTab;
    });
    setReportTabSections(updatedReportTab);
    setEnableSaveButton(true);
    setChangesIsHappened(true);
  };

  // handling the segment checkboxes
  const handleSegmentCheckbox = (segmentId) => {
    const updatedReportTab = reportTabSections.map((reportTab) => {
      if (reportTab.id === selectedReportTab.id) {
        reportTab.segmentData = reportTab.segmentData.map((seg) => ({
          ...seg,
          is_checked: seg.id === segmentId ? !seg.is_checked : seg.is_checked,
          is_arrow_selected:
            seg.id === segmentId ? false : seg.is_arrow_selected,
        }));
      }
      return reportTab;
    });
    setReportTabSections(updatedReportTab);
    setEnableSaveButton(true);
    setChangesIsHappened(true);
  };

  const saveAdvancePreference = () => {
    setModalIsOpen(false);
  };

  const multiStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "#EBF3FF",
        color: "#2E6AD2",
      };
    },
  };

  // Flag to determine if at least one report matches the conditions
  let found = false;

  // Function to render the content based on the conditions
  const renderReportsContent = () => {
    return selectedReportTab?.segmentData
      ?.map((segment) => {
        if (
          segment.is_checked &&
          segment.is_arrow_selected &&
          segment.reports.length
        ) {
          const filteredReports = segment.reports.filter((comp) => {
            return searchText === ""
              ? true
              : comp.name.toLowerCase().includes(searchText.toLowerCase());
          });

          if (filteredReports.length > 0) {
            found = true; // Set found to true as we have at least one matching report
            return filteredReports.map(
              (company) =>
                company.is_access && (
                  <div key={company.id} className="adv-modal-cmp-list">
                    <input
                      style={{ background: "#2E6AD2" }}
                      type="checkbox"
                      name="checkbox"
                      checked={company.is_selected}
                      onChange={() =>
                        handleModalCompaniesCheckbox(segment.id, company.id)
                      }
                    />
                    <p
                      className={
                        company.is_selected
                          ? "pref-seg-text-blue ml-3"
                          : "pref-seg-input-text ml-3"
                      }
                    >
                      {company.name}
                    </p>
                  </div>
                )
            );
          }
        }
        return null; // Return null for segments that don't meet the criteria
      })
      .filter(Boolean); // Filter out null values
  };

  const content = renderReportsContent();

  return (
    <div style={{ marginTop: "-10px" }}>
      <div
        id={`${selectedReportTab?.name}-${selectedReportTab?.id}`}
        className={"pref-content"}
      >
        <div className="content-box-header">
          <h3 className="pref-h3">{selectedReportTab?.name}</h3>
          {selectedReportTab.show_advanced_preference && (
            <div className="d-flex">
              <div className="adv-pref-info mr-2">
                <span
                  data-tip
                  data-for="adv-pref-info"
                  style={{ color: "#1678CF" }}
                  className="material-icons icon-fs-20"
                >
                  info
                </span>
                <ReactTooltip id="adv-pref-info" place="top" effect="solid">
                  Select companies in segments to set email notifications.
                </ReactTooltip>
              </div>
              <button
                disabled={isDisabled}
                className={
                  isDisabled
                    ? "btn-disable-adv-preference"
                    : "btn-adv-preference"
                }
                onClick={openModal}
              >
                Advance Preferences
              </button>
            </div>
          )}
        </div>
        <div>
          {selectedReportTab?.preference_actions.map((action) => (
            <div
              key={action.id}
              style={{ height: "32px" }}
              className="d-flex align-items-center"
            >
              <input
                type="checkbox"
                name="checkbox"
                checked={action.is_selected}
                onChange={() => handleActionCheckbox(action.slug)}
              />
              <p className="pref-input-text">New content is published</p>
            </div>
          ))}
        </div>

        {selectedReportTab?.show_advanced_setting && (
          <div>
            <div
              className={
                isDisabled
                  ? "pref-noti-container-disable"
                  : "pref-noti-container"
              }
            >
              <h3 className="pref-cmp-notification-text mt-2">
                Apply preferences to:
              </h3>
              <div style={{ width: "100%" }}>
                <Select
                  options={optionsList}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  onChange={handleSelectMultipleReport}
                  styles={multiStyles}
                  value={multiSelectReportOptions}
                  components={{
                    Option: InputOption,
                  }}
                />
              </div>
            </div>

            <div>
              <div className="pref-seg-comp-container">
                <div className="pref-segment-container">
                  <div className="pref-top-header">
                    <p>Select Segments</p>
                  </div>
                  <div className="pref-seg-list-container">
                    {selectedReportTab?.segmentData.length === 0 ? (
                      <>
                        <p
                          style={{
                            margin: "auto",
                            fontSize: "15px",
                            textAlign: "center",
                          }}
                        >
                          No Segments Available.
                        </p>
                      </>
                    ) : (
                      selectedReportTab?.segmentData?.map((segment) => (
                        <div
                          key={segment.name}
                          style={{ height: "35px", width: "95%" }}
                          className="d-flex align-items-center justify-content-between"
                        >
                          <div className="d-flex align-items-center">
                            <input
                              style={{ background: "#2E6AD2" }}
                              type="checkbox"
                              name="checkbox"
                              disabled={
                                isDisabled
                                  ? true
                                  : segment.is_access
                                  ? false
                                  : true
                              }
                              checked={segment.is_checked}
                              onChange={() => handleSegmentCheckbox(segment.id)}
                            />
                            <p
                              className={
                                segment.is_access
                                  ? "pref-seg-input-text"
                                  : "pref-seg-disable-text "
                              }
                            >
                              {segment.name}
                            </p>
                          </div>
                          {!segment.is_access && (
                            <div>
                              <span
                                data-tip
                                data-for="no-access"
                                style={{
                                  color: "#adb7c4",
                                  cursor: "pointer",
                                  marginTop: "6px",
                                }}
                                className="material-icons icon-fs-16"
                              >
                                lock
                              </span>
                              <ReactTooltip
                                id="no-access"
                                place="top"
                                effect="solid"
                              >
                                You don't have access for this segment
                              </ReactTooltip>
                            </div>
                          )}
                        </div>
                      ))
                    )}
                  </div>
                </div>
                <div className="pref-company-container">
                  <div className="pref-top-header">
                    <p>Selected Items</p>
                  </div>
                  <div className="pref-items-list-container">
                    {selectedReportTab?.segmentData.length === 0 ? (
                      <>
                        <p
                          style={{
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            width: "100%",
                          }}
                        >
                          No Items Available.
                        </p>
                      </>
                    ) : (
                      selectedReportTab?.segmentData?.map(
                        (segment) =>
                          segment.is_checked && (
                            <PreferenceCenterSegmentReportsBox
                              segment={segment}
                              isDisabled={isDisabled}
                            />
                          )
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        isOpen={modalIsOpen}
        style={{ width: "700px" }}
        className={"modal-lg modal-w-header custom-modal primary-modal"}
      >
        <div
          style={{ borderBottom: "1px solid #cdbfbf" }}
          className="d-flex align-items-center justify-content-between pb-2"
        >
          <h3 className="adv-pref-head">Advance Preferences</h3>
          <span
            onClick={closeModal}
            style={{ color: "#555555", float: "right", cursor: "Pointer" }}
            className="material-icons"
          >
            close
          </span>
        </div>

        <ModalBody>
          <div className="pref-seg-comp-container">
            <div className="pref-segment-container-modal">
              <div className="pref-top-header">
                <p>Segments</p>
              </div>
              <div className="pref-seg-list-container">
                {selectedReportTab?.segmentData?.map((segment) => (
                  <div
                    key={segment.id}
                    style={{ height: "35px", width: "85%" }}
                    className="d-flex align-items-center justify-content-between"
                  >
                    <div className="d-flex align-items-center">
                      <input
                        style={{ background: "#2E6AD2" }}
                        type="checkbox"
                        name="checkbox"
                        checked={segment.is_checked}
                        disabled={segment.is_access ? false : true}
                        onChange={() => handleSegmentCheckbox(segment.id)}
                      />
                      <p
                        className={
                          segment.is_access
                            ? segment.is_checked
                              ? "pref-seg-text-blue"
                              : "pref-seg-input-text"
                            : "pref-seg-disable-text"
                        }
                      >
                        {segment.name}
                      </p>
                    </div>
                    <div>
                      {segment.is_checked && (
                        <span
                          onClick={() => selectTheSegmentArrow(segment.id)}
                          className={`material-icons-outlined add-icon icon-fs-18 mt-1 ${
                            segment.is_arrow_selected
                              ? "adv-pref-arrow-move"
                              : "adv-pref-arrow"
                          }`}
                        >
                          arrow_forward
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="pref-company-container">
              <div className="pref-top-header">
                <p>Reports</p>
              </div>
              <div className="pref-items-company-container">
                <div className="adv-modal-search-div">
                  <input
                    className="adv-pref-search-input"
                    placeholder="Search Reports"
                    type="search"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                  <span
                    style={{ color: "#555555" }}
                    className="material-icons-outlined icon-fs-18 pr-3"
                  >
                    search
                  </span>
                </div>
                <div className="adv-modal-cmp-container">
                  {content.length > 0 ? (
                    content
                  ) : (
                    <p className="not-found-item">No item found</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <div className="mt-1 d-flex justify-content-end">
          <ModalFooter>
            <Button
              className="modal-btn btn-outline-primary"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              onClick={saveAdvancePreference}
              color="primary"
              className="modal-btn"
              type="submit"
            >
              Save
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </div>
  );
};

export default PreferenceCenterReportSettings;
