import { Axios } from "../lib/axios";

export const CustomerStudiesServices = {
  getCustomerStudies,
  getCustomerStudiesDetails,
  getCustomerStudiesContent,
  downloadFile,
  addRecentlyViewDetails,
};

function getCustomerStudies(url) {
  return Axios.get(url);
}

function getCustomerStudiesDetails(id) {
  return Axios.get(`customer-study/${id}`);
}

function downloadFile(customerStudyId) {
  return Axios.get(`/customer-study/file/${customerStudyId}`);
}

function getCustomerStudiesContent(customerstudyid) {
  return Axios.get(`customer-study/${customerstudyid}/content`);
}

function addRecentlyViewDetails(body) {
  return Axios.put(`updateRecentlyView`, body);
}
