import React, { Component } from "react";
import { connect } from "react-redux";
import { boards } from "../Store/Actions/Board";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import constants from "../Utils/constants";
import Notification from "../Components/Notification";
import CustomDropdownList from "../Components/CustomDropdownList";
import { BoardServices } from "../Services/Board";
import permissionCheck from "../lib/permissionCheck";
import Toast from "../Utils/Toast";
import Collapsible from "react-collapsible";
import Slugify from "../Utils/Slugify";

class PinToBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boardModal: true,
      boardModalHeader: (
        <>
          <div className="d-flex align-items-center">
            <span class="material-icons mr-2 ml-n1 mt-1">push_pin</span>
            {constants.APPLICATION_ROUTE.MY_BOARDS.PIN_TO_BOARD.HEADER_TITLE}
          </div>
        </>
      ),
      boardName: "",
      formErrors: {},
      apiError: null,
      boardId: null,
      showCreate: false,
      isFormSubmitted: false,
      isBoardSelected: false,
      isMapSelected: true,
      isEdit: false,
      selectedBoardId: null,
      boardCategories: [],
      category: [],
      boardCategoryId: null,
      boardSubCategoryId: null,
      boardSubCategoryName: this.props.subCategoryName,
      boradSubCategoryNameEdited: false,
    };
    this.subcategoryRef = React.createRef(null);
    this.formRef = React.createRef(null);
  }

  handleBoardChange = (e) => {
    if (Boolean(e.is_content_pinned)) return;
    let boards = [...this.props.board.boards];
    let showCreate = false;
    boards = boards.map((board) => {
      board.is_selected = board.id === e.id;
      return board;
    });
    const selectedBoard = boards.find((board) => board.id === e.id);
    this.setState({ selectedBoardId: selectedBoard.id });
    this.props.boards(boards);
    if (e.id === "other") {
      showCreate = true;
      this.setState({ isBoardSelected: false });
    } else {
      showCreate = false;
      this.setState({ isBoardSelected: true });
      this.getBoardCategoriesData(selectedBoard.id, false);
    }

    this.setState({
      isMapSelected: true,
      isEdit: false,
      showCreate: showCreate,
      formErrors: {
        ...this.state.formErrors,
        boardIdError: false,
      },
    });
    if (e.id && e.id === "other" && this.state.isFormSubmitted === true)
      this.isFormValid();
  };

  handleCreateBoard = () => {
    this.setState(
      {
        isFormSubmitted: true,
      },
      () => {
        if (!this.isFormValid()) {
          this.addToBoard(this.state.boardName, true);
        }
      }
    );
  };

  addToBoard = (name, addToPin = false) => {
    let prepareObject = {
      name: name,
    };
    BoardServices.addBoard(prepareObject)
      .then((res) => {
        Toast(
          (res && res.data && res.data.message) || "Added board successfully!",
          "success"
        );
        this.setState(
          {
            boardName: "",
            boardNameError: false,
            boardIdError: false,
            showCreate: false,
            boardModal: true,
            isMapSelected: false,
            isBoardSelected: true,
            selectedBoardId: res?.data?.data?.board?.id,
          },
          () => {
            this.props.getTotalBoards(res.data.data.board.id);
            // if (addToPin) this.pinToBoard(res.data.data.board.id);
          }
        );
      })
      .catch((error) => {
        this.setState({
          apiError:
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
        });
      });
  };

  pinToBoard = (id) => {
    let mapping_id = null;
    let newSubCategoryName = "";
    if (this.props.type === "project") {
      mapping_id = this.props.project_details.projectDetails.project.id;
      newSubCategoryName = this.findDefaultSubcategoryName(
        this.props.project_details.categories,
        this.props.pinning_sub_category_id
      );
    } else if (this.props.type === "benchmark") {
      mapping_id = this.props.benchmark_details.benchmarkDetails.benchmark.id;
      newSubCategoryName = this.findDefaultSubcategoryName(
        this.props.benchmark_details.categories,
        this.props.pinning_sub_category_id
      );
    } else if (this.props.type === "market_forecast") {
      mapping_id =
        this.props.market_forecast_details.marketForecastDetails.market_forecast
          .id;
      newSubCategoryName = this.findDefaultSubcategoryName(
        this.props.market_forecast_details.categories,
        this.props.pinning_sub_category_id
      );
    } else if (this.props.type === "market_landscape") {
      mapping_id =
        this.props.market_landscape_details.marketLandscapeDetails
          .market_landscape.id;
      newSubCategoryName = this.findDefaultSubcategoryName(
        this.props.market_landscape_details.categories,
        this.props.pinning_sub_category_id
      );
    } else if (this.props.type === "special_report") {
      mapping_id =
        this.props.special_reports.specialreportDetails.special_report.id;
      newSubCategoryName = this.findDefaultSubcategoryName(
        this.props.special_reports.categories,
        this.props.pinning_sub_category_id
      );
    } else if (this.props.type === "customer_study") {
      mapping_id =
        this.props.customer_study_details.customerStudyDetails.customerStudy.id;
      newSubCategoryName = this.findDefaultSubcategoryName(
        this.props.customer_study_details.categories,
        this.props.pinning_sub_category_id
      );
    } else if (this.props.type === "ecosystem") {
      mapping_id = this.props.ecosystem_details.ecosystemDetails.ecosystem.id;
      newSubCategoryName = this.findDefaultSubcategoryName(
        this.props.ecosystem_details.categories,
        this.props.pinning_sub_category_id
      );
    } else {
      let selectedSegment =
        this.props.company_details.companyDetails.company.segments.find(
          (s) => s.is_selected === true
        );
      newSubCategoryName = this.findDefaultSubcategoryName(
        this.props.company_details.categories,
        this.props.pinning_sub_category_id
      );
      mapping_id = selectedSegment.company_segment_id;
    }
    let fitlerDefaultSubCategoryName = this.state.category?.subcategories.find(
      (i) => i.default
    )?.name;
    let prepareObject = {
      mapping_id: mapping_id,
      subcategory_id: this.props.pinning_sub_category_id,
      board_category_id: this.state.boardCategoryId,
      board_subcategory_id: this.state.boardSubCategoryId,
      board_subcategory_name:
        this.state.boardSubCategoryId !== null
          ? null
          : fitlerDefaultSubCategoryName,
    };
    BoardServices.pinToBoard(id, this.props.type, prepareObject)
      .then((res) => {
        this.props.successAddToBoard();
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
        this.setState({
          apiError:
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
        });
      });
  };

  findDefaultSubcategoryName = (categories, subcatId) => {
    let name = "";
    for (const category of categories) {
      for (const subcategory of category.subcategories) {
        if (subcategory.id === subcatId) {
          name = subcategory.name;
          break;
        }
      }
    }
    return name;
  };

  handleSubmit = () => {
    let boards = [...this.props.board.boards];
    let selectedBoard = boards.find((b) => b.is_selected === true);
    let boardIdError = false;
    if (selectedBoard) {
      if (selectedBoard.id === "other") this.handleCreateBoard();
      else this.pinToBoard(selectedBoard.id);
    } else {
      boardIdError = true;
    }
    this.setState({
      formErrors: {
        ...this.state.formErrors,
        boardIdError: boardIdError,
      },
    });
  };

  closeBoardModal = () => {
    this.setState(
      {
        boardModal: false,
      },
      () => {
        this.props.closeBoardModal();
      }
    );
  };

  isFormValid = () => {
    let formError = false;
    let boardNameError = false;
    let boardIdError = false;
    if (this.state.boardName.trim() === "") {
      boardNameError = true;
      formError = true;
    }
    this.setState({
      ...this.state,
      showCreate: true,
      formErrors: {
        ...this.state.formErrors,
        boardNameError: boardNameError,
        boardIdError: boardIdError,
      },
      apiError: null,
    });
    return formError;
  };

  onBoardNameChangeHandler = (e) => {
    this.setState(
      {
        boardName: e.target.value,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };

  handleCreate = (e) => {
    this.addToBoard(e);
  };

  // toggle between the map and add category
  toggleCategoryView = (type) => {
    if (type === "map") {
      this.setState({ isMapSelected: true, isEdit: false });
    } else {
      this.setState({ isMapSelected: false });
    }
  };

  // select map categories item
  handleMapCategorySelection = (categoryId) => {
    const categories = this.state.boardCategories;
    const updateCategoryItems = categories.map((cat) => {
      return {
        ...cat,
        is_selected: categoryId === cat.id,
        subcategories: cat.subcategories.map((subcat, index) => ({
          ...subcat,
          is_selected:
            categoryId === cat.id && cat.subcategories?.length === index + 1,
        })),
      };
    });
    this.setState({
      boardCategoryId: categoryId,
      boardCategories: updateCategoryItems,
    });
  };

  // select map subcategories item
  selectSubcategoryRadioButton = (catId, subCatId) => {
    const boards = [...this.state.boardCategories];
    const updateBoardCategories = boards.map((board) => ({
      ...board,
      subcategories: board.subcategories.map((subCat) => ({
        ...subCat,
        is_selected: subCat.id == subCatId && board.id == catId,
      })),
    }));
    this.setState({
      boardCategoryId: catId,
      boardSubCategoryId: subCatId,
      boardCategories: updateBoardCategories,
    });
  };

  addSubCategory = () => {
    let x = document.createElement("div");
    x.className = "input-wrapper";
    x.innerHTML =
      '<input type="text" class="form-control board-sub-cat" id="subCategoryName" placeholder="Enter Subcategory Name" />';
    this.subcategoryRef.current.appendChild(x);
  };

  // fetch board categories data
  getBoardCategoriesData = (boardId, lastIndex) => {
    BoardServices.getBoardCategories(boardId)
      .then((res) => {
        let data = res.data.categories;
        const updatedCategoryData = data.map((category, index) => {
          let defaultSubCategory = {
            id: null,
            name: this.state.boardSubCategoryName,
            is_selected: lastIndex ? index == data?.length - 1 : index == 0,
            rank: category.subcategories?.length + 1,
            default: true,
          };
          let modifySubcategoryData = category.subcategories.map((i) => ({
            ...i,
            default: false,
          }));
          let fitlerDefaultEditedData =
            this.state.category?.subcategories?.filter((i) => i?.default) || [];

          let subcategories = !this.state.boradSubCategoryNameEdited
            ? [...modifySubcategoryData, defaultSubCategory]
            : [...modifySubcategoryData, ...fitlerDefaultEditedData];

          return {
            ...category,
            is_selected: lastIndex ? index == data?.length - 1 : index == 0,
            subcategories,
          };
        });
        let isDataAvailable = updatedCategoryData.length > 0;
        this.setState({
          boardCategories: updatedCategoryData,
          isMapSelected: isDataAvailable,
          category: updatedCategoryData[0],
          boardCategoryId: updatedCategoryData.find((cat) => cat.is_selected)
            ?.id,
        });
      })
      .catch((error) => {
        this.setState({ boardCategories: [] });
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  // create and update the board categories
  createAndUpdateBoardCategory = (boardId, body) => {
    BoardServices.addAndUpdateBoardCategory(boardId, body)
      .then((res) => {
        this.getBoardCategoriesData(boardId, true);
        Toast(
          res && res.data && res.data.message
            ? res.data.message
            : "Added Category Successfully!",
          "success"
        );
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
    this.setState({ isEdit: false });
  };

  openEditCategoryForm = (category) => {
    this.setState({ isEdit: true, isMapSelected: false, category: category });
    setTimeout(() => {
      if (this.formRef.current?.length > 0) {
        this.formRef.current[0].value = category.name;

        if (category.subcategories) {
          // if (
          //   this.formRef.current.length - 2 <=
          //   category.subcategories.length
          // ) {
          category.subcategories.forEach((item, index) => {
            // if (index <= category.subcategories.length - 2) {
            this.addSubCategory();
            this.formRef.current[index + 2].value =
              category.subcategories[index].name;
            // }
          });
          // }
        }
      }
    }, 200);
  };

  prepareObjectForAddEditCategory = (event) => {
    event.preventDefault();
    if (event.target[0].value.trim() != "") {
      let data = [];
      for (let i = 0; i < event.target.length; i++) {
        if (event.target[i].type === "text") {
          if (
            event.target[i].value != "" &&
            event.target[i].value !== "Create new sub category as per selection"
          ) {
            event.target[i].value != "" && data.push(event.target[i].value);
          } else {
            Toast("Sub Category name cannot be empty", "error");
            return;
          }
        }
      }
      if (this.state.isEdit) {
        // update the category - sub-cat or if not exist then create the new sub category
        let category = JSON.parse(JSON.stringify({ ...this.state.category }));

        category.subcategories.pop();

        data.forEach((item, index) => {
          if (index >= 1) {
            if (category.subcategories[index - 1] !== undefined) {
              category.subcategories[index - 1].name = item;
            } else if (
              (typeof this.state.category.subcategories[index - 1]?.default ===
                "boolean" &&
                !this.state.category.subcategories[index - 1]?.default) ||
              !this.state.category.subcategories[index - 1]?.default
            ) {
              let newSubcategory = {
                id: null,
                name: item,
                rank: index,
              };
              category.subcategories.push(newSubcategory);
            } else {
              this.state.category.subcategories[index - 1].name = item;
            }
          }
        });

        const updatedCategory = {
          id: category.id,
          name: data[0],
          rank: category.rank,
          subcategories: category.subcategories.map((subcategory, index) => ({
            id: subcategory.id,
            name: subcategory.name,
            rank: subcategory.rank,
          })),
        };
        this.createAndUpdateBoardCategory(
          this.state.selectedBoardId,
          updatedCategory
        );
        this.setState({
          boradSubCategoryNameEdited: true,
        });
        setTimeout(() => {
          this.setState({
            isMapSelected: true,
            boradSubCategoryNameEdited: true,
          });
        }, 800);
      } else {
        // create and add the new category content
        const newCategory = {
          id: null,
          name: data[0],
          rank: this.state.boardCategories?.length + 1,
          subcategories: data.slice(1).map((subcategory, index) => ({
            id: null,
            name: subcategory,
            rank: index + 1,
          })),
        };
        this.createAndUpdateBoardCategory(
          this.state.selectedBoardId,
          newCategory
        );
        setTimeout(() => {
          this.setState({ isMapSelected: true });
        }, 800);
      }
    } else {
      Toast("Category name cannot be empty", "error");
    }
  };

  customValueComponent = ({ item }) => {
    return (
      <div className="d-flex text-align-center justify-content-between">
        <span
          data-for={`tooltip-${item.name}-profile-info`}
          data-tip={item.name}
          data-iscapture="true"
        >
          {item.name}
        </span>
        <div>
          {Boolean(item.is_content_pinned) && (
            <span
              className="material-icons text-gray col-gray"
              style={{ fontSize: "15px" }}
            >
              push_pin
            </span>
          )}
        </div>
      </div>
    );
  };

  render() {
    let boards =
      this.props.board && this.props.board.boards
        ? this.props.board.boards
        : [];
    let disabledBoards = boards.filter((b) => !b.disable);
    let disabledBoardsList = boards.filter((b) => b.is_content_pinned);
    let showCreate = permissionCheck(
      constants.ACL.PERMISSION_KEY,
      constants.ACL.MY_BOARDS_RESOURCE.TITLE,
      constants.ACL.MY_BOARDS_RESOURCE.ACTIONS.CREATE
    );
    return (
      <Modal
        isOpen={this.state.boardModal}
        toggle={this.closeBoardModal}
        className={"modal-md modal-w-header custom-modal primary-modal"}
      >
        <ModalHeader toggle={this.closeBoardModal}>
          {this.state.boardModalHeader}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <form className={"col-sm-12 form-wrapper"}>
              {this.state.apiError !== null && (
                <Notification color={"danger"} message={this.state.apiError} />
              )}
              <div className={"row"}>
                <div className={"col-sm-12"}>
                  <label for="companies">
                    Board <span className={"mandatory"}>* </span>
                  </label>
                  <div className="custom-combobox-list">
                    <CustomDropdownList
                      data={disabledBoards}
                      handleChange={this.handleBoardChange}
                      placeholder={"Select Board"}
                      allowCreate={showCreate ? "onFilter" : false}
                      handleCreate={this.handleCreate}
                      itemComponent={this.customValueComponent}
                      isDisabled={disabledBoardsList}
                    />
                  </div>
                  {this.state.formErrors.boardIdError && (
                    <div
                      className="invalid-tooltip"
                      style={{ display: "block" }}
                    >
                      {constants.FORM.MANDATORY_FIELD}
                    </div>
                  )}
                </div>
                {showCreate && (
                  <>
                    {this.state.showCreate && (
                      <>
                        <div className="col-sm-12 mt-2 position-relative">
                          <label for="boardName">
                            Board Name <span className={"mandatory"}>* </span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              this.state.formErrors.boardNameError === true
                                ? "is-invalid"
                                : ""
                            } ${
                              this.state.formErrors.boardNameError === false
                                ? ""
                                : ""
                            }`}
                            id="boardName"
                            placeholder="Board Name"
                            value={this.state.boardName}
                            onChange={this.onBoardNameChangeHandler}
                          />
                          {this.state.formErrors.boardNameError && (
                            <div className="invalid-tooltip">
                              {constants.FORM.MANDATORY_FIELD}
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}
                {this.state.isBoardSelected && (
                  <div className="">
                    <div className="col-sm-12 ml-1 d-flex mt-3 position-relative">
                      {this.state.boardCategories?.length > 0 && (
                        <div
                          onClick={() => this.toggleCategoryView("map")}
                          className={
                            this.state.isMapSelected
                              ? "selected-map-header mr-4"
                              : "board-map-header mr-4"
                          }
                        >
                          <span class="material-icons-outlined icon-fs-18 mt-1">
                            comment_bank
                          </span>
                          <p>Map Categories</p>
                        </div>
                      )}

                      <div
                        onClick={() => this.toggleCategoryView("add")}
                        className={
                          this.state.isMapSelected
                            ? "board-map-header"
                            : "selected-map-header"
                        }
                      >
                        <span class="material-icons-outlined icon-fs-18">
                          {this.state.isEdit ? "edit" : "add"}
                        </span>
                        <p>{this.state.isEdit ? "Edit" : "Add"} Categories</p>
                      </div>
                    </div>

                    {this.state.isMapSelected ? (
                      <div className="map-category-container">
                        {this.state.boardCategories?.length === 0 ? (
                          <div className="d-flex flex-column justify-content-center align-items-center">
                            <img
                              className="mt-3"
                              style={{ width: "40%" }}
                              src="https://tbr-ggk.s3.us-east-2.amazonaws.com/staging/no-data.png"
                              alt=""
                            />
                            <h3 className="no-data-text">No Record Found</h3>
                          </div>
                        ) : (
                          <>
                            {this.state.boardCategories.map((category) => (
                              <Collapsible
                                open={category?.is_selected}
                                triggerSibling={() => (
                                  <div
                                    className={
                                      category?.is_selected
                                        ? "map-category-item-selected"
                                        : "map-category-item"
                                    }
                                  >
                                    <div className="d-flex">
                                      <div className="map-design-div"></div>
                                      <h3>{category.name}</h3>
                                    </div>
                                    <div className="map-checkboxes-div">
                                      <input
                                        className="custom-checkbox-new"
                                        type="checkbox"
                                        name={category.name}
                                        checked={category.is_selected}
                                        id={Slugify(category.name)}
                                        onChange={() =>
                                          this.handleMapCategorySelection(
                                            category.id
                                          )
                                        }
                                      />
                                      <span
                                        style={{ color: "#2e6ad2" }}
                                        className="material-icons-outlined icon-fs-18"
                                        onClick={() =>
                                          this.openEditCategoryForm(category)
                                        }
                                      >
                                        edit
                                      </span>
                                    </div>
                                  </div>
                                )}
                              >
                                <div className="map-item-content">
                                  {category?.subcategories?.map(
                                    (subcategory) => (
                                      <div className="map-single-item">
                                        <h2
                                          className={
                                            subcategory?.id === null
                                              ? "new-sub-cat-head"
                                              : "sub-cat-head"
                                          }
                                        >
                                          {subcategory?.name?.length >= 42
                                            ? `${subcategory?.name?.slice(
                                                0,
                                                40
                                              )}...`
                                            : subcategory?.name}
                                        </h2>
                                        <input
                                          type="radio"
                                          name="subcategoryRadioGroup"
                                          id="subcategoryRadio"
                                          checked={subcategory?.is_selected}
                                          onChange={() =>
                                            this.selectSubcategoryRadioButton(
                                              category.id,
                                              subcategory.id
                                            )
                                          }
                                        />
                                      </div>
                                    )
                                  )}
                                  {/* <div className="map-single-item">
                                    <h2 className="new-sub-cat-head">
                                      {this.state.boardSubCategoryName}
                                    </h2>
                                    <input
                                      type="radio"
                                      name="subcategoryRadioGroup"
                                      id="subcategoryRadio"
                                      checked={true}
                                      onChange={() =>
                                        this.selectSubcategoryRadioButton(
                                          category.id,
                                          null
                                        )
                                      }
                                    />
                                  </div> */}
                                </div>
                              </Collapsible>
                            ))}
                          </>
                        )}
                      </div>
                    ) : (
                      <div>
                        <div className="custom-popover add-edit-category-subcategory-popover board-pin-modal-add-category">
                          <div>
                            <h5 className="board-pin-modal-header">
                              {this.state.isEdit ? "Edit" : "Add"} Category /
                              Subcategory
                            </h5>
                            <form
                              onSubmit={(e) =>
                                this.prepareObjectForAddEditCategory(e)
                              }
                              ref={this.formRef}
                            >
                              <div className="cat-subcat-inputs-block">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="categoryName"
                                  placeholder="Enter Category Name"
                                />
                                <button
                                  type="button"
                                  className="btn btn-primary content-save-btn mb-2 w-auto float-none add-sub-category d-flex align-items-center pl-1"
                                  onClick={this.addSubCategory}
                                >
                                  <span className="material-icons-outlined icon icon-fs-18 text-black2">
                                    add
                                  </span>
                                  Add Sub Category
                                </button>
                                <div
                                  className="sub-category-inputs"
                                  ref={this.subcategoryRef}
                                ></div>
                              </div>
                              <div className="board-modal-save-btn-wrapper">
                                <button
                                  className="cancel-btn"
                                  onClick={this.closeBoardModal}
                                >
                                  Cancel
                                </button>
                                <button type="submit" className="save-btn">
                                  Save
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </form>
          </div>
        </ModalBody>
        {this.state.isMapSelected && (
          <ModalFooter>
            <Button
              className="modal-btn btn-outline-primary"
              onClick={this.closeBoardModal}
            >
              Cancel
            </Button>
            <Button
              className="modal-btn"
              color="primary"
              onClick={this.handleSubmit}
            >
              Save
            </Button>{" "}
          </ModalFooter>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    board: state.board,
    company_details: state.company_details,
    project_details: state.project_details,
    benchmark_details: state.benchmark_details,
    market_forecast_details: state.market_forecast_details,
    market_landscape_details: state.market_landscape_details,
    special_reports: state.special_reports,
    customer_study_details: state.customer_study_details,
    ecosystem_details: state.ecosystem_details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    boards: (payload) => {
      dispatch(boards(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PinToBoard);
