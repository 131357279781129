import { benchmark_details as DETAILS } from "../actionConstants";

const benchmarkCategories = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.BENCHMARK_CATEGORIES:
      return action.payload;
    default:
      return state;
  }
};

const benchmarkDetails = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.BENCHMARK_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

const categories = (state = [], action) => {
  switch (action.type) {
    case DETAILS.CATEGORIES:
      return action.payload;
    default:
      return state;
  }
};

const benchmarkContent = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.BENCHMARK_CONTENT:
      return action.payload;
    default:
      return state;
  }
};

export { benchmarkCategories, benchmarkDetails, categories, benchmarkContent };
