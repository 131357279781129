import { combineReducers } from "redux";
import * as DETAILS_REF from "./reducerReference/specialreportsDetailsReference";

const specialreportCategories = DETAILS_REF.specialreportCategories;
const specialreportDetails = DETAILS_REF.specialreportDetails;
const categories = DETAILS_REF.categories;
const specialreportContent = DETAILS_REF.specialreportContent;

const specialReportReducer = combineReducers({
  specialreportCategories,
  specialreportDetails,
  categories,
  specialreportContent,
});

export default specialReportReducer;
