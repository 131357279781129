import React, { useEffect, useState } from "react";
import { VisualizationServicesV2 } from "../../../Services/VisualizationV2";
import "./index.scss";
import CompanySection from "./SelectSection/CompanySection";
import SegmentSection from "./SelectSection/SegmentSection";
import MetricsSection from "./SelectSection/MetricsSection";
import GeoVerticalSection from "./SelectSection/GeoVerticalSection";
import { map, isUndefined, get, orderBy } from "lodash";
import Toast from "../../../Utils/Toast";
import { CommonFunctions } from "../../../Containers/V2/VisalizationV2/CommonFunction";

function CompanyTab({
  paramId,
  selectedNode,
  setSelectedNode,
  setMetricGroupId,
  metricGroupId,
}) {
  const [companyList, setCompanyList] = useState([]);
  const [backupCompayList, setBackupCompayList] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [segmentList, setSegmentList] = useState([]);
  const [texonomyList, setTexonomyList] = useState([]);
  const [selectedTexonomy, setSelectedTexonomy] = useState([]);
  const [expandedTexonomy, setExpandedTexonomy] = useState([]);
  const [selectedSegments, setSelectedSegments] = useState([]);
  const [expandedSegments, setExpandedSegments] = useState([]);
  const [id, setId] = useState(0);
  const [disableSegmentBox, setDisableSegmentBox] = useState(false);
  const [metricsTypeId, setMetricsTypeId] = useState();
  const [metricTypeList, setMetricTypeList] = useState([]);
  const [selectedMetrics, setSelectedMetrics] = useState([]);
  const [geoList, setGeoList] = useState([]);
  const [verticalList, setVerticalList] = useState([]);
  const [showGeo, setShowGeo] = useState(false);
  const [expandedGeos, setExpandedGeos] = useState([]);
  const [selectedGeo, setSelectedGeo] = useState([]);
  const [selectedVertical, setSelectedVertical] = useState([]);
  const [expandedVarticals, setExpandedVarticals] = useState([]);
  const [disableMetricsBox, setDisableMetricsBox] = useState(false);
  const [disableGroVerticalBox, setDisableGroVerticalBox] = useState(false);
  const [disabledCompany, setDisabledCompanyBox] = useState(true);
  const [expandedGroup, setExpandedGroup] = useState([]);

  const handleByCompanyTrigger = (checked) => {
    setSelectedCompanies(checked);
  };

  const handleBySegmentTrigger = (checked) => {
    setSelectedSegments(checked);
  };

  const childrenStructure = (nodes, name) => {
    return map(nodes, (node) => {
      const addField = {
        ...node,
        value: node.id,
        label: node[name],
      };

      if (isUndefined(node.is_data_available)) {
        const hasData =
          node.total_geos ||
          node.total_quarters ||
          node.total_verticals ||
          node.total_years ||
          node[name] === "global" ||
          node[name] === "all industries";

        addField.is_data_available = hasData ? "true" : "false";
      }

      if (get(addField, "children.length", 0) > 0) {
        addField.children = childrenStructure(addField.children, name);
      }

      return addField;
    });
  };

  const addSegment = async () => {
    let input = { company_ids: selectedCompanies };
    let segmentRes = await VisualizationServicesV2.getSegmentsByCompany(input);
    let data = childrenStructure(segmentRes.data.data, "segment_name");
    setSegmentList(data);
    setExpandedSegments(CommonFunctions.collectIdsForAllNodes(data));
    if (!paramId) {
      setSelectedSegments([]);
      setSelectedTexonomy([]);
    }
    setDisableSegmentBox(true);
    setDisabledCompanyBox(false);
    let texonomyRes = await VisualizationServicesV2.getTexonomyByCompany(input);
    let data1 = texonomyRes.data.data;
    let temp = [];
    data1.forEach((item) => {
      temp.push({
        ...item,
        group: true,
        value: item.id,
        label: item.group_name,
        checked:
          item.id === metricGroupId
            ? [...new Set(selectedNode.map((i) => i.segment_id))]
            : [],
        children: childrenStructure(item.segments, "segment_name"),
      });
    });

    setTexonomyList(temp);
    setExpandedTexonomy(CommonFunctions.collectIdsForAllNodes(temp));
    setExpandedGroup(temp.map((i) => i.id));
  };

  const addMetrics = async () => {
    let input = {
      company_ids: selectedCompanies,
      segment_ids: selectedSegments.length
        ? selectedSegments
        : texonomyList.filter((i) => i.checked.length)[0]?.checked,
    };

    let metricsRes =
      await VisualizationServicesV2.getMetricsBycompanyAndSegment(input);
    let data = metricsRes.data.data.map((i) => ({
      ...i,
      id: i.metric_type_id,
      label: i.metric_type_name,
      metrics: i.metrics.map((el) => {
        if (
          el?.total_geos ||
          el?.total_quarters ||
          el?.total_verticals ||
          el?.total_years
        ) {
          el.is_data_available = "true";
        } else {
          el.is_data_available = "false";
        }
        return el;
      }),
    }));

    // Function to check if any data has is_data_available set to true
    const hasDataAvailable = (data) =>
      data.metrics.some((d) => d.is_data_available === "true");

    // Sort the data array within each object
    const sortedInnerData = data.map((item) => ({
      ...item,
      metrics: orderBy(
        item.metrics,
        [(d) => d.is_data_available === "true"],
        ["desc"]
      ),
    }));

    // Sort the array with true values first
    const sortedData = orderBy(sortedInnerData, [hasDataAvailable], ["desc"]);

    setMetricTypeList(sortedData);
    setMetricsTypeId(sortedData[0].metric_type_id);
    setDisableSegmentBox(false);
    setDisabledCompanyBox(false);
    setDisableMetricsBox(true);
  };

  const handleMetricsType = (e) => {
    setMetricsTypeId(e.id);
  };

  const handleByMetricsTrigger = (e) => {
    setSelectedMetrics(e);
  };

  const handleGeoAndVertical = async () => {
    let input = {
      company_ids: selectedCompanies,
      segment_ids: selectedSegments.length
        ? selectedSegments
        : texonomyList.filter((i) => i.checked.length)[0]?.checked,
      metric_ids: selectedMetrics,
    };
    let geoRes = await VisualizationServicesV2.getGeoByCompanySegmentAndMetrics(
      input
    );
    let goeData = childrenStructure(geoRes.data.data.geos, "geo_name");
    setGeoList(goeData);
    setSelectedGeo([goeData.filter((i) => i.geo_name === "global")?.[0]?.id]);

    let verticalRes =
      await VisualizationServicesV2.getVerticalByCompanySegmentAndMetrics(
        input
      );
    let verticalData = childrenStructure(
      verticalRes.data.data.verticals,
      "vertical_name"
    );
    setVerticalList(verticalData);

    setSelectedVertical([
      verticalData.filter((i) => i.vertical_name === "all industries")?.[0]?.id,
    ]);
    setDisableMetricsBox(false);
    setDisableGroVerticalBox(true);
  };

  const handleChevron = (value, flag) => {
    if (flag) {
      setExpandedGroup([...expandedGroup, value]);
    } else {
      setExpandedGroup(expandedGroup.filter((i) => i !== value));
    }
  };

  const createRules = async () => {
    try {
      let input = {
        company_ids: selectedCompanies,
        segment_ids: selectedSegments.length
          ? selectedSegments
          : texonomyList.filter((i) => i.checked.length)[0]?.checked,
        metric_ids: selectedMetrics,
        geo_ids: selectedGeo,
        vertical_ids: selectedVertical,
      };

      let rulesRes = await VisualizationServicesV2.fetchChartRules(input);
      if (!rulesRes.data.data.length) {
        Toast("Data not available", "error");
      }
      let uniqueObjects = [...selectedNode, ...rulesRes.data.data].filter(
        (obj, index, self) =>
          index ===
          self.findIndex((t) => JSON.stringify(t) === JSON.stringify(obj))
      );
      setSelectedNode(uniqueObjects);
      setMetricGroupId(texonomyList.filter((i) => i.checked.length)[0]?.id);
      setDisabledCompanyBox(true);
      setDisableGroVerticalBox(false);
      setDisableMetricsBox(false);
      setSegmentList([]);
      setTexonomyList([]);
      setGeoList([]);
      setVerticalList([]);
      setMetricTypeList([]);
      setMetricsTypeId();
      setExpandedSegments([]);
      setExpandedTexonomy([]);
      setExpandedGeos([]);
      setExpandedVarticals([]);
      setShowGeo(false);
      if (!paramId) {
        setSelectedCompanies([]);
        setSelectedSegments([]);
        setSelectedTexonomy([]);

        setSelectedMetrics([]);
        setSelectedGeo([]);
        setSelectedVertical([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setSelectedData = async () => {
    setSelectedCompanies([...new Set(selectedNode.map((i) => i.company_id))]);
    if (!metricGroupId) {
      setSelectedSegments([...new Set(selectedNode.map((i) => i.segment_id))]);
      setExpandedSegments([...new Set(selectedNode.map((i) => i.segment_id))]);
    }

    setSelectedMetrics([...new Set(selectedNode.map((i) => i.metric_id))]);
    setSelectedGeo([...new Set(selectedNode.map((i) => i.geo_id))]);
    setExpandedGeos([...new Set(selectedNode.map((i) => i.geo_id))]);
    setSelectedVertical([...new Set(selectedNode.map((i) => i.vertical_id))]);
    setExpandedVarticals([...new Set(selectedNode.map((i) => i.vertical_id))]);
  };

  useEffect(() => {
    if (paramId) {
      setSelectedData();
    }
  }, [selectedNode]);

  return (
    <>
      {/* Company Section */}
      <CompanySection
        disableMetricsBox={disableMetricsBox}
        disableGroVerticalBox={disableGroVerticalBox}
        handleByCompanyTrigger={handleByCompanyTrigger}
        addSegment={addSegment}
        selectedCompanies={selectedCompanies}
        disabledCompany={disabledCompany}
        paramId={paramId}
      />

      {/* Segment Section */}
      <SegmentSection
        disableSegmentBox={disableSegmentBox}
        segmentList={segmentList}
        selectedSegments={selectedSegments}
        expandedSegments={expandedSegments}
        setExpandedSegments={setExpandedSegments}
        handleBySegmentTrigger={handleBySegmentTrigger}
        addMetrics={addMetrics}
        setDisableSegmentBox={setDisableSegmentBox}
        setSelectedSegments={setSelectedSegments}
        setSegmentList={setSegmentList}
        setDisabledCompanyBox={setDisabledCompanyBox}
        paramId={paramId}
        texonomyList={texonomyList}
        setTexonomyList={setTexonomyList}
        expandedTexonomy={expandedTexonomy}
        setExpandedTexonomy={setExpandedTexonomy}
        selectedTexonomy={selectedTexonomy}
        setSelectedTexonomy={setSelectedTexonomy}
        handleChevron={handleChevron}
        setExpandedGroup={setExpandedGroup}
        expandedGroup={expandedGroup}
      />

      {/* Metrics Section */}
      <MetricsSection
        disableMetricsBox={disableMetricsBox}
        metricTypeList={metricTypeList}
        metricsTypeId={metricsTypeId}
        handleMetricsType={handleMetricsType}
        selectedMetrics={selectedMetrics}
        setDisableSegmentBox={setDisableSegmentBox}
        setDisabledCompanyBox={setDisabledCompanyBox}
        setDisableMetricsBox={setDisableMetricsBox}
        handleGeoAndVertical={handleGeoAndVertical}
        handleByMetricsTrigger={handleByMetricsTrigger}
        setSelectedMetrics={setSelectedMetrics}
        setMetricTypeList={setMetricTypeList}
        setMetricsTypeId={setMetricsTypeId}
        paramId={paramId}
      />

      {/* Geo and Vertical Section */}
      <GeoVerticalSection
        disableGroVerticalBox={disableGroVerticalBox}
        showGeo={showGeo}
        setShowGeo={setShowGeo}
        verticalList={verticalList}
        selectedVertical={selectedVertical}
        setSelectedVertical={setSelectedVertical}
        expandedVarticals={expandedVarticals}
        setExpandedVarticals={setExpandedVarticals}
        geoList={geoList}
        selectedGeo={selectedGeo}
        setSelectedGeo={setSelectedGeo}
        expandedGeos={expandedGeos}
        setExpandedGeos={setExpandedGeos}
        setDisableGroVerticalBox={setDisableGroVerticalBox}
        setDisableMetricsBox={setDisableMetricsBox}
        createRules={createRules}
        paramId={paramId}
        setGeoList={setGeoList}
        setVerticalList={setVerticalList}
      />
    </>
  );
}

export default CompanyTab;
