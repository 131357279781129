import React, { useState, useEffect } from "react";
import DynamicTableComponent from "./DynamicTableComponent";
import constants from "../Utils/constants";
import { formatRoute } from "react-router-named-routes";
import { useHistory } from "react-router-dom";

const vendorSpecificReportOption = [
  { id: 1, name: "Company Research", isSelected: true, percentage: "" },
  {
    id: 2,
    name: "Company Snapshot",
    isSelected: false,
    percentage: "",
  },
  { id: 3, name: "Company Profile", isSelected: false, percentage: "" },
  {
    id: 4,
    name: "Benchmark Coverage",
    isSelected: false,
    percentage: "",
  },
];

const reportTableHeader = [
  {
    id: "report_name",
    title: "Report Names",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "percentage",
    title: "Number of Views",
    sortable: false,
    style: {
      width: "100px",
      textAlign: "left",
      textTransform: "capitalize",
      marginLeft: "10px",
    },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
];

const tableOptions = [
  {
    name: "Company-Specific Research",
    isSelected: true,
    slug: "company",
    isHover: false,
    percentage: 0,
  },

  {
    name: "Benchmark",
    isSelected: false,
    slug: "benchmark",
    isHover: false,
    percentage: 0,
  },
  {
    name: "Special Report",
    isSelected: false,
    slug: "special_reports",
    isHover: false,
    percentage: 0,
  },
  {
    name: "Market Forecast",
    isSelected: false,
    slug: "forecast",
    isHover: false,
    percentage: 0,
  },
  {
    name: "Market Landscape",
    isSelected: false,
    slug: "landscape",
    isHover: false,
    percentage: 0,
  },
  {
    name: "Customer Study",
    isSelected: false,
    slug: "customer_study",
    isHover: false,
    percentage: 0,
  },
  {
    name: "Ecosystem",
    isSelected: false,
    slug: "ecosystem",
    isHover: false,
    percentage: 0,
  },
];

const ReportUsageTable = ({
  data,
  redirectToBigTable,
  goToReportViewedDetailPage,
}) => {
  let history = useHistory();
  const [reportOptions, setReportOptions] = useState(tableOptions);
  const [selectedReport, setSelectedReport] = useState(
    "Company-Specific Research"
  );
  const [rowData, setRowData] = useState([]);
  const [vendorOption, setVendorOption] = useState(vendorSpecificReportOption);
  const [selectedVendor, setSelectedVendor] = useState("Company Research");

  const updateDataBasedValue = () => {
    const totalVendorSum =
      data?.total_benchmark_coverage +
      data?.total_vendor_profile +
      data?.total_vendor_reports +
      data?.total_vendor_snapshot;

    const totalValue =
      totalVendorSum +
      data?.total_benchmarks +
      data?.total_market_forecasts +
      data?.total_market_landscapes +
      data?.total_special_reports +
      data?.total_customer_studies +
      data?.total_ecosystems;

    reportOptions.map((item) => {
      if (item.slug === "company") {
        item.percentage = calculatePercentage(totalValue, totalVendorSum);
      } else if (item.slug === "benchmark") {
        item.percentage = calculatePercentage(
          totalValue,
          data?.total_benchmarks
        );
      } else if (item.slug === "special_reports") {
        item.percentage = calculatePercentage(
          totalValue,
          data?.total_special_reports
        );
      } else if (item.slug === "forecast") {
        item.percentage = calculatePercentage(
          totalValue,
          data?.total_market_forecasts
        );
      } else if (item.slug === "landscape") {
        item.percentage = calculatePercentage(
          totalValue,
          data?.total_market_landscapes
        );
      } else if (item.slug === "customer_study") {
        item.percentage = calculatePercentage(
          totalValue,
          data?.total_customer_studies
        );
      } else if (item.slug === "ecosystem") {
        item.percentage = calculatePercentage(
          totalValue,
          data?.total_ecosystems
        );
      }
    });

    vendorOption.map((item) => {
      if (item.name === "Company Research") {
        item.percentage = calculatePercentage(
          totalVendorSum,
          data?.total_vendor_reports
        );
      } else if (item.name === "Company Snapshot") {
        item.percentage = calculatePercentage(
          totalVendorSum,
          data?.total_vendor_snapshot
        );
      } else if (item.name === "Company Profile") {
        item.percentage = calculatePercentage(
          totalVendorSum,
          data?.total_vendor_profile
        );
      } else if (item.name === "Benchmark Coverage") {
        item.percentage = calculatePercentage(
          totalVendorSum,
          data?.total_benchmark_coverage
        );
      }
    });
  };

  const calculatePercentage = (total, sum) => {
    const result = (sum * 100) / total;
    return result.toFixed(2);
  };

  const goToReportDetail = (data) => {
    const findSelectedReport = reportOptions.find(
      (report) => report.isSelected
    );
    const type = findSelectedReport.slug;
    if (!data.hasOwnProperty("others")) {
      if (type === "company") {
        goToReportViewedDetailPage(type, data);
      } else {
        let path =
          type === "company"
            ? constants.APPLICATION_ROUTE.COMPANY.VIEW.ROUTE
            : type === "benchmark"
            ? constants.APPLICATION_ROUTE.BENCHMARK.VIEW.ROUTE
            : type === "forecast"
            ? constants.APPLICATION_ROUTE.MARKET_FORECAST.VIEW.ROUTE
            : type === "special_reports"
            ? constants.APPLICATION_ROUTE.SPECIAL_REPORTS.VIEW.ROUTE
            : type === "landscape"
            ? constants.APPLICATION_ROUTE.MARKET_LANDSCAPE.VIEW.ROUTE
            : type === "customer_study"
            ? constants.APPLICATION_ROUTE.CUSTOMER_STUDIES.VIEW.ROUTE
            : type === "ecosystem"
            ? constants.APPLICATION_ROUTE.ECOSYSTEM.VIEW.ROUTE
            : null;
        history.push({
          pathname: formatRoute(path, {
            id: type === "company" ? data.company_id : data.id,
          }),
        });
      }
    } else {
      redirectToBigTable(type);
    }
  };

  const formatRowData = (reportData, isSegmentVisible = false) => {
    const convertToTableData = reportData?.map((obj) => {
      const { name, views, company_id, id, segment_name } = obj;
      let reportName = name;
      let reportId = company_id ?? id;
      let reportView = views;
      let segmentName = segment_name ?? "";

      if (!obj["name"]) {
        reportName = Object.keys(obj)[0];
        reportView = Object.values(obj)[0];
      }

      return {
        id: reportId,
        report_name: (
          <div
            onClick={() => goToReportDetail(obj)}
            style={{ cursor: "pointer" }}
            className="report-usage-report-name"
          >
            {reportName?.length < 45
              ? reportName
              : reportName?.slice(0, 45) + "..."}{" "}
            {isSegmentVisible && segmentName ? ` - ${segmentName}` : ""}
          </div>
        ),
        percentage: <p className="per-value-text">{reportView}</p>,
      };
    });
    setRowData(convertToTableData);
  };

  const selectedTab = (slug) => {
    const updatedOption = reportOptions?.map((item) => {
      if (item.slug === slug) {
        item.isSelected = true;
        setSelectedReport(item.name);
      } else {
        item.isSelected = false;
      }
      return item;
    });
    if (slug === "company") {
      const updateVendorOption = vendorOption.map((item, index) => ({
        ...item,
        isSelected: index === 0,
      }));
      setVendorOption(updateVendorOption);
      setSelectedVendor("Company Research");
    }
    setReportOptions(updatedOption);
  };

  const showPercentageOnHover = (slug) => {
    const updatedOption = reportOptions?.map((item) => ({
      ...item,
      isHover: item.slug === slug,
    }));
    setReportOptions(updatedOption);
  };

  const removePercentageOnLeave = () => {
    const updatedOption = reportOptions?.map((item) => {
      item.isHover = false;
      return item;
    });
    setReportOptions(updatedOption);
  };

  const selectVendorReport = (id) => {
    const updatedOption = vendorOption?.map((item) => {
      if (item.id === id) {
        item.isSelected = true;
        setSelectedVendor(item.name);
      } else {
        item.isSelected = false;
      }
      return item;
    });
    setVendorOption(updatedOption);
  };

  useEffect(() => {
    updateDataBasedValue();
    // default - the first one is selected
    const updateVendorOption = vendorOption.map((item, index) => ({
      ...item,
      isSelected: index === 0,
    }));
    setVendorOption(updateVendorOption);
    setSelectedVendor("Company Research");
  }, [data]);

  useEffect(() => {
    const findSelectedReport = reportOptions.find(
      (report) => report.isSelected
    );
    const report = findSelectedReport.name;
    switch (report) {
      case "Company-Specific Research":
        if (selectedVendor === "Company Research") {
          formatRowData(data?.vendor_reports_analytics, true);
        } else if (selectedVendor === "Company Snapshot") {
          formatRowData(data?.vendor_snapshot_analytics, true);
        } else if (selectedVendor === "Company Profile") {
          formatRowData(data?.vendor_profile_analytics, true);
        } else if (selectedVendor === "Benchmark Coverage") {
          formatRowData(data?.benchmark_coverage_analytics, true);
        }
        break;
      case "Benchmark":
        formatRowData(data?.benchmark_analytics);
        break;
      case "Market Forecast":
        formatRowData(data?.market_forecast_analytics);
        break;
      case "Market Landscape":
        formatRowData(data?.market_landscape_analytics);
        break;
      case "Special Report":
        formatRowData(data?.special_report_analytics);
        break;
      case "Customer Study":
        formatRowData(data?.customer_study_analytics);
        break;
      case "Ecosystem":
        formatRowData(data?.ecosystem_analytics);
        break;
    }
  }, [selectedReport, selectedVendor, data]);

  return (
    <div className="d-flex pt-4 pb-4 pl-3">
      <div className="report-usage-table-left">
        {reportOptions.map((option) => (
          <div
            onMouseEnter={() => showPercentageOnHover(option.slug)}
            onMouseLeave={removePercentageOnLeave}
            onClick={() => selectedTab(option.slug)}
            key={option.slug}
            className={
              option.isSelected
                ? "selected-report-header"
                : "unselect-report-header"
            }
          >
            <p>{option.name}</p>
            {option.isSelected && (
              <div className="percentage">{option.percentage}%</div>
            )}

            {!option.isSelected && option.isHover && (
              <div className="percentage-hover">{option.percentage}%</div>
            )}
          </div>
        ))}
      </div>
      <div className="report-usage-table-container">
        {selectedReport === "Company-Specific Research" ? (
          <div>
            <div className="vendor-specific-div">
              {vendorOption?.map((option) => (
                <div
                  onClick={() => selectVendorReport(option.id)}
                  key={option.id}
                  className={
                    option.isSelected
                      ? "vendor-selected"
                      : "vendor-not-selected"
                  }
                >
                  {option.name}
                  <div class="single-chart">
                    <svg viewBox="0 0 36 36" class="circular-chart blue">
                      <path
                        class="circle-bg"
                        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                      />
                      <path
                        class="circle"
                        stroke-dasharray={`${option.percentage}, 100`}
                        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                      />
                      <text x="18" y="20.35" class="percentage">
                        {option.percentage}%
                      </text>
                    </svg>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <h2>{selectedReport}</h2>
        )}
        <div className="mt-3 w-100 custom-table-block custom-table-block-2 benchmark-list-table-block sort-table-block th-nowrap center-th-align">
          <DynamicTableComponent
            columnData={reportTableHeader}
            rowData={rowData}
            showPagination={false}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportUsageTable;
