import { Axios } from "../lib/axios";

export const CalendarServices = {
  getCalendarEvent,
  createCalendarEvent,
  updateCalendarEvent,
  deleteCalendarEvent,
  getSearchData,
  getPublishedCalendarEvent,
  getUpcomingEvent,
};

function getCalendarEvent() {
  return Axios.get("/events");
}

function getPublishedCalendarEvent() {
  return Axios.get("/calenderEvents");
}

function createCalendarEvent(body) {
  return Axios.post("/addEvent", body);
}

function updateCalendarEvent(id, body) {
  return Axios.put(`/event/${id}`, body);
}

function deleteCalendarEvent(id) {
  return Axios.delete(`/event/${id}`);
}

function getSearchData(params) {
  return Axios.get(`/events?${params}`);
}

function getUpcomingEvent() {
  return Axios.get("/upcomingCalendarEvents");
}
