import { Axios, Axios_without_loader } from "../lib/axios";
export const OpenSearchServices = {
  fetchSearchData,
  checkResourceAccess,
  fetchSearchSuggestions,
  fetchSearchHistory,
};
function fetchSearchData(body) {
  return Axios.post("fetch-search-data", body);
}
function checkResourceAccess(body) {
  return Axios.post("checkResourceAccess", body);
}

function fetchSearchSuggestions(body) {
  return Axios_without_loader.post("fetch-search-suggestions", body);
}

function fetchSearchHistory(body) {
  return Axios_without_loader.post("fetchSearchHistory", body);
}
