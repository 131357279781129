import momentWithZone from "moment-timezone";
import moment from "moment";
function DetailsDateTimeFormatter(date, showTimeZone = true) {
  let userTimezone = moment.tz.guess();
  let userTimezoneShortCode = moment().tz(userTimezone).zoneAbbr();
  return `${momentWithZone(date)
    .tz(userTimezone)
    .format("MMMM DD, YYYY HH:mm")}${
    showTimeZone ? ` ${userTimezoneShortCode}` : ""
  }`;
}

export default DetailsDateTimeFormatter;
