import { combineReducers } from "redux";
import * as DETAILS_REF from "./reducerReference/projectDetailsReference";

const projectCategories = DETAILS_REF.projectCategories;
const projectDetails = DETAILS_REF.projectDetails;
const categories = DETAILS_REF.categories;
const projectContent = DETAILS_REF.projectContent;

const projectDetailsReducer = combineReducers({
  projectCategories,
  projectDetails,
  categories,
  projectContent,
});

export default projectDetailsReducer;
