import React, { useRef } from "react";
import ReactSelect from "react-select";
import { components } from "react-select";
import constants from "../Utils/constants";

/**
 * Custom CSS for handling multiValue selection
 * background color change
 * onHover,onActive option selection change
 * After selection options selected options styles
 */
const customStyles = {
  menuList: (styles) => ({
    ...styles,
    background: "white",
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    background: isFocused
      ? constants.REACT_SELECT_MODULE.SELECTED_OPTIONS_COLOR
      : isSelected
      ? constants.REACT_SELECT_MODULE.SELECTED_OPTIONS_COLOR
      : "white",
    color: isFocused
      ? constants.REACT_SELECT_MODULE.SELECTED_OPTIONS_LABEL_COLOR
      : isSelected
      ? constants.REACT_SELECT_MODULE.SELECTED_OPTIONS_LABEL_COLOR
      : "black",

    ":active": {
      backgroundColor: constants.REACT_SELECT_MODULE.SELECTED_OPTIONS_COLOR,
    },
  }),

  container: (styles) => ({
    ...styles,
    minWidth: "175px",
    maxWidth: "175px",
  }),

  valueContainer: (styles) => ({
    ...styles,
    flexWrap: "nowrap",
    width: "120px",
    overflowX: "auto",
    "::-webkit-scrollbar": {
      display: "none",
    },
  }),

  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: constants.REACT_SELECT_MODULE.SELECTED_OPTIONS_COLOR,
      minWidth: "unset",
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: constants.REACT_SELECT_MODULE.SELECTED_OPTIONS_LABEL_COLOR,
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: constants.REACT_SELECT_MODULE.SELECTED_OPTIONS_REMOVER_COLOR,
    ":hover": {
      backgroundColor:
        constants.REACT_SELECT_MODULE
          .SELECTED_OPTIONS_REMOVER_HOVER_BACKGROUND_COLOR,
      color: constants.REACT_SELECT_MODULE.SELECTED_OPTIONS_REMOVER_HOVER_COLOR,
      borderColor:
        constants.REACT_SELECT_MODULE
          .SELECTED_OPTIONS_REMOVER_HOVER_BORDER_COLOR,
    },
  }),
};

const MultiSelect = (props) => {
  // isOptionSelected sees previous props.value after onChange
  const valueRef = useRef(props.value);
  valueRef.current = props.value;

  const selectAllOption = {
    value: "<SELECT_ALL>",
    label: "All",
  };

  const isSelectAllSelected = () =>
    valueRef.current.length === props.options.length;

  const isOptionSelected = (option) =>
    valueRef.current.some(({ value }) => value === option.value) ||
    isSelectAllSelected();

  const getOptions = () => [selectAllOption, ...props.options];

  const getValue = () =>
    isSelectAllSelected() ? [selectAllOption] : props.value;

  const onChange = (newValue, actionMeta) => {
    const { action, option, removedValue } = actionMeta;

    if (action === "select-option" && option.value === selectAllOption.value) {
      props.onChange(props.options, actionMeta);
    } else if (
      (action === "deselect-option" &&
        option.value === selectAllOption.value) ||
      (action === "remove-value" &&
        removedValue.value === selectAllOption.value)
    ) {
      props.onChange([], actionMeta);
    } else if (
      actionMeta.action === "deselect-option" &&
      isSelectAllSelected()
    ) {
      props.onChange(
        props.options.filter(({ value }) => value !== option.value),
        actionMeta
      );
    } else {
      props.onChange(newValue || [], actionMeta);
    }
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  return (
    <ReactSelect
      isOptionSelected={isOptionSelected}
      options={getOptions()}
      value={getValue()}
      styles={customStyles}
      onChange={onChange}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      isMulti
      components={{ Option }}
      defaultValue={{
        value: "<SELECT_ALL>",
        label: "All",
      }}
    />
  );
};

export default MultiSelect;
