import { project_details as DETAILS } from "../Reducers/actionConstants";

const projectCategories = (payload) => {
  return {
    type: DETAILS.PROJECT_CATEGORIES,
    payload,
  };
};

const projectDetails = (payload) => {
  return {
    type: DETAILS.PROJECT_DETAILS,
    payload,
  };
};

const categories = (payload) => {
  return {
    type: DETAILS.CATEGORIES,
    payload,
  };
};

const projectContent = (payload) => {
  return {
    type: DETAILS.PROJECT_CONTENT,
    payload,
  };
};

export { projectCategories, projectDetails, categories, projectContent };
