const gotoSection = (id, num = 140) => {
  setTimeout(() => {
    const targetElement = document.querySelector(id);
    if (targetElement) {
      const top = targetElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: top - num,
        behavior: "smooth",
      });
    }
  }, 100); // Adjust delay as needed
};

export default gotoSection;
