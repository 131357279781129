import { notification as NOTIFICATION } from "../actionConstants";

const fetchNotification = (state = [], action) => {
  switch (action.type) {
    case NOTIFICATION.NOTIFICATION_LIST:
      return action.payload;
    default:
      return state;
  }
};

const fetchNotioficationMetadata = (state = null, action) => {
  switch (action.type) {
    case NOTIFICATION.NOTIFICATION_ITEM:
      return action.payload;
    default:
      return state;
  }
};

export { fetchNotification, fetchNotioficationMetadata };
