import axios from "axios";
import constants from "../Utils/constants";
import { formatRoute } from "react-router-named-routes";

let baseURL, timeout;
baseURL = process.env.REACT_APP_TBRI_API_URL;
timeout = process.env.REACT_APP_AXIOS_TIMEOUT
  ? parseInt(process.env.REACT_APP_AXIOS_TIMEOUT)
  : 300000;

let Axios;
let Axios_without_loader;
let is_refreshing = false;
let refreshSubscribers = [];

const init = () => {
  Axios = axios.create({
    baseURL: baseURL,
    timeout,
  });
  Axios.interceptors.request.use(handleSuccessRequest, handleErrorRequest);
  Axios.interceptors.response.use(handleSuccess, handleError);

  Axios_without_loader = axios.create({
    baseURL: baseURL,
    timeout: timeout,
  });

  Axios_without_loader.interceptors.request.use(
    handleSuccessRequestwithoutloader,
    handleErrorRequestwithoutloader
  );
  Axios_without_loader.interceptors.response.use(
    handleSuccesswithoutloader,
    handleErrorwithoutloader
  );

  Axios_without_loader.defaults.timeout = timeout;

  // set apiCount to zero for the first time
  setItem(0);
};

const handleSuccessRequestwithoutloader = (request) => {
  if (localStorage.getItem("token"))
    // try to check if token is expired...
    request.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "token"
    )}`;
  return request;
};

const handleErrorRequestwithoutloader = (error) => {
  return Promise.reject(error);
};

const handleSuccesswithoutloader = (response) => {
  return response;
};

const handleErrorwithoutloader = (error) => {
  if (error.message === "Network Error") {
    // The user doesn't have internet
    return Promise.reject(error.response);
  }
  try {
    switch (error.response.status) {
      case 400:
        let error_msg =
          (error &&
            error.response &&
            error.response.data &&
            error.response.data.error) ||
          "";
        if (error_msg === constants.TOKEN.INVALID_MESSAGE) {
          // document.body.classList.remove('loading-indicator')
          // removeLoadingIndicator();
          localStorage.removeItem("token");
          localStorage.removeItem("refresh_token");
          window.location.href = formatRoute(
            constants.APPLICATION_ROUTE.LOGIN.ROUTE,
            {}
          );
        }
        break;
      case 401:
        //Un authorized
        if (localStorage.getItem("refresh_token")) {
          return refreshAccessToken(error);
        }
        break;
      case 404:
        // Show 404 page
        break;
      case 500:
        // Serveur Error redirect to 500
        break;
      default:
        Promise.reject(error.response);
        break;
    }
  } catch (e) {}
  return Promise.reject(error.response);
};
const handleSuccessRequest = (request) => {
  let url =
    request.url?.split("/")[0] === "board_download"
      ? "board_download"
      : request.url;
  if (!removeLoader.includes(url)) {
    addLoadingIndicator();
  }
  if (localStorage.getItem("token"))
    // try to check if the token is expired
    request.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "token"
    )}`;
  return request;
};

const handleErrorRequest = (error) => {
  removeLoadingIndicator();
  return Promise.reject(error);
};

const handleSuccess = (response) => {
  removeLoadingIndicator();
  return response;
};

const handleError = (error) => {
  removeLoadingIndicator();
  if (error.message === "Network Error") {
    // The user doesn't have internet
    return Promise.reject(error.response);
  }
  try {
    switch (error.response.status) {
      case 400:
        let error_msg =
          (error &&
            error.response &&
            error.response.data &&
            error.response.data.error) ||
          "";
        if (error_msg === constants.TOKEN.INVALID_MESSAGE) {
          // document.body.classList.remove('loading-indicator')
          removeLoadingIndicator();
          localStorage.removeItem("token");
          localStorage.removeItem("refresh_token");
          window.location.href = formatRoute(
            constants.APPLICATION_ROUTE.LOGIN.ROUTE,
            {}
          );
        }
        break;
      case 401:
        //Un authorized
        if (localStorage.getItem("refresh_token")) {
          return refreshAccessToken(error);
        }
        break;
      case 403:
        window.location.href = formatRoute(
          constants.APPLICATION_ROUTE.ACCESS_DENIED.ROUTE,
          {}
        );
        break;
      case 404:
        window.location.href = formatRoute(
          constants.APPLICATION_ROUTE.PAGE_NOT_FOUND.ROUTE,
          {}
        );
        break;
      case 500:
        // Serveur Error redirect to 500
        break;
      default:
        Promise.reject(error.response);
        break;
    }
  } catch (e) {}
  return Promise.reject(error.response);
};

export const addLoadingIndicator = () => {
  let apiCount = getItem();
  apiCount = apiCount ? apiCount + 1 : 1;
  setItem(apiCount);
  document.body.classList.add("loading-indicator");
  // const div = document.getElementById("loading-indicator")
  // div.dataset.testid = "loading-indicator"
};

export const removeLoadingIndicator = () => {
  let apiCount = getItem();
  apiCount = apiCount ? apiCount - 1 : 0;
  setItem(apiCount);
  if (!apiCount) {
    document.body.classList.remove("loading-indicator");
    // var myobj = document.getElementById("loading");
    // myobj.remove();
  }
};

const getItem = () => {
  return parseInt(sessionStorage.getItem("apiCount"));
};

const setItem = (apiCount) => {
  return sessionStorage.setItem("apiCount", apiCount);
};

init();

const onRrefreshed = (token) => {
  refreshSubscribers.forEach((cb) => cb(token));
  refreshSubscribers = [];
};

const subscribeTokenRefresh = (cb) => {
  refreshSubscribers.push(cb);
};

const removeLoader = [
  "setClientPreference",
  "/client-platform/user",
  "board_download",
  "client-platform/notification",
];

/**
 * For ref go through https://gist.github.com/mkjiau/650013a99c341c9f23ca00ccb213db1c
 * @param {Request Object} originalRequest
 * @returns Promise
 */
export const refreshAccessToken = (originalRequest) => {
  if (!is_refreshing) {
    addLoadingIndicator();
    is_refreshing = true;
    axios
      .post(
        baseURL + "token",
        { refresh_token: localStorage.getItem("refresh_token") },
        {}
      )
      .then((responseNew) => {
        localStorage.setItem("token", responseNew.data.token.access_token);
        localStorage.setItem(
          "refresh_token",
          responseNew.data.token.refresh_token
        );

        /**
         *  this dispatch event has listener in sessionPopup modal, "DON'T DELETE THE BELOW LINE..."
         */
        window.dispatchEvent(new Event(constants.SESSION_POP_UP.EVENT));

        removeLoadingIndicator();
        is_refreshing = false;
        onRrefreshed(responseNew.data.token.access_token);
      })
      .catch((res) => {
        removeLoadingIndicator();
        localStorage.removeItem("SSO");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        refreshSubscribers = [];
        window.location.href = window.location.href;
      });
  }
  return new Promise((resolve, reject) => {
    subscribeTokenRefresh((token, err) => {
      if (err) {
        reject(err);
      } else {
        originalRequest.response.config.__isRetryRequest = true;
        originalRequest.response.config.headers.Authorization =
          "Bearer " + token;
        addLoadingIndicator();
        resolve(
          axios(originalRequest.response.config)
            .then((res) => res)
            .catch((err) => err)
            .finally(() => removeLoadingIndicator())
        );
      }
    });
  });
};

export { Axios, Axios_without_loader, baseURL, init };
