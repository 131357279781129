import React from "react";
import Select from "react-select-me";
import "react-widgets/dist/css/react-widgets.css";
import "react-select-me/lib/ReactSelectMe.css";

const CustomDropdownList = (props) => {
  let options = props.data.map((datum) => {
    return {
      label: datum.name,
      value: datum[props.valueField],
      created_at: datum.created_at,
      created_by: datum.created_by,
      deleted_at: datum.deleted_at,
      deleted_by: datum.deleted_by,
      description: datum.description,
      is_active: datum.is_active,
      is_selected: datum.is_selected,
      [props.valueField]: datum[props.valueField],
      name: datum.name,
      updated_at: datum.updated_at,
      updated_by: datum.updated_by,
    };
  });

  const renderFunction = (selectedOptions, onRemove) => {
    if (selectedOptions.length > 0)
      return (
        <div className="list-items">
          {selectedOptions.map((option) => (
            <span className="option-label-wrapper">
              <span className="option-label">{option.label}</span>
            </span>
          ))}
        </div>
      );
    else return <div className="placeholder">{props.placeholder}</div>;
  };

  const optionRender = (options) => {
    return (
      <div className="filter-options-div">
        {" "}
        <input
          type="checkbox"
          id={options.label}
          checked={options.is_selected}
          key={options.label}
        />
        <label htmlFor={options.label}>{options.label}</label>{" "}
      </div>
    );
  };

  const iconFunction = (isOpened) => {
    return (
      <span className={isOpened ? "icon icon-up" : "icon icon-down"}></span>
    );
  };
  const classNames = {
    // usual class names
    dd__wrapper: "filter-dropdown",
    // or even with css modules
    dd__list: "filter-options",
  };
  return (
    <Select
      s={classNames}
      multiple="true"
      placeholder={props.placeholder ? props.placeholder : ""}
      options={options.sort(function (x, y) {
        return x.is_selected === y.is_selected ? 0 : x.is_selected ? -1 : 1;
      })}
      optionRenderer={optionRender}
      valueField={props.valueField ? props.valueField : "id"}
      textField={props.textField ? props.textField : "name"}
      selectedBlockRenderer={renderFunction}
      iconRenderer={iconFunction}
      onChange={(value) => props.handleChange(value, props)}
      defaultValue={
        options && options.find((d) => d.is_selected === true)
          ? options
              .filter((d) => d.is_selected === true)
              .map((a) => a[props.valueField])
          : []
      }
      value={
        options && options.find((d) => d.is_selected === true)
          ? options
              .filter((d) => d.is_selected === true)
              .map((a) => {
                return { value: a[props.valueField], label: a[props.name] };
              })
          : []
      }
      disabled={props.isDisabled ? props.isDisabled : false}
    />
  );
};

export default CustomDropdownList;
