import { home as HOME } from "../Reducers/actionConstants";
import { UserServices } from "../../Services/User";

const getUserLogin = (user) => {
  return UserServices.getUserLogin({
    email: user.email,
    password: user.password,
  }).then((data) => {
    if (data.message) {
    } else {
      localStorage.setItem("token", data.data.data.token);
      return data.data.data;
    }
  });
};

const loginUserSuccess = (payload) => {
  return {
    type: "LOGIN_USER_SUCCESS",
    payload,
  };
};

const loginUserFailure = (payload) => {
  return {
    type: "LOGIN_USER_FAILURE",
    payload,
  };
};

const segments = (payload) => {
  return {
    type: HOME.SEGMENTS,
    payload,
  };
};

const activeMenuTab = (payload) => {
  return {
    type: HOME.ACTIVE_MENU_TAB,
    payload,
  };
};

const displayName = (payload) => {
  return {
    type: HOME.DISPLAY_NAME,
    payload,
  };
};

const openSearchBar = (payload) => {
  return {
    type: HOME.OPEN_SEARCHBAR,
    payload,
  };
};

const getSearchText = (payload) => {
  return {
    type: HOME.SEARCH_TEXT,
    payload,
  };
};

export {
  getUserLogin,
  loginUserSuccess,
  loginUserFailure,
  segments,
  activeMenuTab,
  openSearchBar,
  getSearchText,
  displayName,
};
