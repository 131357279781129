import React, { Component } from "react";
import BoardDashboardSidebar from "../Containers/BoardDashboardSidebar";
import BoardDashboardContent from "../Containers/BoardDashboardContent";
import { BoardServices } from "../Services/Board";
import { connect } from "react-redux";
import queryString from "query-string";
import constants from "../Utils/constants";
import { formatRoute } from "react-router-named-routes";
import { activeMenuTab } from "../Store/Actions/User";
import { debounce, delay } from "lodash";
import DailogNew from "../Components/DailogNew";
import {
  boardContent,
  boardContentComments,
  boardContentDetails,
  boardDetails,
  boardGeneralComments,
  boards,
  boardType,
} from "../Store/Actions/Board";
import ComapnyDetailsDateFormatter from "../Utils/ComapnyDetailsDateFormatter";
import ShareBoard from "../Containers/ShareBoard";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Toast from "../Utils/Toast";
import DocumentTitle from "../Utils/DocumentTitle";
import HelpModal from "../Pages/HelpModal";
import CustomBreadcrumb from "../Components/CustomBreadcrumb";
import permissionCheck from "../lib/permissionCheck";
import decodeJWT from "../lib/decodeJWT";
import { SpecialReportServices } from "../Services/SpecialReports";
import { ClientServices } from "../Services/Client";
import gaEvent from "../Utils/GAEvents";
import Slugify from "../Utils/Slugify";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import ExportBoardDashboardFileFormatter from "../Utils/ExportBoardDashboardFileFormatter";
import {
  downloadFileDetails,
  downloadingProgressId,
  downloadingProgressModel,
} from "../Store/Actions/DownloadProgress";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

class BoardDetails extends Component {
  state = {
    dailogModal: false,
    dailogModalContent: "",
    dailogModalHeader: "",
    dailogModalConfig: {},
    dailogModalStyleType: "",
    pinning_sub_category_id: "",
    keyword: "",
    boardInnerSidebarOpen: true,
    boardCategories: [],
    boardId: "",
    userPermission: [],
    isDisable: true,
    boardDownloadId: null,
    downloadConfirmModal: false,
    downloadInProgressModal: false,
  };

  toggleBoardInnerSidebar = () => {
    this.setState({ boardInnerSidebarOpen: !this.state.boardInnerSidebarOpen });
  };

  componentWillUnmount() {
    this.props.boards([]);
    this.props.boardDetails({});
    this.props.boardContent([]);
    gaEvent.trigger();
  }

  fetchData() {
    let params = queryString.parse(this.props.location.search);
    DocumentTitle(constants.APPLICATION_ROUTE.MY_BOARDS.LIST.NAME);
    this.props.activeMenuTab(constants.APPLICATION_ROUTE.MY_BOARDS.LIST.ROUTE);
    this.getClientPermission();
    let boardId = this.props.match.params.id;
    boardId = boardId ? parseInt(boardId) : 0;
    this.setState(
      {
        keyword: params.search_value ? params.search_value : "",
        boardId: boardId,
      },
      () => {
        this.getBoardDetails(boardId);
        this.callRecentlyView(boardId);
        this.getBoardSidebarCategories(boardId);
      }
    );
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.fetchData();
    }
  }

  // getting client permissions
  getClientPermission = () => {
    const clientId = localStorage.getItem("clientId");
    ClientServices.getClientDetails(clientId)
      .then((res) => {
        const userPermission = res.data;
        this.setState({ userPermission: userPermission });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  // check and restrict the board content with user permission
  updateBoardContentWithClientPermission = (contentData) => {
    let boardContent = contentData.length > 0 ? contentData : [];
    const boardArr = [];

    for (const board of boardContent) {
      switch (board.type) {
        case "company":
          if (
            this.state.userPermission.allow_all_companies === 1 &&
            this.state.userPermission.allow_all_segments === 1
          ) {
            if (!boardArr.includes(board)) boardArr.push(board);
          }
          if (
            this.state.userPermission.segments &&
            this.state.userPermission.segments.length > 0
          ) {
            this.state.userPermission.segments.forEach((segment) => {
              if (segment.name === board.segment_name) {
                if (!boardArr.includes(board)) boardArr.push(board);
              }
            });
          }
          if (
            this.state.userPermission.companies &&
            this.state.userPermission.companies.length > 0
          ) {
            this.state.userPermission.companies.forEach((item) => {
              if (item.name === board.company_name) {
                if (!boardArr.includes(board)) boardArr.push(board);
              }
            });
          }
          if (
            this.state.userPermission.company_segment &&
            this.state.userPermission.company_segment.length > 0
          ) {
            this.state.userPermission.company_segment.forEach((item) => {
              let company = item.companies.find(
                (company) => company.name == board.company_name
              );
              if (item.name === board.segment_name && company) {
                if (!boardArr.includes(board)) boardArr.push(board);
              }
            });
          }
          break;
        case "benchmark":
          if (this.state.userPermission.benchmark_mappings) {
            if (this.state.userPermission.benchmark_mappings.allow_all) {
              if (
                this.state.userPermission.benchmark_mappings.allow_all === 1
              ) {
                if (!boardArr.includes(board)) boardArr.push(board);
              }
            }
            if (
              this.state.userPermission.benchmark_mappings.benchmarks.length > 0
            ) {
              this.state.userPermission.benchmark_mappings.benchmarks.forEach(
                (item) => {
                  if (item.segment.name === board.segment_name) {
                    if (!boardArr.includes(board)) boardArr.push(board);
                  }
                }
              );
            }
            if (
              this.state.userPermission.benchmark_mappings.segments.length > 0
            ) {
              this.state.userPermission.benchmark_mappings.segments.forEach(
                (segment) => {
                  if (segment.name === board.segment_name) {
                    if (!boardArr.includes(board)) boardArr.push(board);
                  }
                }
              );
            }
          }

          break;
        case "market_forecast":
          if (this.state.userPermission.market_forecast_mappings) {
            if (this.state.userPermission.market_forecast_mappings.allow_all) {
              if (
                this.state.userPermission.market_forecast_mappings.allow_all ===
                1
              ) {
                if (!boardArr.includes(board)) boardArr.push(board);
              }
            }
            if (
              this.state.userPermission.market_forecast_mappings
                .market_forecasts.length > 0
            ) {
              this.state.userPermission.market_forecast_mappings.market_forecasts.forEach(
                (item) => {
                  if (item.segment.name === board.segment_name) {
                    if (!boardArr.includes(board)) boardArr.push(board);
                  }
                }
              );
            }
            if (
              this.state.userPermission.market_forecast_mappings.segments
                .length > 0
            ) {
              this.state.userPermission.market_forecast_mappings.segments.forEach(
                (segment) => {
                  if (segment.name === board.segment_name) {
                    if (!boardArr.includes(board)) boardArr.push(board);
                  }
                }
              );
            }
          }

          break;
        case "market_landscape":
          if (this.state.userPermission.market_landscape_mappings) {
            if (this.state.userPermission.market_landscape_mappings.allow_all) {
              if (
                this.state.userPermission.market_landscape_mappings
                  .allow_all === 1
              ) {
                if (!boardArr.includes(board)) boardArr.push(board);
              }
            }

            if (
              this.state.userPermission.market_landscape_mappings
                .market_landscapes.length > 0
            ) {
              this.state.userPermission.market_landscape_mappings.market_landscapes.forEach(
                (item) => {
                  if (item.segment.name === board.segment_name) {
                    if (!boardArr.includes(board)) boardArr.push(board);
                  }
                }
              );
            }
            if (
              this.state.userPermission.market_landscape_mappings.segments
                .length > 0
            ) {
              this.state.userPermission.market_landscape_mappings.segments.forEach(
                (segment) => {
                  if (segment.name === board.segment_name) {
                    if (!boardArr.includes(board)) boardArr.push(board);
                  }
                }
              );
            }
          }
          break;
        case "special_report":
          if (this.state.userPermission.special_report_mappings) {
            if (this.state.userPermission.special_report_mappings.allow_all) {
              if (
                this.state.userPermission.special_report_mappings.allow_all ===
                1
              ) {
                if (!boardArr.includes(board)) boardArr.push(board);
              }
            }

            if (
              this.state.userPermission.special_report_mappings.special_reports
                .length > 0
            ) {
              this.state.userPermission.special_report_mappings.special_reports.forEach(
                (item) => {
                  if (item.segment.name === board.segment_name) {
                    if (!boardArr.includes(board)) boardArr.push(board);
                  }
                }
              );
            }
            if (
              this.state.userPermission.special_report_mappings.segments
                .length > 0
            ) {
              this.state.userPermission.special_report_mappings.segments.forEach(
                (segment) => {
                  if (segment.name === board.segment_name) {
                    if (!boardArr.includes(board)) boardArr.push(board);
                  }
                }
              );
            }
          }
          break;
        case "project":
          boardArr.push(board);
          break;
        case "customer_study":
          if (this.state.userPermission.customer_study_mappings) {
            if (this.state.userPermission.customer_study_mappings.allow_all) {
              if (
                this.state.userPermission.customer_study_mappings.allow_all ===
                1
              ) {
                if (!boardArr.includes(board)) boardArr.push(board);
              }
            }
            if (
              this.state.userPermission.customer_study_mappings.customer_study
                .length > 0
            ) {
              this.state.userPermission.customer_study_mappings.customer_study.forEach(
                (item) => {
                  if (item.segment.name === board.segment_name) {
                    if (!boardArr.includes(board)) boardArr.push(board);
                  }
                }
              );
            }
            if (
              this.state.userPermission.customer_study_mappings.segments
                .length > 0
            ) {
              this.state.userPermission.customer_study_mappings.segments.forEach(
                (segment) => {
                  if (segment.name === board.segment_name) {
                    if (!boardArr.includes(board)) boardArr.push(board);
                  }
                }
              );
            }
          }

          break;
        case "ecosystem":
          if (this.state.userPermission.ecosystem_mappings) {
            if (this.state.userPermission.ecosystem_mappings.allow_all) {
              if (
                this.state.userPermission.ecosystem_mappings.allow_all === 1
              ) {
                if (!boardArr.includes(board)) boardArr.push(board);
              }
            }
            if (
              this.state.userPermission.ecosystem_mappings.ecosystems.length > 0
            ) {
              this.state.userPermission.ecosystem_mappings.ecosystems.forEach(
                (item) => {
                  if (item.segment.name === board.segment_name) {
                    if (!boardArr.includes(board)) boardArr.push(board);
                  }
                }
              );
            }
            if (
              this.state.userPermission.ecosystem_mappings.segments.length > 0
            ) {
              this.state.userPermission.ecosystem_mappings.segments.forEach(
                (segment) => {
                  if (segment.name === board.segment_name) {
                    if (!boardArr.includes(board)) boardArr.push(board);
                  }
                }
              );
            }
          }

          break;
        default:
          break;
      }
    }
    return boardContent?.map((board) => {
      const boardIsExist = boardArr.includes(board);
      board["isBlur"] = !boardIsExist;
      return board;
    });
  };

  // merge board content inside categories
  mergeBoardContentInsideSubcategories = (
    isAdmin,
    contentData,
    updatedCategoryData
  ) => {
    const categories = updatedCategoryData;
    const boardContent =
      Number(isAdmin) === 0
        ? this.updateBoardContentWithClientPermission(contentData)
        : contentData;
    const boardPinned = this.props.board.boardContentDetails.board_pinneds;

    for (let cat of categories) {
      for (let subcat of cat.subcategories) {
        const contentIndex = subcat.content_index
          ? JSON.parse(subcat.content_index)
          : [];
        const contentArray = [];

        for (let content of contentIndex) {
          const pinned_board = boardPinned.find(
            (pin) =>
              parseInt(pin.id) === parseInt(content.board_pinned_id) &&
              parseInt(content.content_id) === parseInt(pin.mapping_id)
          );

          const matchingContent = boardContent.filter(
            (content) =>
              content.type === pinned_board.type &&
              parseInt(content.subcategory_id) ===
                parseInt(pinned_board.subcategory_id) &&
              (parseInt(content.company_segment_id) ===
                parseInt(pinned_board.mapping_id) ||
                parseInt(content.customer_study_id) ===
                  parseInt(pinned_board.mapping_id) ||
                parseInt(content.ecosystem_id) ===
                  parseInt(pinned_board.mapping_id) ||
                parseInt(content.market_forecast_id) ===
                  parseInt(pinned_board.mapping_id) ||
                parseInt(content.market_landscape_id) ===
                  parseInt(pinned_board.mapping_id) ||
                parseInt(content.project_id) ===
                  parseInt(pinned_board.mapping_id) ||
                parseInt(content.special_report_id) ===
                  parseInt(pinned_board.mapping_id) ||
                parseInt(content.benchmark_id) ===
                  parseInt(pinned_board.mapping_id))
          );

          const typeMapping = {
            market_landscape: "market-landscape",
            market_forecast: "market-forecast",
            special_report: "special-reports",
            customer_study: "customer-studies",
            ecosystem: "ecosystems",
          };

          if (matchingContent) {
            let index = 0;
            for (let item of matchingContent) {
              let url = "";
              if (item.type === "company") {
                // URL for company type
                url = `/company/${item.company_id}/dashboard`;
              } else {
                const mappedType = typeMapping[item.type] || item.type;
                const idField = `${item.type}_id`;
                const idValue = item[idField];
                // URL for all other report types
                url = `/${mappedType}/${idValue}/dashboard`;
              }
              item["board_pinned_id"] = pinned_board.id;
              item["isDuplicate"] = index === 0;
              item["url"] = url;
              item["openCommentsFlag"] = false;
              contentArray.push(item);
              index++;
            }
          }
        }

        subcat["content"] = contentArray;
      }
    }
    this.setState({ boardCategories: categories });
  };

  // fetch the board sidebar categories
  getBoardSidebarCategories = (boardId) => {
    BoardServices.getBoardCategories(boardId)
      .then((res) => {
        const data = res.data.categories;
        const updatedCategoryData = data?.map((category) => ({
          ...category,
          is_selected: true,
          subcategories: category.subcategories?.map((subcategory) => ({
            ...subcategory,
            is_selected: true,
            content: [],
          })),
        }));
        this.getBoardContent(boardId, updatedCategoryData);
      })
      .catch((error) => {
        this.setState({ boardCategories: [] });
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  // add and update the board sidebar categories
  updateBoardSidebarCategory = (boardId, body) => {
    BoardServices.addAndUpdateBoardCategory(boardId, body)
      .then((res) => {
        this.getBoardDetails(boardId);
        this.getBoardSidebarCategories(boardId);
        Toast(
          res && res.data && res.data.message
            ? res.data.message
            : "Added Category Successfully!",
          "success"
        );
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  // delete board subcategory
  removeBoardCategories = (boardId, catId) => {
    BoardServices.deleteBoardCategory(catId)
      .then((res) => {
        Toast(
          res && res.data && res.data.message
            ? res.data.message
            : "Details deleted successfully",
          "success"
        );
        this.getBoardDetails(boardId);
        this.getBoardSidebarCategories(boardId);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  // delete board piece of conntent from subcategory
  deleteContentFromSubcategory = (
    board_id,
    board_subcat_id,
    content_id,
    index,
    board_pinned_id
  ) => {
    let body = {
      content_id,
      index,
      board_pinned_id,
    };
    BoardServices.deleteContentFromSubcategory(board_subcat_id, body)
      .then((res) => {
        Toast(
          res && res.data && res.data.message
            ? res.data.message
            : "Details deleted successfully",
          "success"
        );
        this.getBoardDetails(board_id);
        this.getBoardSidebarCategories(board_id);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  // re-arrange the board sidebar categories order (Drag and Drop)
  dragAndDropBoardCategory = debounce((boardId, body) => {
    BoardServices.rearrangeBoardCategoryOrder(boardId, body)
      .then((res) => {
        // this.getBoardSidebarCategories(boardId);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  }, 1000);

  handleOnDragEnd = ({ destination, source, draggableId, type }) => {
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    if (type === "category") {
      const newOrder = [...this.state.boardCategories];
      const [reorderedItem] = newOrder.splice(source.index, 1);
      newOrder.splice(destination.index, 0, reorderedItem);
      const updateNewOrder = newOrder?.map((item, index) => ({
        ...item,
        rank: index + 1,
      }));
      this.setState({ boardCategories: updateNewOrder });
      this.dragAndDropBoardCategory(this.state.boardId, updateNewOrder);
      return;
    }

    if (type === "sub-category") {
      if (source.droppableId === destination.droppableId) {
        const selectedCategory = this.state.boardCategories.find(
          (cat) => cat.id == source.droppableId
        );
        const newOrder = [...selectedCategory.subcategories];
        const [reorderedItem] = newOrder.splice(source.index, 1);
        newOrder.splice(destination.index, 0, reorderedItem);
        selectedCategory.subcategories = newOrder?.map((item, index) => ({
          ...item,
          rank: index + 1,
        }));
        const updateCategories = this.state.boardCategories?.map((cat) => {
          if (cat.id === selectedCategory.id) {
            return selectedCategory;
          } else {
            return cat;
          }
        });
        this.setState({ boardCategories: updateCategories });
        this.dragAndDropBoardCategory(this.state.boardId, updateCategories);
        return;
      } else {
        const sourceCategory = this.state.boardCategories.find(
          (cat) => cat.id == source.droppableId
        );
        const destinationCategory = this.state.boardCategories.find(
          (cat) => cat.id == destination.droppableId
        );
        const sourceCategoryNewOrder = [...sourceCategory.subcategories];
        const destCategoryNewOrder = [...destinationCategory.subcategories];
        const [reorderedItem] = sourceCategoryNewOrder.splice(source.index, 1);
        destCategoryNewOrder.splice(destination.index, 0, reorderedItem);
        sourceCategory.subcategories = sourceCategoryNewOrder?.map(
          (item, index) => ({ ...item, rank: index + 1 })
        );
        destinationCategory.subcategories = destCategoryNewOrder?.map(
          (item, index) => ({ ...item, rank: index + 1 })
        );
        const updateBoardCategory = this.state.boardCategories?.map((item) => {
          if (item.id == source.droppableId) {
            return sourceCategory;
          } else if (item.id == destination.droppableId) {
            return destinationCategory;
          } else {
            return item;
          }
        });
        this.setState({ boardCategories: updateBoardCategory });
        this.dragAndDropBoardCategory(this.state.boardId, updateBoardCategory);
        return;
      }
    }
  };

  // update the state from child component
  updateBoardCategoriesState = (newCategories) => {
    const selectedCategories = newCategories.some((newCategory) =>
      newCategory.subcategories.some((res) => res.is_selected)
    )
      ? 1
      : 0;
    this.setState({
      boardCategories: newCategories,
      isDisable: selectedCategories,
    });
  };

  callRecentlyView = async (boardId) => {
    let body = {
      table_name: constants.APPLICATION_ROUTE.MY_BOARDS.LIST.NAME,
      table_id: boardId,
    };
    await SpecialReportServices.addRecentlyViewDetails(body).catch((error) => {
      // this.props.categories([]);
      // this.props.specialreportContent([]);
      Toast(
        error && error.data && error.data.error
          ? error.data.error
          : constants.ERROR.SOMETHING_WENT_WRONG,
        "error"
      );
    });
  };

  initGA = (isPublished = 1) => {
    gaEvent.init({
      moduleName: constants.APPLICATION_ROUTE.MY_BOARDS.LIST.NAME,
      id: this.props.match.params.id,
      isPublished: isPublished,
      page_url:
        this.props.history.location.pathname +
        this.props.history.location.search,
    });
  };

  getBoardDetails = (boardId, doNotSendGAEvent) => {
    BoardServices.getBoardDetails(boardId)
      .then((res) => {
        let response = res && res.data;
        response.is_public = 1;
        this.getBoardGeneralComments(boardId);
        this.props.boardDetails(response);
        if (!doNotSendGAEvent) {
          this.initGA(response.is_published);
        }
      })
      .catch((error) => {
        if (error.status === 403) {
          window.location.href = formatRoute(
            constants.APPLICATION_ROUTE.ACCESS_DENIED.ROUTE,
            {}
          );
        }
        this.props.boardDetails({});
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  getBoardGeneralComments = (boardId) => {
    BoardServices.getBoardGeneralComments(boardId)
      .then((res) => {
        let comments =
          res && res.data && res.data.data && res.data.data.comments;
        let n = comments?.map((c) => ({
          ...c,
          txtComment: c.comment,
          editToggle: false,
        }));
        this.props.boardGeneralComments(n);
      })
      .catch((error) => {
        this.props.boardGeneralComments([]);
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  handleOpenEditCommentGeneral = (e, boardId, commentId, type) => {
    let boardGeneralComments = [...this.props.board.boardGeneralComments];
    let i = boardGeneralComments.findIndex((c) => c.id === commentId);
    boardGeneralComments[i].editToggle = true;
    this.props.boardGeneralComments(boardGeneralComments);
  };

  handleCloseEditCommentGeneral = (e, boardId, commentId, type) => {
    let boardGeneralComments = [...this.props.board.boardGeneralComments];
    let i = boardGeneralComments.findIndex((c) => c.id === commentId);
    boardGeneralComments[i].editToggle = false;
    boardGeneralComments[i].txtComment = boardGeneralComments[i].comment;
    this.props.boardGeneralComments(boardGeneralComments);
  };

  handleCommentChangeGeneral = (e, boardId, commentId, type) => {
    let boardGeneralComments = [...this.props.board.boardGeneralComments];
    let i = boardGeneralComments.findIndex((c) => c.id === commentId);
    boardGeneralComments[i].txtComment = e.target.value;
    this.props.boardGeneralComments(boardGeneralComments);
  };

  handleSaveEditCommentGeneral = (e, boardId, commentId, type) => {
    let boardGeneralComments = [...this.props.board.boardGeneralComments];
    let i = boardGeneralComments.findIndex((c) => c.id === commentId);
    let body = {
      comment: boardGeneralComments[i].txtComment,
      is_public: boardGeneralComments[i].is_public,
    };
    BoardServices.updatedBoardComment(commentId, body)
      .then((response) => {
        this.getBoardGeneralComments(boardId);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  handleOpenEditCommentContent = (e, boardId, commentId, type) => {
    let boardContentComments = [...this.props.board.boardContentComments];
    let i = boardContentComments.findIndex((c) => c.id === commentId);
    boardContentComments[i].editToggle = true;
    this.props.boardContentComments(boardContentComments);
  };

  handleCloseEditCommentContent = (e, boardId, commentId, type) => {
    let boardContentComments = [...this.props.board.boardContentComments];
    let i = boardContentComments.findIndex((c) => c.id === commentId);
    boardContentComments[i].editToggle = false;
    boardContentComments[i].txtComment = boardContentComments[i].comment;
    this.props.boardContentComments(boardContentComments);
  };

  handleCommentChangeContent = (e, boardId, commentId, type) => {
    let boardContentComments = [...this.props.board.boardContentComments];
    let i = boardContentComments.findIndex((c) => c.id === commentId);
    boardContentComments[i].txtComment = e.target.value;
    this.props.boardContentComments(boardContentComments);
  };

  handleSaveEditCommentContent = (e, contentId, commentId, type) => {
    let boardId = this.props.match.params.id;
    let boardContentComments = [...this.props.board.boardContentComments];
    let i = boardContentComments.findIndex((c) => c.id === commentId);
    let body = {
      comment: boardContentComments[i].txtComment,
      is_public: boardContentComments[i].is_public,
    };
    BoardServices.updatedBoardComment(commentId, body)
      .then((response) => {
        this.getBoardContentComments(boardId, contentId);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  // ------------- content top comments --------------- //
  handleOpenEditTopCommentContent = (e, contentId, commentId, type) => {
    let contents = [...this.props.board.boardContent];
    let i = contents.findIndex((c) => c.content_id === contentId);
    let im = contents[i].comments.findIndex((m) => m.id === commentId);
    contents[i].comments[im].editToggle = true;
    this.props.boardContent(contents);
  };

  handleCloseEditTopCommentContent = (e, contentId, commentId, type) => {
    let contents = [...this.props.board.boardContent];
    let i = contents.findIndex((c) => c.content_id === contentId);
    let im = contents[i].comments.findIndex((m) => m.id === commentId);
    contents[i].comments[im].editToggle = false;
    contents[i].comments[im].txtComment = contents[i].comments[im].comment;
    this.props.boardContent(contents);
  };

  handleTopCommentChangeContent = (e, contentId, commentId, type) => {
    let contents = [...this.props.board.boardContent];
    let i = contents.findIndex((c) => c.content_id === contentId);
    let im = contents[i].comments.findIndex((m) => m.id === commentId);
    contents[i].comments[im].txtComment = e.target.value;
    this.props.boardContent(contents);
  };

  handleSaveEditTopCommentContent = (e, contentId, commentId, type) => {
    let boardId = this.props.match.params.id;
    let contents = [...this.props.board.boardContent];
    let i = contents.findIndex((c) => c.content_id === contentId);
    let im = contents[i].comments.findIndex((m) => m.id === commentId);
    let body = {
      comment: contents[i].comments[im].txtComment,
      is_public: contents[i].comments[im].is_public,
    };
    BoardServices.updatedBoardComment(commentId, body)
      .then((response) => {
        this.getBoardSidebarCategories(boardId);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  getBoardContentComments = (boardId, contentId) => {
    BoardServices.getBoardContentCommentsById(boardId, contentId)
      .then((res) => {
        let comments =
          res && res.data && res.data.data && res.data.data.comments;
        let n = comments?.map((c) => ({
          ...c,
          txtComment: c.comment,
          editToggle: false,
        }));
        this.props.boardContentComments(n);
      })
      .catch((error) => {
        this.props.boardContentComments([]);
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  handleDeleteComment = (e, contentId, commentId, type) => {
    let boardId = this.props.match.params.id;
    BoardServices.removeBoardComment(commentId)
      .then((response) => {
        if (type === "general") {
          this.getBoardGeneralComments(boardId);
        } else {
          this.getBoardContentComments(boardId, contentId);
        }
        this.getBoardSidebarCategories(boardId);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  handleDeleteCommentConfirmation = (e, contentId, commentId, type) => {
    this.setState({ isDeleteConfirmOpen: true });
    confirmAlert({
      closeOnEscape: false,
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure, You want to remove?</h1>
            <button
              onClick={() => {
                this.setState({ isDeleteConfirmOpen: false });
                onClose();
              }}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                this.handleDeleteComment(e, contentId, commentId, type);
                onClose();
              }}
            >
              Confirm
            </button>
          </div>
        );
      },
    });
  };

  getBoardContent = (boardId, updatedCategoryData) => {
    let tokenDetails = decodeJWT(localStorage.getItem("token"));
    let isAdmin = tokenDetails?.user?.is_admin;

    let queryParams = "";
    let url = `board/${boardId}/content`;
    if (this.state.keyword !== "") {
      queryParams += "?search_column=all&search_value=" + this.state.keyword;
      url = url + queryParams;
    }

    BoardServices.getBoardContent(url)
      .then((res) => {
        let response =
          res && res.data && res.data.data && res.data.data.content;
        let responseDetails = res && res.data && res.data.data;
        response.forEach((c) => {
          c.is_public = 1;
          c.comment = "";
          c.published_date_group = ComapnyDetailsDateFormatter(
            c.published_date,
            false
          );
          if (
            c.published_date_group ===
            ComapnyDetailsDateFormatter(new Date(), false)
          ) {
            c.published_date_group = "today";
          }
          c.show_actions = false;
          c.comments = c.comments?.map((c) => ({
            ...c,
            txtComment: c.comment,
            editToggle: false,
          }));
        });
        this.props.boardContent(response);
        this.props.boardContentDetails(responseDetails);
        this.changeRoute(boardId);
        this.mergeBoardContentInsideSubcategories(
          isAdmin,
          response,
          updatedCategoryData
        );
      })
      .catch((error) => {
        this.props.boardContent([]);
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  changeRoute = (boardId) => {
    if (boardId > 0) {
      let queryParams = "";
      if (this.state.keyword !== "") {
        queryParams +=
          "?search_column=keyword&search_value=" + this.state.keyword;
      }
      this.props.history.push({
        pathname: formatRoute(
          constants.APPLICATION_ROUTE.MY_BOARDS.DASHBOARD.ROUTE,
          { id: boardId }
        ),
        search: queryParams,
      });
    }
  };

  dailogModalAccept = (config) => {
    this.setState(
      {
        dailogModal: false,
        dailogModalHeader: "",
        dailogModalContent: "",
        dailogModalConfig: {},
      },
      () => {
        switch (config.type) {
          case "remove_content_from_board":
            this.removeFromBoard(config.id, config.sub_category_id);
            break;
          case "remove_category_from_board":
            const { boardId, catId } = config;
            this.removeBoardCategories(boardId, catId);
            break;
          case "remove_subcat_piece":
            const {
              board_id,
              board_subcat_id,
              content_id,
              index,
              board_pinned_id,
            } = config;
            this.deleteContentFromSubcategory(
              board_id,
              board_subcat_id,
              content_id,
              index,
              board_pinned_id
            );
            break;
          default:
            break;
        }
      }
    );
  };

  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };

  handleSearch = () => {
    let boardId = this.props.match.params.id;
    boardId = boardId ? parseInt(boardId) : 0;
    this.setState({ pageNo: 1 }, () => {
      this.getBoardSidebarCategories(boardId);
    });
  };

  handleInput = (e) => {
    this.setState({ keyword: e.target.value });
  };

  toggleContentActions = (content_id) => {
    let contents = [...this.props.board.boardContent];
    let i = contents.findIndex((c) => c.content_id === content_id);
    contents[i].show_actions = !contents[i].show_actions;
    this.props.boardContent(contents);
  };

  toggleContentCommentsHandler = (content_id) => {
    let contents = [...this.props.board.boardContent];
    let i = contents.findIndex((c) => c.content_id === content_id);
    contents[i].openCommentsFlag = !contents[i].openCommentsFlag;
    this.props.boardContent(contents);
  };

  toggleWriteContentCommentsHandler = (content_id) => {
    let contents = [...this.props.board.boardContent];
    contents = contents.map((c) => {
      if (parseInt(c.content_id) === parseInt(content_id)) {
        c.openCommentsFlag = true;
        c.writeCommentsFlag = true;
        c.show_actions = false;
      }
      return c;
    });
    this.props.boardContent(contents);
  };

  handleContentCommentChangeHandler = (e, content_id) => {
    let contents = [...this.props.board.boardContent];
    let i = contents.findIndex((c) => c.content_id === content_id);
    contents[i].comment = e.target.value;
    this.props.boardContent(contents);
  };

  handleContentCommentTypeChangeHandler = (e, content_id, is_public) => {
    let contents = [...this.props.board.boardContent];
    let i = contents.findIndex((c) => c.content_id === content_id);
    contents[i].is_public = is_public;
    this.props.boardContent(contents);
  };

  handleContentCommentSaveHandler = (e, content_id) => {
    let boardId =
      this.props.board &&
      this.props.board.boardDetails &&
      this.props.board.boardDetails.id;
    let contents = [...this.props.board.boardContent];
    let i = contents.findIndex((c) => c.content_id === content_id);
    let is_public = 1;
    if (contents[i].is_public === 0) {
      is_public = 0;
    }
    let comment = {
      type: "content",
      comment: contents[i].comment,
      content_id: content_id,
      is_public: is_public,
      board_id: boardId,
      mapping_id:
        contents[i].type === "company"
          ? parseInt(contents[i].company_segment_id)
          : parseInt(contents[i].project_id),
      sub_category_id: contents[i].subcategory_id,
    };
    BoardServices.addBoardContentComments(boardId, comment)
      .then((response) => {
        this.getBoardSidebarCategories(boardId);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  toggleGeneralCommentsHandler = (id) => {
    let boardDetails = { ...this.props.board.boardDetails };
    boardDetails.openCommentsFlag = !boardDetails.openCommentsFlag;
    this.props.boardDetails(boardDetails);
  };

  toggleWriteGeneralCommentsHandler = (id) => {
    let boardDetails = { ...this.props.board.boardDetails };
    boardDetails.openCommentsFlag = true;
    boardDetails.writeCommentsFlag = true;
    this.props.boardDetails(boardDetails);
  };

  handleGeneralCommentChangeHandler = (e, id) => {
    let boardDetails = { ...this.props.board.boardDetails };
    boardDetails.comment = e.target.value;
    this.props.boardDetails(boardDetails);
  };

  handleGeneralCommentTypeChangeHandler = (e, content_id, is_public) => {
    let boardDetails = { ...this.props.board.boardDetails };
    boardDetails.is_public = is_public;
    this.props.boardDetails(boardDetails);
  };

  toggleContentCommentTypeDropdown = (e, id, comment_id) => {
    let contents = [...this.props.board.boardContent];
    let i = contents.findIndex((c) => c.content_id === id);
    let ic = contents[i].comments.findIndex((c) => c.id === comment_id);
    contents[i].comments[ic].isOpenCommentTypeDropdown =
      !contents[i].comments[ic].isOpenCommentTypeDropdown;
    this.props.boardContent(contents);
  };

  toggleContentCommentType = (e, id, comment_id, is_public) => {
    let contents = [...this.props.board.boardContent];
    let i = contents.findIndex((c) => c.content_id === id);
    let ic = contents[i].comments.findIndex((c) => c.id === comment_id);
    contents[i].comments[ic].is_public = is_public;
    this.props.boardContent(contents);
    this.handleSaveCommentType(contents[i].comments[ic]);
  };

  toggleContentShowMoreCommentTypeDropdown = (e, id, comment_id) => {
    let boardContentComments = [...this.props.board.boardContentComments];
    let i = boardContentComments.findIndex((c) => c.id === comment_id);
    boardContentComments[i].isOpenCommentTypeDropdown =
      !boardContentComments[i].isOpenCommentTypeDropdown;
    this.props.boardContentComments(boardContentComments);
  };

  toggleContentShowMoreCommentType = (e, id, comment_id, is_public) => {
    let boardContentComments = [...this.props.board.boardContentComments];
    let i = boardContentComments.findIndex((c) => c.id === comment_id);
    boardContentComments[i].is_public = is_public;
    this.props.boardContentComments(boardContentComments);
    this.handleSaveCommentType(boardContentComments[i]);
  };

  toggleGeneralCommentTypeDropdown = (e, id, comment_id) => {
    let boardGeneralComments = [...this.props.board.boardGeneralComments];
    let i = boardGeneralComments.findIndex((c) => c.id === comment_id);
    boardGeneralComments[i].isOpenCommentTypeDropdown =
      !boardGeneralComments[i].isOpenCommentTypeDropdown;
    this.props.boardGeneralComments(boardGeneralComments);
  };

  toggleGeneralCommentType = (e, id, comment_id, is_public) => {
    let boardGeneralComments = [...this.props.board.boardGeneralComments];
    let i = boardGeneralComments.findIndex((c) => c.id === comment_id);
    boardGeneralComments[i].is_public = is_public;
    this.props.boardGeneralComments(boardGeneralComments);
    this.handleSaveCommentType(boardGeneralComments[i]);
  };

  handleGeneralCommentSaveHandler = (e, id) => {
    let boardId =
      this.props.board &&
      this.props.board.boardDetails &&
      this.props.board.boardDetails.id;
    let boardDetails = { ...this.props.board.boardDetails };
    let is_public = 1;
    if (boardDetails.is_public === 0) {
      is_public = 0;
    }
    let comment = {
      type: "general",
      comment: boardDetails.comment,
      is_public: is_public,
      board_id: boardId,
    };
    BoardServices.addBoardGeneralComments(boardId, comment)
      .then((response) => {
        boardDetails.is_public = 1;
        boardDetails.comment = "";
        this.props.boardDetails(boardDetails);
        this.getBoardGeneralComments(boardId);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  handleSaveCommentType = (comment) => {
    let body = {
      comment: comment.comment,
      is_public: comment.is_public,
    };
    BoardServices.updatedBoardComment(comment.id, body)
      .then((response) => {})
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  removeCategoryConfirmation = (type, boardId, catId) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Remove</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {type === "cat"
              ? constants.MY_BOARDS.UN_PIN.REMOVE_CATEGORY_FROM_BOARD
              : constants.MY_BOARDS.UN_PIN.REMOVE_SUBCATEGORY_FROM_BOARD}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "remove_category_from_board", boardId, catId },
      dailogModalStyleType: "danger-modal",
    });
  };

  removeContentFromSubcategory = (
    type,
    board_subcat_id,
    board_id,
    checkingData
  ) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Remove</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {constants.MY_BOARDS.UN_PIN.REMOVE_CONTENT_FROM_BOARD}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: {
        type,
        board_subcat_id,
        board_id,
        content_id: checkingData.content_id,
        index: checkingData.index,
        board_pinned_id: checkingData.board_pinned_id,
      },
      dailogModalStyleType: "danger-modal",
    });
  };

  removeFromBoardConfirmation = (content, sub_category_id) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Remove</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {constants.MY_BOARDS.UN_PIN.REMOVE_FROM_BOARD_CONFIRMATION}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: {
        type: "remove_content_from_board",
        id: content,
        sub_category_id,
      },
      dailogModalStyleType: "danger-modal",
    });
  };

  removeFromBoard = (c, sub_category_id) => {
    let body = null;
    let selectedPin = null;
    let board_pinneds =
      this.props.board &&
      this.props.board.boardContentDetails &&
      this.props.board.boardContentDetails.board_pinneds;
    if (c.type === "company") {
      body = {
        mapping_id: parseInt(c.company_segment_id),
        sub_category_id: c.subcategory_id,
      };
    } else if (c.type === "project") {
      body = {
        mapping_id: parseInt(c.project_id),
        sub_category_id: c.subcategory_id,
      };
    } else if (c.type === "benchmark") {
      body = {
        mapping_id: parseInt(c.benchmark_id),
        sub_category_id: c.subcategory_id,
      };
    } else if (c.type === "market_forecast") {
      body = {
        mapping_id: parseInt(c.market_forecast_id),
        sub_category_id: c.subcategory_id,
      };
    } else if (c.type === "market_landscape") {
      body = {
        mapping_id: parseInt(c.market_landscape_id),
        sub_category_id: c.subcategory_id,
      };
    } else {
      body = null;
    }
    if (body) {
      selectedPin = board_pinneds.find(
        (bp) =>
          bp.mapping_id === body.mapping_id &&
          bp.subcategory_id === body.sub_category_id &&
          bp.type === c.type
      );
    }
    if (selectedPin && selectedPin.id) {
      BoardServices.removeFromBoard(selectedPin.id, sub_category_id)
        .then((res) => {
          this.saveRemoveFromBoardSuccess();
        })
        .catch((error) => {
          Toast(
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
            "error"
          );
        });
    }
  };

  saveRemoveFromBoardSuccess = () => {
    let boardId = this.props.match.params.id;

    Toast(constants.MY_BOARDS.UN_PIN.SUCCESS, "success");
    this.getBoardSidebarCategories(boardId);
  };

  openShareBoardModal = (e, data) => {
    e.stopPropagation();
    this.setState({
      sharedBoardId: data.id,
    });
  };

  closeShareBoardModal = () => {
    this.setState({
      sharedBoardId: null,
    });
  };

  saveShareBoardSuccess = () => {
    let boardId = this.props.match.params.id;

    Toast(constants.MY_BOARDS.SHARE.SUCCESS, "success");
    this.setState(
      {
        sharedBoardId: null,
      },
      () => {
        this.getBoardDetails(boardId, true);
      }
    );
  };

  downloadBoardPDF = async (isComments) => {
    this.setState({ downloadConfirmModal: false });
    this.props.downloadingProgressId(null);
    this.props.downloadFileDetails({});
    let boardDetails = this.props.board && this.props.board.boardDetails;
    let bCategory = this.state.boardCategories;
    let saveCategory = bCategory
      .map((x) => {
        const filteredCategory = x.subcategories.filter(
          (subcategory) =>
            subcategory.is_selected &&
            subcategory.content.every((item) => !item.isBlur)
        );
        if (filteredCategory.length > 0) {
          return {
            ...x,
            subcategories: filteredCategory,
          };
        } else {
          return null;
        }
      })
      .filter((res) => res !== null);
    let request = {
      mappingId: boardDetails.id,
      type: "board",
      source: "content",
      ext: "pdf",
      showComments: isComments,
      boardCategories: saveCategory,
    };
    await BoardServices.downloadContent(request)
      .then((res) => {
        this.setState({ downloadInProgressModal: true });

        let boardData = res.data.data;
        this.props.downloadingProgressModel(true);
        this.props.downloadingProgressId(boardData?.id);
        this.props.downloadFileDetails(boardData);
        setTimeout(() => {
          this.setState({ downloadInProgressModal: false });
        }, 5000);
      })
      .catch((error) => {
        if (error?.status === 403) {
          Toast(
            error.response
              ? error.response
              : "You don't have access to the board or have not selected any boards.",
            "error"
          );
        } else {
          Toast(
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
            "error"
          );
        }
      });
  };

  downloadBoardPDFCommentsConfirmation = () => {
    this.setState({ downloadConfirmModal: true });
  };

  displayCommentsHandler = (e) => {
    let boardDetails = { ...this.props.board.boardDetails };
    boardDetails.display_comments = e.target.checked ? 1 : 0;
    let body = {
      name: boardDetails.name,
      display_comments: boardDetails.display_comments,
    };
    BoardServices.editBoard(boardDetails.id, body)
      .then((response) => {
        this.props.boardDetails(boardDetails);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  breadcrumbs = () => {
    let boardDetails =
      this.props.board && this.props.board.boardDetails
        ? this.props.board.boardDetails
        : [];
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.MY_BOARDS.LIST.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.MY_BOARDS.LIST.ROUTE, {}),
        is_active: false,
      },
      {
        title: boardDetails.id
          ? `${boardDetails.name}`
          : constants.APPLICATION_ROUTE.MY_BOARDS.DASHBOARD.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };

  findAsActions = () => {
    const { boardDetails } = this.props.board;
    const users = [boardDetails?.owner, ...(boardDetails?.shared_users || [])];

    const tokenDetails = decodeJWT(localStorage.getItem("token"));

    return users.some((user) => user?.id === tokenDetails.user.id);
  };

  render() {
    let boardDetails =
      this.props.board && this.props.board.boardDetails
        ? this.props.board.boardDetails
        : [];
    let boardContent =
      this.props.board && this.props.board.boardContent
        ? this.props.board.boardContent
        : [];
    let boardContentDates = boardContent?.map((b) => b.published_date_group);
    boardContentDates = [...new Set(boardContentDates)];
    let aclPermissionKey = constants.ACL.PERMISSION_KEY;
    let aclResource = constants.ACL.MY_BOARDS_RESOURCE;
    if (boardDetails.type === "shared") {
      switch (boardDetails.mode.short_name) {
        case constants.MY_BOARDS_SHARE_MODE.EDIT.SLUG:
          aclPermissionKey = constants.ACL.BOARD_PERMISSION_KEY;
          aclResource = constants.ACL.MY_BOARDS_RESOURCE_EDIT;
          break;
        case constants.MY_BOARDS_SHARE_MODE.VIEW.SLUG:
          aclPermissionKey = constants.ACL.BOARD_PERMISSION_KEY;
          aclResource = constants.ACL.MY_BOARDS_RESOURCE_VIEW;
          break;
        case constants.MY_BOARDS_SHARE_MODE.COMMENT.SLUG:
          aclPermissionKey = constants.ACL.BOARD_PERMISSION_KEY;
          aclResource = constants.ACL.MY_BOARDS_RESOURCE_COMMENT;
          break;
        case constants.MY_BOARDS_SHARE_MODE.VIEW_COMMENT.SLUG:
          aclPermissionKey = constants.ACL.BOARD_PERMISSION_KEY;
          aclResource = constants.ACL.MY_BOARDS_RESOURCE_VIEW_COMMENT;
          break;
        default:
          aclPermissionKey = constants.ACL.BOARD_PERMISSION_KEY;
          aclResource = constants.ACL.MY_BOARDS_RESOURCE_VIEW;
          break;
      }
    }
    let isCommentViewAllowed = permissionCheck(
      aclPermissionKey,
      aclResource.TITLE,
      aclResource.ACTIONS.COMMENT_LIST
    );
    let isDisplayComments = boardDetails.display_comments === 0 ? false : true;
    if (boardDetails.id) DocumentTitle(`${boardDetails.name}`);

    return (
      <div
        className={`${
          this.state.boardInnerSidebarOpen
            ? "opened-innersidebar"
            : "closed-innersidebar"
        } main_content fixed-title-main-content`}
      >
        <div className="m-3 mb-0 bg-white px-3 pb-3 br_8 main_content_height">
          <div className="d-flex align-items-center board-details-page-title-sec fixed-title-section">
            <div className="breadcrumb-mb-0">
              <CustomBreadcrumb breadcrumbs={this.breadcrumbs()} />
            </div>
            <div className="ml-auto mr-3">
              {permissionCheck(
                constants.ACL.PERMISSION_KEY,
                constants.ACL.MY_BOARDS_RESOURCE.TITLE,
                constants.ACL.MY_BOARDS_RESOURCE.ACTIONS.DOWNLOAD
              ) && (
                <button
                  className={`${
                    !this.state.isDisable ? "disabled" : ""
                  } icon-btn-primary icon-btn d-flex align-items-center justify-content-center company_details_download`}
                  data-for={`tooltip-${Slugify(
                    constants.COMPANY.VIEW.DOWNLOAD_TOOLTIP
                  )}`}
                  data-tip={constants.COMPANY.VIEW.DOWNLOAD_TOOLTIP}
                  data-iscapture="true"
                  disabled={
                    !this.state.isDisable ||
                    this.props.downloadingProgressIdState?.downloadingProgressId
                  }
                  onClick={() => {
                    if (this.state.isDisable) {
                      if (boardDetails.type && boardDetails.type === "shared") {
                        if (isCommentViewAllowed && isDisplayComments) {
                          this.downloadBoardPDFCommentsConfirmation();
                        } else {
                          this.downloadBoardPDF(0);
                        }
                      } else {
                        this.downloadBoardPDFCommentsConfirmation();
                      }
                    }
                  }}
                >
                  <span className="material-icons-round icon">download</span>
                  <CustomReactTooltip
                    id={`tooltip-${Slugify(
                      constants.COMPANY.VIEW.DOWNLOAD_TOOLTIP
                    )}`}
                    multiline={true}
                  />
                </button>
              )}
            </div>
            <HelpModal category="My Boards Details" />
          </div>
          <BoardDashboardSidebar
            handleOnDragEnd={this.handleOnDragEnd}
            toggleBoardInnerSidebar={this.toggleBoardInnerSidebar}
            boardCategories={this.state.boardCategories}
            updateBoardSidebarCategory={this.updateBoardSidebarCategory}
            updateBoardCategoriesState={this.updateBoardCategoriesState}
            dragAndDropBoardCategory={this.dragAndDropBoardCategory}
          />
          <BoardDashboardContent
            boardCategories={this.state.boardCategories}
            removeCategoryConfirmation={this.removeCategoryConfirmation}
            removeContentFromSubcategory={this.removeContentFromSubcategory}
            handleOnDragEnd={this.handleOnDragEnd}
            removeFromBoardConfirmation={this.removeFromBoardConfirmation}
            toggleContentActions={this.toggleContentActions}
            handleSearch={this.handleSearch}
            handleInput={this.handleInput}
            keyword={this.state.keyword}
            shareHandler={this.openShareBoardModal}
            addCommentHandler={this.addCommentHandler}
            toggleContentCommentsHandler={this.toggleContentCommentsHandler}
            toggleWriteContentCommentsHandler={
              this.toggleWriteContentCommentsHandler
            }
            handleContentCommentChangeHandler={
              this.handleContentCommentChangeHandler
            }
            handleContentCommentTypeChangeHandler={
              this.handleContentCommentTypeChangeHandler
            }
            handleContentCommentSaveHandler={
              this.handleContentCommentSaveHandler
            }
            toggleContentCommentTypeDropdown={
              this.toggleContentCommentTypeDropdown
            }
            toggleContentCommentType={this.toggleContentCommentType}
            toggleGeneralCommentsHandler={this.toggleGeneralCommentsHandler}
            toggleWriteGeneralCommentsHandler={
              this.toggleWriteGeneralCommentsHandler
            }
            handleGeneralCommentChangeHandler={
              this.handleGeneralCommentChangeHandler
            }
            handleGeneralCommentTypeChangeHandler={
              this.handleGeneralCommentTypeChangeHandler
            }
            handleGeneralCommentSaveHandler={
              this.handleGeneralCommentSaveHandler
            }
            toggleGeneralCommentTypeDropdown={
              this.toggleGeneralCommentTypeDropdown
            }
            toggleGeneralCommentType={this.toggleGeneralCommentType}
            getBoardContentComments={this.getBoardContentComments}
            toggleContentShowMoreCommentTypeDropdown={
              this.toggleContentShowMoreCommentTypeDropdown
            }
            toggleContentShowMoreCommentType={
              this.toggleContentShowMoreCommentType
            }
            handleCommentChangeGeneral={this.handleCommentChangeGeneral}
            handleOpenEditCommentGeneral={this.handleOpenEditCommentGeneral}
            handleDeleteCommentGeneral={this.handleDeleteCommentConfirmation}
            handleCloseEditCommentGeneral={this.handleCloseEditCommentGeneral}
            handleSaveEditCommentGeneral={this.handleSaveEditCommentGeneral}
            handleCommentChangeContent={this.handleCommentChangeContent}
            handleOpenEditCommentContent={this.handleOpenEditCommentContent}
            handleCloseEditCommentContent={this.handleCloseEditCommentContent}
            handleSaveEditCommentContent={this.handleSaveEditCommentContent}
            handleTopCommentChangeContent={this.handleTopCommentChangeContent}
            handleOpenEditTopCommentContent={
              this.handleOpenEditTopCommentContent
            }
            handleDeleteCommentContent={this.handleDeleteCommentConfirmation}
            handleCloseEditTopCommentContent={
              this.handleCloseEditTopCommentContent
            }
            handleSaveEditTopCommentContent={
              this.handleSaveEditTopCommentContent
            }
            downloadBoardPDF={this.downloadBoardPDF}
            downloadBoardPDFCommentsConfirmation={
              this.downloadBoardPDFCommentsConfirmation
            }
            displayCommentsHandler={this.displayCommentsHandler}
            boardDeatils={this.findAsActions()}
            history={this.props.history}
          />
          {this.state.sharedBoardId && (
            <ShareBoard
              boardId={this.state.sharedBoardId}
              boardDetails={this.props.board.boardDetails}
              closeShareBoardModal={this.closeShareBoardModal}
              saveShareBoardSuccess={this.saveShareBoardSuccess}
            />
          )}
          <DailogNew
            isOpen={this.state.dailogModal}
            accept={this.dailogModalAccept}
            decline={this.dailogModalDecline}
            header={this.state.dailogModalHeader}
            content={this.state.dailogModalContent}
            config={this.state.dailogModalConfig}
            modalStyleType={this.state.dailogModalStyleType}
          />
          <Modal
            isOpen={this.state.downloadConfirmModal}
            toggle={() => {}}
            className={"modal-sm modal-confirm custom-modal"}
          >
            <ModalBody>
              <div className="custom-ui">
                <span>Are you sure you want to include comments?</span>
              </div>
            </ModalBody>

            <ModalFooter>
              <Button
                className="btn-outline-primary modal-right-btn modal-btn"
                onClick={() => {
                  this.downloadBoardPDF(0);
                }}
              >
                No
              </Button>{" "}
              <Button
                className="btn-outline-primary modal-right-btn modal-btn"
                onClick={() => {
                  this.downloadBoardPDF(1);
                }}
              >
                Yes
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={this.state.downloadInProgressModal}
            toggle={() => {}}
            className={"modal-sm modal-confirm custom-modal"}
          >
            <ModalBody>
              <div className="custom-ui">
                <span>
                  PDF generation for the board contents has started. We'll
                  notify you once complete.
                </span>
              </div>
            </ModalBody>

            <ModalFooter>
              <Button
                className="btn-outline-primary modal-btn"
                onClick={() => {
                  this.setState({ downloadInProgressModal: false });
                }}
              >
                Ok
              </Button>{" "}
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company_details: state.company_details,
    home: state.home,
    board: state.board,
    downloadingProgressIdState: state.downloadingProcessModel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    boards: (payload) => {
      dispatch(boards(payload));
    },
    boardDetails: (payload) => {
      dispatch(boardDetails(payload));
    },
    boardContent: (payload) => {
      dispatch(boardContent(payload));
    },
    boardContentDetails: (payload) => {
      dispatch(boardContentDetails(payload));
    },
    boardContentComments: (payload) => {
      dispatch(boardContentComments(payload));
    },
    boardGeneralComments: (payload) => {
      dispatch(boardGeneralComments(payload));
    },
    boardType: (payload) => {
      dispatch(boardType(payload));
    },
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
    downloadingProgressModel: (progress) => {
      dispatch(downloadingProgressModel(progress));
    },
    downloadFileDetails: (file) => {
      dispatch(downloadFileDetails(file));
    },
    downloadingProgressId: (id) => {
      dispatch(downloadingProgressId(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BoardDetails);
