import { Axios } from "../lib/axios";
export const ClientServices = {
  getClientUsers,
  getClientUserDetails,
  addClientUser,
  editClientUser,
  toggleClientUser,
  removeClientUser,
  getClientUserRoles,
  getClientSpecificUsers,
  resendEmailVerification,
  getTotalClientUsers,
  getClientDetails,
  toggleClientUserStatus,
  copyEmailVerification,
  getClientUserStandardTemplate,
  uploadBulkClientUsers,
};
function getClientUserRoles() {
  return Axios.get(`/roles?type=tbr`);
}
function getClientUsers(url) {
  return Axios.get(url);
}
function getClientSpecificUsers(url) {
  return Axios.get(url);
}
function getClientUserDetails(userId) {
  return Axios.get(`client-user-management/client-user/${userId}`);
}
function addClientUser(body) {
  return Axios.post(`client-user-management/client-user`, body);
}
function editClientUser(clientUserId, body) {
  return Axios.put(`client-user-management/client-user/${clientUserId}`, body);
}
function toggleClientUser(clientUserId, status) {
  return Axios.patch(`clientuser/${clientUserId}/status/${status}`);
}
function removeClientUser(body) {
  return Axios.delete(`clientuser`, { data: body });
}
function resendEmailVerification(body) {
  return Axios.post(`resendverificationmail`, body);
}

function getTotalClientUsers() {
  return Axios.get("clientusers");
}

function getClientDetails(clientId) {
  return Axios.get(`client/${clientId}`);
}

function toggleClientUserStatus(clientUserId, status) {
  return Axios.patch(
    `client-user-management/client-user/${clientUserId}/status/${status}`
  );
}

function copyEmailVerification(body) {
  return Axios.post("copyVerificationLink", body);
}

function getClientUserStandardTemplate() {
  let url = "clients/excel/template?type=userstandardtemplate&extension=xlsx";
  return Axios.get(url, { responseType: "arraybuffer" });
}
function uploadBulkClientUsers(body) {
  return Axios.post(`client-user-management/bulkupload`, body);
}
