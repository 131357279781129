import React, { useState } from "react";
import { useEffect } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadFileDetails,
  downloadingProgressModel,
  downloadingProgressId,
} from "../Store/Actions/DownloadProgress";
import { BoardServices } from "../Services/Board";
import { useCallback } from "react";
import { debounce, delay } from "lodash";
import { useRef } from "react";
import constants from "../Utils/constants";
import Toast from "../Utils/Toast";
import gaEvent from "../Utils/GAEvents";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

const DownloadIcon = () => (
  <svg
    width="22"
    height="24"
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_4752_1333)">
      <path
        d="M13.0171 1.4165H8.98285V6.79557H5.72852L11 12.0671L16.2715 6.79557H13.0171V1.4165Z"
        fill="#046CD9"
      />
      <path
        d="M4 14.5837H18M13.0171 1.4165H8.98285V6.79557H5.72852L11 12.0671L16.2715 6.79557H13.0171V1.4165Z"
        stroke="#046CD9"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_4752_1333"
        x="0"
        y="0.916504"
        width="22"
        height="22.167"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4752_1333"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4752_1333"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

function DownloadingFileProgressModel(props) {
  const dispatch = useDispatch();
  const socketUrl = process.env.REACT_APP_WEB_SOCKET_URL;
  let downloadProgressData = useSelector(
    (state) => state.downloadingProcessModel.downloadFileDetails
  );
  let boards = useSelector((state) => state.board);
  let progressId = useSelector(
    (state) => state.downloadingProcessModel.downloadingProgressId
  );
  const [downloadModal, setDownloadModal] = useState(false);
  const progressIdRef = useRef(progressId);

  // Keep the ref updated
  useEffect(() => {
    progressIdRef.current = progressId;
    debouncedGetBoardDownloadData();
  }, [progressId]);

  const debouncedGetBoardDownloadData = useCallback(() => {
    if (progressIdRef.current) {
      BoardServices.getBoardDownloadById(progressIdRef.current).then((res) => {
        dispatch(downloadFileDetails(res.data.data));
        if (res.data.data.file_download_status === "completed") {
          setDownloadModal(true);
        } else if (res.data.data.file_download_status === "failed") {
          dispatch(downloadFileDetails({}));
          dispatch(downloadingProgressModel(false));
          dispatch(downloadingProgressId(null));
          Toast("Failed to download the file.", "error");
        }
      });
    }
  }, [dispatch]);

  const downloadFile = () => {
    BoardServices.getBoardDownloadFile(progressIdRef.current)
      .then((response) => {
        if (response.status === 200) {
          const blob = new Blob([response?.data], {
            type: response.headers["content-type"],
          });
          if (navigator.userAgent.match("CriOS")) {
            delay(() => {
              let reader = new FileReader();

              reader.onload = function (e) {
                window.location.href = reader.result;
              };
              reader.readAsDataURL(blob);
            }, 500);
          } else {
            const url = window.URL.createObjectURL(blob);
            // const isPublished = this.props.board?.boardDetails?.is_published;
            delay(() => {
              // Create a link element
              const link = document.createElement("a");
              link.href = url;

              // Set the filename (if provided in response headers)
              const filename = response.headers["content-disposition"]
                ? response.headers["content-disposition"]
                    .split("filename=")[1]
                    .trim()
                    .replace(/['"]/g, "")
                : null;

              const isPublished = boards?.boardDetails?.is_published;
              const board_id = boards?.boardDetails?.id;

              gaEvent.sendEventToGA(constants.GA.CUSTOM_EVENTS.DOWNLOAD_VIEW, {
                fileName: filename,
                fileFormat: `pdf`,
                moduleName: constants.APPLICATION_ROUTE.MY_BOARDS.LIST.NAME,
                id: board_id,
                isPublished: isPublished,
                pageUrl:
                  props.props.history.location.pathname +
                  props.props.history.location.search,
              });

              link.setAttribute("download", filename);

              // Append the link to the document
              document.body.appendChild(link);

              // Trigger the download
              link.click();

              // Clean up by removing the link and revoking the object URL
              document.body.removeChild(link);
              window.URL.revokeObjectURL(url);
            }, 500);
          }
        }
        dispatch(downloadFileDetails({}));
        dispatch(downloadingProgressModel(false));
        dispatch(downloadingProgressId(null));
        setDownloadModal(false);
      })
      .catch((error) => {
        if (error?.status === 403) {
          Toast(
            error.response
              ? error.response
              : "You don't have access to the board or have not selected any boards.",
            "error"
          );
        } else {
          Toast(
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
            "error"
          );
        }
      });
  };

  useEffect(() => {
    const socket = new WebSocket(socketUrl);

    socket.onopen = () => console.log("WebSocket connection opened");
    socket.onmessage = (event) => {
      let msg = event.data;
      if (msg === "NEW_NOTIFICATION_BOARDS_DOWNLOAD") {
        debouncedGetBoardDownloadData();
      }
    };
    socket.onerror = (error) => console.error("WebSocket error:", error);
    socket.onclose = () => console.log("WebSocket connection closed");

    return () => {
      socket.close();
    };
  }, [socketUrl, debouncedGetBoardDownloadData]);

  let percentage = downloadProgressData?.file_downloading_progress || 0;

  return (
    <>
      <div style={{ position: "relative" }}>
        <div className="downloading-file-progress justify-between">
          <div className="d-flex" style={{ alignItems: "center", gap: "10px" }}>
            <div style={{ width: "26px" }}>
              <CircularProgressbar
                value={percentage || 0}
                text={`${percentage || 0}%`}
                styles={buildStyles({
                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "butt",

                  // Text size
                  textSize: "30px",

                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: 0.5,

                  // Can specify path transition in more detail, or remove it entirely
                  // pathTransition: 'none',

                  // Colors
                  pathColor: `#046CD9`,
                  textColor: "#046CD9",
                  trailColor: "#ffffff",
                  backgroundColor: "#3e98c7",
                })}
              />
            </div>
            <div>
              <span style={{ fontSize: "12px", fontWeight: 600 }}>
                {percentage === 100 ? "Download Now" : "Processing..."}
              </span>
            </div>
          </div>
          {percentage === 100 && (
            <div
              style={{ marginTop: "5px", cursor: "pointer" }}
              onClick={downloadFile}
            >
              <DownloadIcon />
            </div>
          )}
        </div>
      </div>

      <Modal
        isOpen={downloadModal}
        toggle={() => {}}
        className={"modal-sm modal-confirm custom-modal"}
      >
        <ModalBody>
          <div className="custom-ui">
            <span>
              Board contents PDF is now ready for download. Thank you for your
              patience!
            </span>
          </div>
        </ModalBody>

        <ModalFooter>
          <Button
            className="btn-outline-primary modal-btn"
            onClick={downloadFile}
          >
            Download Now
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
}

export default DownloadingFileProgressModel;
