import { combineReducers } from "redux";
import home from "./home";
import company_details from "./company_details";
import project_details from "./project_details";
import client from "./client";
import board from "./board";
import benchmark_details from "./benchmark_details";
import market_forecast_details from "./market_forecast_details";
import market_landscape_details from "./market_landscape_details";
import customer_study_details from "./customer_study_details";
import ecosystem_details from "./ecosystem_details";
import special_reports from "./special_reports";
import visualization from "./visualization";
import notification from "./notification";
import downloadingProcessModel from "./downloadProgress";
import storageSession from "redux-persist/lib/storage/session";

/**
 * persisting data in preview page in session storage
 * so if page is refreshed, no damage happens to chart data
 */
const sessionPersistConfig = {
  key: "sessionRoot",
  storage: storageSession,
  whitelist: ["visualization"],
};

const rootReducer = combineReducers({
  home,
  company_details,
  client,
  project_details,
  board,
  benchmark_details,
  market_forecast_details,
  market_landscape_details,
  special_reports,
  customer_study_details,
  ecosystem_details,
  // visualization: persistReducer(sessionPersistConfig, visualization)
  visualization,
  notification,
  downloadingProcessModel,
});

export default rootReducer;
