import React from "react";
import { HeaderNotificationService } from "../Services/HeaderNotification";
import moment from "moment";
import {
  notificationItem,
  notifications,
} from "../Store/Actions/Notifications";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Toast from "../Utils/Toast";
import constants from "../Utils/constants";

function Notifications() {
  const dispatch = useDispatch();
  const notificationList = useSelector(
    (state) => state.notification.notifications
  );
  const history = useHistory();

  const handleMarkReadAll = async () => {
    try {
      let input = {
        ids: notificationList.filter((i) => !i.is_read).map((item) => item.id),
      };
      await HeaderNotificationService.markReadAll(input);
      await getNotificationList();
    } catch (error) {
      Toast(
        error && error.data && error.data.error
          ? error.data.error
          : constants.ERROR.SOMETHING_WENT_WRONG,
        "error"
      );
    }
  };

  const markAsRead = async (e, id) => {
    try {
      e.stopPropagation();
      let input = {
        ids: [id],
      };
      await HeaderNotificationService.markReadAll(input);
      await getNotificationList();
    } catch (error) {
      Toast(
        error && error.data && error.data.error
          ? error.data.error
          : constants.ERROR.SOMETHING_WENT_WRONG,
        "error"
      );
    }
  };

  const handleNotification = async (e, id, boardId, isRead, meta_data) => {
    try {
      if (!isRead) {
        let input = {
          ids: [id],
        };
        await HeaderNotificationService.markReadAll(input);
        await getNotificationList();
      }
      dispatch(notificationItem(JSON.parse(meta_data)));

      history.push(`/board/${boardId}/dashboard`);
      e.stopPropagation();
    } catch (error) {
      Toast(
        error && error.data && error.data.error
          ? error.data.error
          : constants.ERROR.SOMETHING_WENT_WRONG,
        "error"
      );
    }
  };

  const getNotificationList = async () => {
    try {
      let list = await HeaderNotificationService.getNotificationList();
      dispatch(notifications(list?.data?.notifications || []));
    } catch (error) {
      Toast(
        error && error.data && error.data.error
          ? error.data.error
          : constants.ERROR.SOMETHING_WENT_WRONG,
        "error"
      );
    }
  };

  return (
    <aside className="main_content">
      <div className="m-3 mb-0 bg-white pb-3 br_8 main_content_height">
        <div className="notification-list-view">
          <div className="notification-header">
            <span className="span-1">Notifications</span>
            {notificationList.filter((i) => !i.is_read).map((item) => item.id)
              .length > 0 && (
              <span
                className="span-2"
                style={{ cursor: "pointer" }}
                onClick={handleMarkReadAll}
              >
                Mark read all
              </span>
            )}
          </div>
          {notificationList.length ? (
            <div
              className="notification-main-div"
              style={{ height: "72vh", overflowY: "scroll" }}
            >
              {notificationList.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="notification-div"
                    style={{
                      background: !item.is_read ? "#EEF6FF" : "none",
                      padding: "10px",
                      cursor: "pointer",
                    }}
                    onClick={(e) =>
                      handleNotification(
                        e,
                        item.id,
                        item.board_id,
                        item.is_read,
                        item?.meta_data
                      )
                    }
                  >
                    <div className="div-1">
                      <img
                        src={
                          "https://tbr-ggk.s3.us-east-2.amazonaws.com/MassageIcon.svg"
                        }
                        alt="pin-icon"
                        style={{ width: "29px" }}
                      />
                      <div>
                        <span className="span-1" style={{ fontSize: "22px" }}>
                          {item.title}
                        </span>
                        <span className="span-2" style={{ fontSize: "14px" }}>
                          {item.message}
                        </span>
                        <span className="span-3" style={{ fontSize: "12px" }}>
                          {moment(item.created_at).format(
                            "MMM DD, YYYY , HH:mm:ss"
                          )}

                          {!item.is_read && (
                            <span
                              style={{ fontSize: "12px", cursor: "pointer" }}
                              onClick={(e) =>
                                markAsRead(e, item.id, item?.meta_data)
                              }
                            >
                              Mark as read
                            </span>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div
              style={{
                height: "72vh",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                fontSize: "24px",
                fontWeight: "400",
              }}
            >
              No notifications yet!
            </div>
          )}
        </div>
      </div>
    </aside>
  );
}

export default Notifications;
