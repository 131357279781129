import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { FaqServices } from "../Services/FaqService";
import { activeMenuTab } from "../Store/Actions/User";
import constants from "../Utils/constants";
import Toast from "../Utils/Toast";
import { Collapser, Trigger, Panel } from "react-collapser";
import "react-collapser/dist/default-styles.css";
import DocumentTitle from "../Utils/DocumentTitle";
import ScrollTo from "react-scroll-into-view";

let scrollTimeout;

function Faqs() {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const faqsContentRef = useRef();
  const categoryRefs = useRef([]);
  const faqRefs = useRef([]);

  /**
   * Add active class to first category
   * Also add more height to last faq item to allow space for scroll
   */
  useEffect(() => {
    if (categoryRefs.current && categoryRefs.current.length) {
      categoryRefs.current[0].classList.add("active");
    }
    if (faqRefs.current && faqRefs.current.length && faqsContentRef.current) {
      let lastListItem = faqRefs.current[faqRefs.current.length - 1];
      if (lastListItem.offsetHeight < faqsContentRef.current.offsetHeight) {
        lastListItem.style.minHeight =
          faqsContentRef.current.offsetHeight + "px";
      }
    }
  }, [
    categoryRefs.current,
    categories,
    faqsContentRef.current,
    faqRefs.current,
  ]);

  useEffect(() => {
    DocumentTitle(constants.APPLICATION_ROUTE.FAQS.HEADER.TITLE);
    dispatch(activeMenuTab(constants.APPLICATION_ROUTE.FAQS.LIST.ROUTE));
    FaqServices.getFaqs()
      .then((res) => {
        let data = res.data.data;

        if (data.length) {
          setCategories(data);
        }
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  }, [dispatch]);

  const categoryClicked = (id) => {
    remove_active_class();
    let elementIndex = categories.findIndex((category) => category.id === id);
    categoryRefs.current[elementIndex].classList.add("active"); // add active class to selected li
  };

  /**
   * Remove active class to all li elements
   */
  const remove_active_class = () => {
    categoryRefs.current.forEach((ref) => ref.classList.remove("active"));
  };

  /**
   * Handle on scroll event implements debouncing technique to active the category item
   * @param {event} e synthetic event
   */
  const handleScroll = (e) => {
    e.persist();
    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(() => {
      let contentScrolled = e.target.scrollTop;
      let contentHeight = 0;
      remove_active_class();
      for (let i = 0; i < faqRefs.current.length; i++) {
        contentHeight += faqRefs.current[i].offsetHeight;
        if (contentHeight > contentScrolled) {
          categoryRefs.current[i].classList.add("active");
          break;
        }
      }
    }, 500);
  };

  return (
    <aside className="main_content main_content_2 faq-page">
      <div className="m-3 mb-0 bg-white px-3 pb-3 br_8 main_content_height">
        <div className="d-flex align-items-center justify-content-center faq-page-title-sec">
          <span className="material-icons-round chat-icon text-black">
            question_answer
          </span>
          <h1 className="mb-0 text-black2 font-weight-bold">
            Frequently Asked Questions
          </h1>
        </div>
        <aside className="inner-sidebar">
          <div className="faq-inner-sidebar-content h-100">
            <ul>
              {categories.map((category, index) => (
                <ScrollTo
                  selector={`#card${category.id}`}
                  alignToTop={true}
                  scrollOptions={{ block: "nearest" }}
                  className="faq-sideitem"
                  key={category.id}
                >
                  <li
                    key={category.id}
                    // className={category.id === selectedCategory?.id ? 'active' : ''}
                    onClick={() => categoryClicked(category.id)}
                    ref={(el) => (categoryRefs.current[index] = el)}
                  >
                    <h5 className="ellipsis font-weight-bold text-black2">
                      {category.category_name}
                    </h5>
                    <p className="mb-0 text-grey">
                      {`${
                        category.description.length > 60
                          ? `${category.description.substring(0, 60)}...`
                          : `${category.description.substring(0, 60)}`
                      }`}
                    </p>
                  </li>
                </ScrollTo>
              ))}
              {!categories.length ? <h4>No Topics</h4> : ""}
            </ul>
          </div>
        </aside>
        <aside className="faq_content dash_board_content inner-sidebar-right-content">
          <div className="faq-right-content">
            <div
              className="card faqs-card"
              onScroll={handleScroll}
              ref={faqsContentRef}
            >
              <div className="card-body">
                {categories.map((category, index) => (
                  <div
                    className="faq-topic-card"
                    id={`card${category.id}`}
                    key={`card${category.id}`}
                    ref={(el) => (faqRefs.current[index] = el)}
                  >
                    <div className="">
                      <h2 className="faq-title font-weight-bold text-black2">
                        {category.category_name}
                      </h2>
                    </div>
                    <div className="">
                      <p className="text-grey">{category.description}</p>
                    </div>
                    <div className="faqs-collapse-wrapper">
                      {category.faqs?.map((faq, index) => (
                        <div
                          className="custom-collapser custom-collapser2 custom-collapser2-2"
                          key={index}
                        >
                          <Collapser>
                            <Trigger>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                  <h5 className="font-weight-bold mb-0 title">
                                    {faq.question}
                                  </h5>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="collapse-icon-wrapper">
                                    <span className="material-icons-round add icon">
                                      add
                                    </span>
                                    <span className="material-icons-round remove icon">
                                      remove
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Trigger>
                            <Panel>{faq.answer}</Panel>
                          </Collapser>
                        </div>
                      ))}
                      {!category.faqs ? <h4>No FAQs</h4> : ""}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </aside>
      </div>
    </aside>
  );
}

export default Faqs;
