import React, { Component } from "react";
import CustomReactTooltip from "../Components/CustomReactTooltip";
let a;
class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_visible: false,
    };
  }

  componentDidMount() {
    var scrollComponent = this;
    a = function () {
      scrollComponent.toggleVisibility();
    };
    document.addEventListener("scroll", a);
  }
  componentWillUnmount() {
    document.removeEventListener("scroll", a);
  }

  toggleVisibility() {
    if (window.scrollY > 300 || document.scrollY > 300) {
      this.setState({
        is_visible: true,
      });
    } else {
      this.setState({
        is_visible: false,
      });
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  render() {
    const { is_visible } = this.state;
    return (
      <div
        className="scroll-to-top"
        data-for={`tooltip-scroll-to-top`}
        data-tip={`Scroll to top`}
        data-iscapture="true"
        data-testid="scrolldiv1"
      >
        {is_visible && (
          <button
            className="btn btn-primary d-inline-flex p-2"
            onClick={() => this.scrollToTop()}
            data-testid="scrollbtn"
          >
            <span className="material-icons-round icon-fs-20 font-weight-bold">
              arrow_upward
            </span>
          </button>
        )}
        <CustomReactTooltip id={`tooltip-scroll-to-top`} multiline={true} />
      </div>
    );
  }
}

export default Search;
