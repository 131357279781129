import { ecosystem_details as DETAILS } from "../actionConstants";

const ecosystemCategories = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.ECOSYSTEM_CATEGORIES:
      return action.payload;
    default:
      return state;
  }
};

const ecosystemDetails = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.ECOSYSTEM_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

const categories = (state = [], action) => {
  switch (action.type) {
    case DETAILS.CATEGORIES:
      return action.payload;
    default:
      return state;
  }
};

const ecosystemContent = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.ECOSYSTEM_CONTENT:
      return action.payload;
    default:
      return state;
  }
};

export { ecosystemCategories, ecosystemDetails, categories, ecosystemContent };
