import { market_forecast_details as DETAILS } from "../Reducers/actionConstants";

const marketForecastCategories = (payload) => {
  return {
    type: DETAILS.MARKET_FORECAST_CATEGORIES,
    payload,
  };
};

const marketForecastDetails = (payload) => {
  return {
    type: DETAILS.MARKET_FORECAST_DETAILS,
    payload,
  };
};

const categories = (payload) => {
  return {
    type: DETAILS.CATEGORIES,
    payload,
  };
};

const marketForecastContent = (payload) => {
  return {
    type: DETAILS.MARKET_FORECAST_CONTENT,
    payload,
  };
};

export {
  marketForecastCategories,
  marketForecastDetails,
  categories,
  marketForecastContent,
};
