import React, { useState, useEffect, useRef } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Slugify from "../Utils/Slugify";
import { UncontrolledPopover, PopoverBody, Toast } from "reactstrap";
import { connect, useSelector, useDispatch } from "react-redux";
import { boards } from "../Store/Actions/Board";
import Collapsible from "react-collapsible";
import { useParams } from "react-router-dom";
import gotoSection from "../Utils/ScrollToTop";
import decodeJWT from "../lib/decodeJWT";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import { notificationItem } from "../Store/Actions/Notifications";

const BoardDashboardSidebar = (props) => {
  const [category, setCategory] = useState([]);
  const [windowLoaded, setWindowLoaded] = useState(true);
  const [showPopover, setShowPopover] = useState(false);
  const [isError, SetIsError] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [hasAccess, setHasAccess] = useState(false);
  const [popoverId, setPopoverId] = useState("add-edit-category-subcategory");
  const formRef = useRef(null);
  const subcategoryRef = useRef(null);
  const boardId = useParams();
  const popoverRef = useRef(null);
  const notificationData = useSelector((state) => state.notification);
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) return;

    if (windowLoaded) {
      window.scrollTo(0, 0);
      setWindowLoaded(false);
    }

    const tokenDetails = decodeJWT(token);
    const user_id = tokenDetails?.user?.id;

    const boardDetails = props?.board?.boardDetails;
    if (!boardDetails) return;

    const is_owner = boardDetails?.owner?.id === user_id;
    const is_edit_permission = boardDetails.mode?.short_name === "edit";

    setHasAccess(is_owner || is_edit_permission);

    if (
      notificationData?.notificationMetadata?.type === "pin" &&
      props.boardCategories.length
    ) {
      let subCategoryId =
        notificationData?.notificationMetadata?.subcategory_id;
      let subCategoryName =
        notificationData?.notificationMetadata?.board_subcategory_name;

      gotoSection(`#${Slugify(subCategoryName)}_${subCategoryId}`, 190);
      dispatch(notificationItem(null));
      // this.props.notificationItem(null);
    }
  }, [props]);

  const toggleCheckbox = (e, type, category_id, sub_category_id) => {
    let original_categories = [...props.boardCategories];
    let checkbox_selection = e.target.checked;
    if (type === "category") {
      let cat = original_categories.findIndex((c) => c.id === category_id);
      original_categories[cat].is_selected = checkbox_selection;
      if (original_categories[cat].subcategories) {
        original_categories[cat].subcategories.forEach((res_sub) => {
          res_sub.is_selected = checkbox_selection;
        });
      }
    } else if (type === "sub_category") {
      original_categories.forEach((res) => {
        if (res.id === category_id) {
          if (res.subcategories) {
            res.subcategories.forEach((res_sub) => {
              if (res_sub.id === sub_category_id)
                res_sub.is_selected = checkbox_selection;
            });
            let total = res.subcategories.length;
            let total_selected = res.subcategories.filter(
              (row) => row.is_selected === true
            ).length;
            if (total_selected === total && total > 0) {
              res.is_selected = true;
            } else {
              res.is_selected = false;
            }
          }
        }
      });
    }
    props.updateBoardCategoriesState(original_categories);
  };

  const openAndClosePopoverModal = () => {
    setShowPopover(!showPopover);
    setIsEdit(false);
    SetIsError(false);
  };

  const openExistingCategoryForm = (category, id) => {
    setPopoverId(id);
    setShowPopover(true);
    SetIsError(false);
    setIsEdit(true);
    setCategory(category);
    setTimeout(() => {
      formRef.current[0].value = category.name;
      if (category.subcategories) {
        if (formRef.current.length - 2 <= category.subcategories.length) {
          category.subcategories.forEach((item, index) => {
            addSubCategory();
            formRef.current[index + 2].value =
              category.subcategories[index].name;
          });
        }
      }
    }, 200);
  };

  const handleSubmit = (event, category) => {
    SetIsError(false);
    event.preventDefault();
    let data = [];
    if (event.target[0].value != "") {
      for (let i = 0; i < event.target.length; i++) {
        if (event.target[i].type === "text") {
          if (event.target[i].value == "") {
            SetIsError(true);
            return;
          }
          event.target[i].value != "" && data.push(event.target[i].value);
        }
      }
    } else {
      SetIsError(true);
      return;
    }
    // update the board category content
    if (isEdit) {
      // update the category, sub-cat or if not exist then create the new sub category
      data.forEach((item, index) => {
        if (index >= 1) {
          if (category.subcategories[index - 1] !== undefined) {
            category.subcategories[index - 1].name = item;
          } else {
            let newSubcategory = {
              id: null,
              name: item,
              rank: index,
            };
            category.subcategories.push(newSubcategory);
          }
        }
      });
      const updatedCategory = {
        id: category.id,
        name: data[0],
        rank: category.rank,
        subcategories: category.subcategories?.map((subcategory, index) => ({
          id: subcategory.id,
          name: data[index + 1],
          rank: subcategory.rank,
        })),
      };
      props.updateBoardSidebarCategory(boardId.id, updatedCategory);
    } else {
      // add the new category content
      const newCategory = {
        id: null,
        name: data[0],
        rank: props.boardCategories.length + 1,
        subcategories: data.slice(1)?.map((subcategory, index) => ({
          id: null,
          name: subcategory,
          rank: index + 1,
        })),
      };
      props.updateBoardSidebarCategory(boardId.id, newCategory);
    }
    openAndClosePopoverModal();
  };

  const addSubCategory = () => {
    let x = document.createElement("div");
    x.className = "input-wrapper";
    x.innerHTML =
      '<input type="text" class="form-control" id="subCategoryName" placeholder="Enter Subcategory Name" />';
    subcategoryRef.current.appendChild(x);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setShowPopover(false); // Close popover if clicked outside
      }
    };

    // Close popover on scroll
    const handleSidebarScroll = () => {
      setShowPopover(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    document
      .querySelector(".inner-sidebar")
      .addEventListener("scroll", handleSidebarScroll);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document
        .querySelector(".inner-sidebar")
        .removeEventListener("scroll", handleSidebarScroll);
    };
  }, []);

  return (
    <aside className="inner-sidebar board-details">
      <div className="sidebar_nav2 d-flex flex-column h-100">
        <div className="d-flex icon-wrapper">
          <span
            className="material-icons-outlined icon"
            data-testid="sidebarTest"
            onClick={props.toggleBoardInnerSidebar}
          >
            keyboard_double_arrow_left
          </span>
        </div>
        <div className="opened-innersidebar-content">
          {hasAccess && (
            <button
              type="button"
              className="btn content-save-btn mb-2 w-auto float-none add_category d-flex align-items-center pl-1"
              id="add-edit-category-subcategory"
              onClick={openAndClosePopoverModal}
            >
              <span className="material-icons-outlined icon icon-fs-18 text-black2">
                add
              </span>
              Add Category
            </button>
          )}
          <div className="custom-collapser mt-1 inner-sidebar-collapser">
            <div className="mb-1">
              <div className="custom-collapser-item ">
                <DragDropContext
                  onDragEnd={(result) =>
                    hasAccess && props.handleOnDragEnd(result)
                  }
                >
                  <Droppable
                    droppableId="row-category"
                    type="category"
                    direction="vertical"
                  >
                    {(provided, snapshot) => (
                      <div
                        isDraggingOver={snapshot.isDraggingOver}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {props.boardCategories?.map((category, index) => (
                          <Draggable
                            draggableId={category?.id?.toString()}
                            index={index}
                            type="category"
                            key={category.id}
                          >
                            {(provided) => (
                              <div
                                className="board_dashboard_sidebar"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <Collapsible
                                  open={true}
                                  trigger={
                                    <div className="d-flex align-items-center ml-auto collapse-icon-board">
                                      <span
                                        style={{ marginTop: "2px" }}
                                        className="material-icons-outlined material-icon"
                                      >
                                        chevron_right
                                      </span>
                                    </div>
                                  }
                                  triggerSibling={() => (
                                    <div className="d-flex align-items-center trigger-block mt-1 trigger-icon">
                                      <div
                                        style={{
                                          width: "71%",
                                          marginRight: "0px",
                                          color: "#0561C1",
                                          marginLeft: "15px",
                                        }}
                                        className="mb-0 title"
                                        for="tbr_insights"
                                      >
                                        <input
                                          style={{ marginBottom: "1px" }}
                                          className="custom-checkbox-new"
                                          name={category.name}
                                          type="checkbox"
                                          id={Slugify(category.name)}
                                          checked={category.is_selected}
                                          onChange={(e) => {
                                            e.stopPropagation();
                                            toggleCheckbox(
                                              e,
                                              "category",
                                              category.id
                                            );
                                          }}
                                        />
                                        <span
                                          className="ml-2 cursor-pointer"
                                          onClick={() =>
                                            gotoSection(
                                              `#${Slugify(category.name)}_${
                                                category.id
                                              }`,
                                              190
                                            )
                                          }
                                        >
                                          {category.name}
                                        </span>
                                      </div>
                                      <div className="hover-button d-flex align-item-center">
                                        {hasAccess && (
                                          <>
                                            <span
                                              id={`add-edit-category-subcategory_${category.id}`}
                                              className="material-icons-outlined icon icon-fs-18 text-primary cursor-pointer"
                                              style={{ paddingRight: "4px" }}
                                              onClick={() => {
                                                openExistingCategoryForm(
                                                  category,
                                                  `add-edit-category-subcategory_${category.id}`
                                                );
                                              }}
                                            >
                                              edit
                                            </span>
                                            <span
                                              className="material-icons-outlined icon icon-fs-18 text-primary"
                                              style={{ cursor: "grab" }}
                                              data-for="dragtooltip"
                                              data-tip="Drag to Move"
                                              {...provided.dragHandleProps}
                                            >
                                              drag_indicator
                                              <CustomReactTooltip
                                                id="dragtooltip"
                                                multiline={true}
                                              />
                                            </span>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                >
                                  <Droppable
                                    droppableId={category?.id?.toString()}
                                    type="sub-category"
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        className="collapse-item-container"
                                        isDraggingOver={snapshot.isDraggingOver}
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                      >
                                        {category.subcategories?.map(
                                          (subcategory, index) => (
                                            <Draggable
                                              draggableId={subcategory?.id?.toString()}
                                              index={index}
                                              type="sub-category"
                                              key={subcategory.id}
                                            >
                                              {(provided, snapshot) => (
                                                <div
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  isDragging={
                                                    snapshot.isDragging
                                                  }
                                                >
                                                  <div className="board-collapse-panel">
                                                    <div className="board-collapse-panel-content trigger-icon">
                                                      <div
                                                        className={
                                                          index !==
                                                          category.subcategories
                                                            .length -
                                                            1
                                                            ? "board-item-border"
                                                            : "board-item"
                                                        }
                                                      >
                                                        <input
                                                          className="custom-checkbox-new"
                                                          type="checkbox"
                                                          key={subcategory.id}
                                                          name={
                                                            Slugify(
                                                              category.name
                                                            ) +
                                                            "_" +
                                                            Slugify(
                                                              subcategory.name
                                                            ) +
                                                            "_" +
                                                            subcategory.id
                                                          }
                                                          id={
                                                            Slugify(
                                                              category.name
                                                            ) +
                                                            "_" +
                                                            Slugify(
                                                              subcategory.name
                                                            ) +
                                                            "_" +
                                                            subcategory.id
                                                          }
                                                          checked={
                                                            subcategory.is_selected
                                                          }
                                                          onChange={(e) =>
                                                            toggleCheckbox(
                                                              e,
                                                              "sub_category",
                                                              category.id,
                                                              subcategory.id
                                                            )
                                                          }
                                                        />
                                                        <label
                                                          className="non-selected sub-category-label mb-0 add-remove-label"
                                                          htmlFor="tbr_insights_tbr_breaking_news"
                                                          style={{
                                                            width: "71%",
                                                          }}
                                                          onClick={() =>
                                                            gotoSection(
                                                              `#${Slugify(
                                                                subcategory.name
                                                              )}_${
                                                                subcategory.id
                                                              }`,
                                                              190
                                                            )
                                                          }
                                                        >
                                                          <span className="">
                                                            {subcategory.name}
                                                          </span>
                                                        </label>
                                                        {hasAccess && (
                                                          <span
                                                            className="material-icons-outlined icon icon-fs-18 text-primary hover-button"
                                                            style={{
                                                              cursor: "grab",
                                                            }}
                                                            data-for="dragtooltip"
                                                            data-tip="Drag to Move"
                                                            {...provided.dragHandleProps}
                                                          >
                                                            drag_indicator
                                                            <CustomReactTooltip
                                                              id="dragtooltip"
                                                              multiline={true}
                                                            />
                                                          </span>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </Draggable>
                                          )
                                        )}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </Collapsible>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                {hasAccess && showPopover && (
                  <UncontrolledPopover
                    className="custom-popover add-edit-category-subcategory-popover"
                    placement="right"
                    trigger="legacy"
                    isOpen={showPopover}
                    target={popoverId}
                    innerRef={popoverRef}
                  >
                    <PopoverBody>
                      <div className="d-flex align-items-center title-block">
                        <h5 className="mb-0 title font-weight-normal">
                          {isEdit ? "Edit" : "Add"} Category / Subcategory
                        </h5>
                        <span
                          onClick={openAndClosePopoverModal}
                          className="material-icons-round icon ml-auto icon-fs-18 cursor-pointer"
                        >
                          close
                        </span>
                      </div>
                      <form
                        onSubmit={(e) => handleSubmit(e, category)}
                        ref={formRef}
                      >
                        <div className="cat-subcat-inputs-block">
                          {isError && (
                            <div className="input-error">
                              Input can not be empty
                            </div>
                          )}
                          <input
                            type="text"
                            className="form-control"
                            id="categoryName"
                            placeholder="Enter Category Name"
                          />
                          <button
                            type="button"
                            className="btn btn-primary content-save-btn mb-2 w-auto float-none add-sub-category d-flex align-items-center pl-1"
                            onClick={addSubCategory}
                          >
                            <span className="material-icons-outlined icon icon-fs-18 text-black2">
                              add
                            </span>
                            Add Sub Category
                          </button>
                          <div
                            className="sub-category-inputs"
                            ref={subcategoryRef}
                          ></div>
                        </div>
                        <div className="save-btn-wrapper d-flex">
                          <button
                            type="submit"
                            className={
                              "btn btn-primary icon-text-btn d-inline-flex align-items-center ml-auto board-popover-save-btn"
                            }
                          >
                            {isEdit ? "Update" : "Save"}

                            <span className="material-icons-outlined icon-fs-16 ml-1">
                              {isEdit ? "sync" : "save"}
                            </span>
                          </button>
                        </div>
                      </form>
                    </PopoverBody>
                  </UncontrolledPopover>
                )}
              </div>
            </div>
          </div>
          {/* <DragDropContext onDragEnd={(e) => handleOnDragEnd(e)}>
              <Droppable droppableId="characters">
                {(provided) => (
                  <ul
                    className="characters"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {boardItems.map((board, index) => (
                      <Draggable
                        key={board.id}
                        draggableId={board.id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <p>{board.name}</p>
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext> */}
        </div>
        <div className="closed-innersidebar-content d-flex flex-grow-1">
          <div className="content text-center font-weight-semibold">BOARDS</div>
        </div>
      </div>
    </aside>
  );
};

const mapStateToProps = (state) => {
  return {
    board: state.board,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    boards: (payload) => {
      dispatch(boards(payload));
    },
    notificationItem: (payload) => {
      dispatch(notificationItem(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BoardDashboardSidebar);
