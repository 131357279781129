import { downloadProgress as DETAILS } from "../actionConstants";

const downloadingProgressModel = (state = true, action) => {
  switch (action.type) {
    case DETAILS.DOWNLOAD_PROGRESS_MODEL:
      return action.payload;
    default:
      return state;
  }
};

const downloadFileDetails = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.DOWNLOAD_FILE_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

const downloadingProgress = (state = 0, action) => {
  switch (action.type) {
    case DETAILS.DOWNLOADING_PROGRESS:
      return action.payload;
    default:
      return state;
  }
};
const downloadingProgressId = (state = null, action) => {
  switch (action.type) {
    case DETAILS.DOWNLOADING_PROGRESS_ID:
      return action.payload;
    default:
      return state;
  }
};

export {
  downloadingProgressModel,
  downloadFileDetails,
  downloadingProgress,
  downloadingProgressId,
};
