import React, { useState, useEffect } from "react";
import CustomReactTooltip from "../../../Components/CustomReactTooltip";
import constants from "../../../Utils/constants";
import GreenCheckbox from "./SelectSection/GreenCheckbox.svg";
import { clone, cloneDeep, map, remove } from "lodash";

function CustomCheckBox(props) {
  const [activeNode, setActiveNode] = useState();

  useEffect(() => {
    if (Object.keys(props).indexOf("expandAll") !== -1) {
      let expandedArray = [];
      props.expandAll && getExpandedArray(props.nodes, expandedArray);
      props.onExpand(expandedArray);
    }
  }, [props.expandAll, props.nodes, props.disable]);

  useEffect(() => {
    setActiveNode(props.defaultSelection);
  }, [props.defaultSelection]);

  const handleChevron = (value, isOpen) => {
    let expanded = [...props.expanded];
    if (isOpen) {
      expanded.push(value);
    } else {
      let node_values_to_be_removed = getAllChildrenValues(value);
      expanded = expanded.filter(
        (value) => !node_values_to_be_removed.includes(value)
      );
    }
    props.onExpand(expanded);
  };

  /**
   * @param {Integer} value Representing the value of node
   * @returns An Array of all node values to be removed from expanded array
   */
  const getAllChildrenValues = (value) => {
    let node = dfs_algo_on_nodes(props.nodes, value);
    let valuesArray = [];
    populateValuesArrayRecursively(node, valuesArray);
    return valuesArray;
  };

  /**
   * This function recursively updates the valuesArray with all the node values which can be used to remove them from expanded array
   * @param {Object} node An Object representing indiviudal node
   * @param {Array} valuesArray A list of all child nodes values which has children
   */
  const populateValuesArrayRecursively = (node = {}, valuesArray = []) => {
    if (node.children && node.children.length) {
      valuesArray.push(node.value);
      node.children.forEach((item) => {
        populateValuesArrayRecursively(item, valuesArray);
      });
    }
  };

  /**
   * Performs a dfs algorithm on nodes array to find the particular node whose value is same as value provided as attribute
   * @param {Array} nodes An Array of nodes
   * @param {Integer} value An Integer representing the value of node
   * @returns A Node Object
   */
  const dfs_algo_on_nodes = (nodes = [], value) => {
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].value === value) {
        return nodes[i];
      }
      if (nodes[i].children && nodes[i].children.length) {
        let node = dfs_algo_on_nodes(nodes[i].children, value);
        if (node) {
          return node;
        }
      }
    }
  };

  // Handle checkbox state changes
  const handleChecked = (e, value, group_id, level) => {
    if (group_id) {
      let temp = cloneDeep(props.all_data);

      temp = map(temp, (item) => {
        if (item.id === group_id) {
          let tempChecked = clone(item.checked) || [];
          if (e) {
            tempChecked.push(value);
          } else {
            remove(tempChecked, (v) => v === value);
          }
          return { ...item, checked: tempChecked };
        }
        return item;
      });

      props.onCheck(temp);
    } else {
      let selectedItems = clone(props.checked) || [];
      if (e) {
        selectedItems.push(value);
      } else {
        remove(selectedItems, (v) => v === value);
      }
      props.onCheck(selectedItems);
    }
  };

  const handleArrow = (e, value, segment_id) => {
    e.stopPropagation();
    setActiveNode(value);
    props.handleButtonClick(value, segment_id);
  };

  /**
   * This function iterates through all its children and adds value to expanded array
   * This expanded array is used to open all nodes by default if expandAll prop is true
   * @param {Array} nodes An Array of nodes
   * @param {Array} expandedArray An Array of expanded nodes
   */
  const getExpandedArray = (nodes = [], expandedArray = []) => {
    nodes.forEach((element) => {
      if (element.children && element.children.length) {
        expandedArray.push(element.value);
        getExpandedArray(element.children, expandedArray);
      }
    });
  };

  const getOrderlist = (nodes = []) => {
    const { checked, expanded, disabled, showArrow, group_id } = props;

    return (
      <ol className="checkbox-list">
        {nodes.map((item, index) => {
          const {
            value,
            is_data_available,
            is_disabled,
            label,
            children,
            group,
            segment_id,
            level,
          } = item;

          const isChecked = checked.includes(value);
          const isExpanded = expanded?.includes(value) || false;
          const isDataAvailable = is_data_available === "true";
          const isValid = is_data_available === undefined || isDataAvailable;

          const cursorStyle =
            is_disabled || disabled || !isValid ? "not-allowed" : "pointer";
          const opacityStyle =
            is_disabled ||
            disabled ||
            (!isDataAvailable && is_data_available !== undefined)
              ? 0.5
              : 1;

          return (
            <li
              key={index}
              data-testid={`checkbox-li-${value}`}
              className={`checkbox-item ${
                activeNode === value ? "active" : ""
              } ${isExpanded ? "expanded-true" : "expanded-false"}`}
            >
              <span className="d-flex align-items-center rct-text">
                {/* Chevron Icon */}
                {children?.length > 0 && (
                  <span
                    data-testid={`checkbox-chevron-${value}`}
                    className={`material-icons-outlined icon ${
                      isExpanded ? "expanded-true" : "expanded-false"
                    }`}
                    onClick={() => handleChevron(value, !isExpanded)}
                  >
                    {isExpanded ? "expand_more" : "chevron_right"}
                  </span>
                )}

                {/* Checkbox */}
                {!group &&
                  (isDataAvailable && isChecked && disabled ? (
                    <img src={GreenCheckbox} alt="GreenCheck" />
                  ) : (
                    <input
                      className={`custom-checkbox-new ${
                        disabled ? "no-cursor" : ""
                      }`}
                      style={{ cursor: cursorStyle }}
                      type="checkbox"
                      id={value}
                      data-testid={`checkbox-${value}`}
                      onChange={(e) =>
                        handleChecked(e.target.checked, value, group_id, level)
                      }
                      checked={isChecked}
                      disabled={is_disabled || disabled || !isDataAvailable}
                    />
                  ))}

                {/* Label */}
                <label
                  className="text rct-title mb-0"
                  htmlFor={label}
                  data-for={`tooltip-${value}-active`}
                  data-tip={label}
                  style={{
                    opacity: opacityStyle,
                    textTransform: "capitalize",
                  }}
                >
                  {label.length > constants.VISUALIZATION.CHARACTER_LENGTH
                    ? `${label.substring(
                        0,
                        constants.VISUALIZATION.CHARACTER_LENGTH
                      )}...`
                    : label}
                  {label.length > constants.VISUALIZATION.CHARACTER_LENGTH && (
                    <CustomReactTooltip
                      id={`tooltip-${value}-active`}
                      multiline
                    />
                  )}
                </label>

                {/* Arrow Icon */}
                {isChecked && showArrow && !disabled && (
                  <span
                    className="material-icons-outlined add-icon"
                    data-testid={`checkbox-arrow-${value}`}
                    onClick={(e) => handleArrow(e, value, segment_id)}
                  >
                    arrow_forward
                  </span>
                )}
              </span>

              {/* Recursive Rendering */}
              {children && isExpanded && getOrderlist(children)}
            </li>
          );
        })}
      </ol>
    );
  };

  return (
    <div className="visualisation" style={{ width: props?.width || "auto" }}>
      {getOrderlist(props.nodes)}
    </div>
  );
}

export default React.memo(CustomCheckBox);
