import { Axios } from "../lib/axios";

export const DashboardServices = {
  getRecentValues,
  getReportUsage,
  getWhatsNewData,
  getRecentlyViewData,
};

function getRecentValues(url) {
  return Axios.get(url);
}

function getReportUsage(url) {
  return Axios.get(url);
}

function getWhatsNewData(report_name, filter) {
  return Axios.get(
    `/whatsNewsReports?report_name=${report_name}&filter_days=${filter}`
  );
}

function getRecentlyViewData(report_name) {
  return Axios.get(`/recentlyViewReports?report_name=${report_name}`);
}
