import React, { Component } from "react";
class SearchComponent extends Component {
  state = {
    value: this.props.value ? this.props.value : "",
  };
  onSearchChange = (e) => {
    this.setState({
      value: e.target.value,
    });
    this.props.handleInput(e);
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      this.props.handleSearch();
    }
  };
  componentDidMount() {
    this.setState({
      value: this.props.value ? this.props.value : "",
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  render() {
    return (
      <>
        <div className="search-form" data-testid={this.props["data-testid"]}>
          <input
            className="form-control"
            type="text"
            name="name"
            onKeyPress={(e) => {
              e.key === "/" && e.nativeEvent.stopImmediatePropagation();
            }}
            onChange={(e) => {
              this.onSearchChange(e);
            }}
            placeholder={`${
              this.props.placeHolder ? this.props.placeHolder : ""
            }`}
            aria-label={`${
              this.props.placeHolder ? this.props.placeHolder : ""
            }`}
            aria-describedby="basic-addon2"
            value={this.state.value ? this.state.value : ""}
            disabled={this.props.disable || false}
          />
          <button className="btn my-sm-0" type="button">
            <span className="material-icons-outlined">search</span>
          </button>
        </div>
      </>
    );
  }
}
export default SearchComponent;
