const home = {
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  LOGIN_USER_FAILURE: "LOGIN_USER_FAILURE",
  SEGMENTS: "SEGMENTS",
  ACTIVE_MENU_TAB: "ACTIVE_MENU_TAB",
  OPEN_SEARCHBAR: "OPEN_SEARCHBAR",
  SEARCH_TEXT: "SEARCH_TEXT",
  DISPLAY_NAME: "DISPLAY_NAME",
};

const company_details = {
  COMPANY_CATEGORIES: "COMPANY_CATEGORIES",
  COMPANY_SEGMENTS: "COMPANY_SEGMENTS",
  COMPANY_DETAILS: "COMPANY_DETAILS",
  CATEGORIES: "CATEGORIES",
  COMPANY_CONTENT: "COMPANY_CONTENT",
  COMPANY_FINANCIAL_DETAILS: "COMPANY_FINANCIAL_DETAILS",
  COMPANY_FINANCIAL_RADAR_CHART_DETAILS:
    "COMPANY_FINANCIAL_RADAR_CHART_DETAILS",
};
const project_details = {
  PROJECT_CATEGORIES: "PROJECT_CATEGORIES",
  PROJECT_DETAILS: "PROJECT_DETAILS",
  CATEGORIES: "CATEGORIES",
  PROJECT_CONTENT: "PROJECT_CONTENT",
};

const clients = {
  CLIENT_ROLES: "CLIENT_ROLES",
};

const board = {
  BOARDS: "BOARDS",
  BOARD_DETAILS: "BOARD_DETAILS",
  BOARD_CONTENT: "BOARD_CONTENT",
  BOARD_CONTENT_DETAILS: "BOARD_CONTENT_DETAILS",
  BOARD_MODES: "BOARD_MODES",
  BOARD_CONTENT_COMMENTS: "BOARD_CONTENT_COMMENTS",
  BOARD_GENERAL_COMMENTS: "BOARD_GENERAL_COMMENTS",
  BOARD_TYPE: "BOARD_TYPE",
};

const benchmark_details = {
  BENCHMARK_CATEGORIES: "BENCHMARK_CATEGORIES",
  BENCHMARK_DETAILS: "BENCHMARK_DETAILS",
  CATEGORIES: "CATEGORIES",
  BENCHMARK_CONTENT: "BENCHMARK_CONTENT",
};

const customer_study_details = {
  CUSTOMERSTUDY_CATEGORIES: "CUSTOMERSTUDY_CATEGORIES",
  CUSTOMERSTUDY_DETAILS: "CUSTOMERSTUDY_DETAILS",
  CATEGORIES: "CATEGORIES",
  CUSTOMERSTUDY_CONTENT: "CUSTOMERSTUDY_CONTENT",
};

const market_forecast_details = {
  MARKET_FORECAST_CATEGORIES: "MARKET_FORECAST_CATEGORIES",
  MARKET_FORECAST_DETAILS: "MARKET_FORECAST_DETAILS",
  CATEGORIES: "CATEGORIES",
  MARKET_FORECAST_CONTENT: "MARKET_FORECAST_CONTENT",
};
const market_landscape_details = {
  MARKET_LANDSCAPE_CATEGORIES: "MARKET_LANDSCAPE_CATEGORIES",
  MARKET_LANDSCAPE_DETAILS: "MARKET_LANDSCAPE_DETAILS",
  CATEGORIES: "CATEGORIES",
  MARKET_LANDSCAPE_CONTENT: "MARKET_LANDSCAPE_CONTENT",
};

const special_reports = {
  SPECIAL_REPORT_CATEGORIES: "SPECIAL_REPORTS_CATEGORIES",
  SPECIAL_REPORT_DETAILS: "SPECIAL_REPORT_DETAILS",
  CATEGORIES: "CATEGORIES",
  SPECIAL_REPORT_CONTENT: "SPECIAL_REPORT_CONTENT",
};

const ecosystem_details = {
  ECOSYSTEM_CATEGORIES: "ECOSYSTEM_CATEGORIES",
  ECOSYSTEM_DETAILS: "ECOSYSTEM_DETAILS",
  CATEGORIES: "CATEGORIES",
  ECOSYSTEM_CONTENT: "ECOSYSTEM_CONTENT",
};

const visualization = {
  CHART_TYPE: "CHART_TYPE",
  CHART_DATA: "CHART_DATA",
  CHART_ELEMENTS: "CHART_ELEMENTS",
  CHART_AXIS_DETAILS: "CHART_AXIS_DETAILS",
  CHART_SERIES_NAMES: "CHART_SERIES_NAMES",
};

const notification = {
  NOTIFICATION_LIST: "NOTIFICATION_LIST",
  NOTIFICATION_ITEM: "NOTIFICATION_ITEM",
};

const downloadProgress = {
  DOWNLOAD_PROGRESS_MODEL: "DOWNLOAD_PROGRESS_MODEL",
  DOWNLOADING_PROGRESS: "DOWNLOADING_PROGRESS",
  DOWNLOAD_FILE_DETAILS: "DOWNLOAD_FILE_DETAILS",
  DOWNLOADING_PROGRESS_ID: "DOWNLOADING_PROGRESS_ID",
};

export {
  home,
  company_details,
  clients,
  project_details,
  board,
  benchmark_details,
  market_forecast_details,
  market_landscape_details,
  customer_study_details,
  special_reports,
  ecosystem_details,
  visualization,
  notification,
  downloadProgress,
};
