import decodeJWT from "../lib/decodeJWT";

function isTBRIUser() {
  let roleDetails = decodeJWT(localStorage.getItem("token")).role;
  if (roleDetails.type === "tbr_admin") {
    return true;
  }
  return false;
}
export default isTBRIUser;
