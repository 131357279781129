import React, { Component } from "react";
import parse from "html-react-parser";

var options = {
  startTag: "<strong>", // could be a hyperlink
  endTag: "</strong>", // or you could use <i> instead of <b> ... want it? ask!
};
class Highlight extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  wrapper = (match) => {
    return options.startTag + match + options.endTag;
  };

  highlight = (keywords, text) => {
    var regex = keywords.split(" ");
    regex = regex.filter(function (char) {
      return char !== "";
    });
    regex = regex.join("|");
    regex = regex.replace(/[-[\]{}()*+?.,\\^$]/g, "\\$&");

    var matcher = new RegExp(regex, "gi");

    return text.replace(matcher, this.wrapper);
  };

  render() {
    return (
      <>{parse(this.highlight(this.props.searchText, this.props.content))}</>
    );
  }
}
export default Highlight;
