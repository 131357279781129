import React, { Component } from "react";
import { connect } from "react-redux";
import { formatRoute } from "react-router-named-routes";
import { Modal, ModalBody } from "reactstrap";
import AclAction from "../Components/ACL/AclAction";
import BoardDetailsComment from "../Components/BoardDetailsComment";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import Search from "../Components/searchComponent";
import BoardDetailsComments from "../Containers/BoardDetailsComments";
import permissionCheck from "../lib/permissionCheck";
import { categories } from "../Store/Actions/CompanyDetails";
import ComapnyDetailsDateFormatter from "../Utils/ComapnyDetailsDateFormatter";
import constants from "../Utils/constants";
import DocumentTitle from "../Utils/DocumentTitle";
import Slugify from "../Utils/Slugify";
import { BoardServices } from "../Services/Board";
import Toast from "../Utils/Toast";
import decodeJWT from "../lib/decodeJWT";
import ComapnyDetailsDateTimeFormatter from "../Utils/ComapnyDetailsDateTimeFormatter";
import DailogNew from "../Components/DailogNew";
import calculateTimeOnSection from "../Utils/TimeOnSection";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MainCategoryItem from "../Components/MainCategoryItem";
import isTBRIUser from "../Utils/isTBRIUser";
import { notificationItem } from "../Store/Actions/Notifications";

class BoardDashboardContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubItemOpen: true,
      isMainItemOpen: true,
      keyword: "",
      showMoreCommentsModal: false,
      showMoreComments: [],
      showGeneralComments: false,
      downloadBoardAction: false,
      boardID: 0,
      published: false,
      dailogModal: false,
      dailogModalContent: "",
      dailogModalHeader: "",
      unPublishDailogModal: false,
      unPublishDailogModalContent: "",
      unPublishDailogModalHeader: "",
      published_at: null,
      hasAccess: false,
    };
    this.boardsRef = React.createRef();
    this.boardsRef.current = [];
    this.headerRef = React.createRef();
    this.scrollTimer = React.createRef();
  }

  // breadcrumbs = () => {
  //   let boardDetails =
  //     this.props.board && this.props.board.boardDetails
  //       ? this.props.board.boardDetails
  //       : [];
  //   let breadcrumbs = [
  //     {
  //       title: constants.APPLICATION_ROUTE.DASHBOARD.NAME,
  //
  //       link: formatRoute(constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE, {}),
  //       is_active: false,
  //     },
  //     {
  //       title: constants.APPLICATION_ROUTE.MY_BOARDS.LIST.NAME,
  //       link: formatRoute(constants.APPLICATION_ROUTE.MY_BOARDS.LIST.ROUTE, {}),
  //       is_active: false,
  //     },
  //     {
  //       title: boardDetails.id
  //         ? `${boardDetails.name}`
  //         : constants.APPLICATION_ROUTE.MY_BOARDS.DASHBOARD.NAME,
  //       link: null,
  //       is_active: true,
  //     },
  //   ];
  //   return breadcrumbs;
  // };

  toggleGeneralComments = () => {
    this.setState({
      showGeneralComments: !this.state.showGeneralComments,
    });
  };

  // contentLabel = (content) => {
  //   let label;
  //   switch (content.type) {
  //     case "company":
  //       label = (
  //         <h3 className={"category-tag font-weight-semibold mb-0"}>
  //           {`${content.company_name ? content.company_name : ""} - ${
  //             content.segment_name ? content.segment_name : ""
  //           } - ${content.subcategory_name ? content.subcategory_name : ""} `}
  //         </h3>
  //       );
  //       break;
  //     case "project":
  //       label = (
  //         <h3 className={"category-tag font-weight-semibold mb-0"}>
  //           {`${content.project_name ? content.project_name : ""} - ${
  //             content.subcategory_name ? content.subcategory_name : ""
  //           } `}
  //         </h3>
  //       );
  //       break;
  //     case "benchmark":
  //       label = (
  //         <h3 className={"category-tag font-weight-semibold mb-0"}>
  //           {`${content.benchmark_name ? content.benchmark_name : ""} - ${
  //             content.subcategory_name ? content.subcategory_name : ""
  //           } `}
  //         </h3>
  //       );
  //       break;
  //     case "market_forecast":
  //       label = (
  //         <h3 className={"category-tag font-weight-semibold mb-0"}>
  //           {`${
  //             content.market_forecast_name ? content.market_forecast_name : ""
  //           } - ${content.subcategory_name ? content.subcategory_name : ""} `}
  //         </h3>
  //       );
  //       break;
  //     case "market_landscape":
  //       label = (
  //         <h3 className={"category-tag font-weight-semibold mb-0"}>
  //           {`${
  //             content.market_landscape_name ? content.market_landscape_name : ""
  //           } - ${content.subcategory_name ? content.subcategory_name : ""} `}
  //         </h3>
  //       );
  //       break;
  //     case "special_report":
  //       label = (
  //         <h3 className={"category-tag font-weight-semibold mb-0"}>
  //           {`${
  //             content.special_report_name ? content.special_report_name : ""
  //           } - ${content.subcategory_name ? content.subcategory_name : ""} `}
  //         </h3>
  //       );
  //     case "customer_study":
  //       label = (
  //         <h3 className={"category-tag font-weight-semibold mb-0"}>
  //           {`${
  //             content.customer_study_name ? content.customer_study_name : ""
  //           } - ${content.subcategory_name ? content.subcategory_name : ""} `}
  //         </h3>
  //       );
  //       break;
  //     case "ecosystem":
  //       label = (
  //         <h3 className={"category-tag font-weight-semibold mb-0"}>
  //           {`${content.ecosystem_name ? content.ecosystem_name : ""} - ${
  //             content.subcategory_name ? content.subcategory_name : ""
  //           } `}
  //         </h3>
  //       );
  //       break;
  //     default:
  //       break;
  //   }
  //   return label;
  // };

  showMoreHandler = (id) => {
    let boardDetails =
      this.props.board && this.props.board.boardDetails
        ? this.props.board.boardDetails
        : [];
    this.props.getBoardContentComments(boardDetails.id, id);
    this.setState({
      showMoreCommentsModal: true,
    });
  };

  closeShowMoreCommentsModal = () => {
    this.setState({
      showMoreCommentsModal: false,
      showMoreComments: [],
    });
  };

  // toggleDownloadBoardActions = () => {
  //   this.setState({
  //     downloadBoardAction: !this.state.downloadBoardAction,
  //   });
  // };

  componentDidMount() {
    if (
      this.props.notificationData?.notificationMetadata?.comment_type ===
      "general"
    ) {
      this.toggleGeneralComments();
    }
    calculateTimeOnSection.initializeTimer();
  }

  UNSAFE_componentWillReceiveProps() {
    const token = localStorage.getItem("token");
    if (!token) return;

    const tokenDetails = decodeJWT(token);
    const user_id = tokenDetails?.user?.id;

    let boardDetails =
      this.props.board && this.props.board.boardDetails
        ? this.props.board.boardDetails
        : {};
    if (!boardDetails) return;

    const is_owner = boardDetails?.owner?.id == user_id;
    const is_edit_permission = boardDetails.mode?.short_name === "edit";
    this.setState({ hasAccess: is_owner || is_edit_permission });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.boardID != this.state.boardID) {
      let getTime = calculateTimeOnSection.getTime();
      calculateTimeOnSection.initializeTimer();
    }
    const prevCommentType =
      prevProps.notificationData?.notificationMetadata?.comment_type;
    const currentCommentType =
      this.props.notificationData?.notificationMetadata?.comment_type;

    if (currentCommentType === "general" && prevCommentType !== "general") {
      // Runs only when the value changes to "general"
      this.toggleGeneralComments();
    }
  }

  boardPublish = (role) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Add</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="activate-body">
          <h2 className="font-weight-semibold mb-0">
            {isTBRIUser()
              ? constants.MY_BOARDS.LIST.ADMIN_PUBLISH_BOARD_CONFIRMATION
              : constants.MY_BOARDS.LIST.PUBLISH_BOARD_CONFIRMATION}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "owner" },
      dailogModalStyleType: "success-modal",
    });
  };

  boardUnPublish = (role) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Remove</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="activate-body">
          <h2 className="font-weight-semibold mb-0">
            {isTBRIUser()
              ? constants.MY_BOARDS.LIST.ADMIN_UNPUBLISH_BOARD_CONFIRMATION
              : constants.MY_BOARDS.LIST.UNPUBLISH_BOARD_CONFIRMATION}
          </h2>
        </div>
      </>
    );
    this.setState({
      unPublishDailogModal: true,
      unPublishDailogModalContent: dailogModalContent,
      unPublishDailogModalHeader: dailogModalHeader,
      dailogModalConfig: { type: "owner" },
      dailogModalStyleType: "success-modal",
    });
  };
  dailogModalAccept = (config) => {
    this.setState(
      {
        dailogModal: false,
        dailogModalHeader: "",
        dailogModalContent: "",
        dailogModalConfig: {},
      },
      () => {
        this.publishBoard(
          decodeJWT(localStorage.getItem("token")).role?.short_name
        );
      }
    );
  };
  unPublishDailogModalAccept = () => {
    this.setState(
      {
        unPublishDailogModal: false,
        unPublishDailogModalContent: "",
        unPublishDailogModalHeader: "",
        dailogModalConfig: {},
        dailogModalStyleType: "success-modal",
      },
      () => {
        this.unPublishBoard(
          decodeJWT(localStorage.getItem("token")).role?.short_name
        );
      }
    );
  };
  publishBoard = (role) => {
    const requestBody =
      role === "lead" || role == "client_super_admin" || role == "client_admin"
        ? { is_published: 1 }
        : { is_published: 2 };
    BoardServices.boardPublish(this.props.board.boardDetails.id, requestBody)
      .then((res) => {
        this.setState(
          {
            published: true,
            published_at: res.published_at,
          },
          () => {
            Toast(
              role === "lead" ||
                role == "client_super_admin" ||
                role == "client_admin"
                ? constants.MY_BOARDS.PUBLISH.CLIENT_PUBLISH_SUCCESS
                : constants.MY_BOARDS.PUBLISH.ADMIN_PUBLISH_SUCCESS,
              "success"
            );
            /* Redirecting to `Boards` list page after successfully published content*/
            this.props.history &&
              this.props.history.push({
                pathname: formatRoute(
                  constants.APPLICATION_ROUTE.MY_BOARDS.LIST.ROUTE,
                  {}
                ),
                search: "?filter=all",
              });
          }
        );
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  unPublishBoard = (role) => {
    BoardServices.boardUnPublish(this.props.board.boardDetails.id)
      .then((res) => {
        this.setState(
          {
            published: false,
            published_at: res.published_at,
          },
          () => {
            Toast(
              role === "lead" ||
                role == "client_super_admin" ||
                role == "client_admin"
                ? constants.MY_BOARDS.PUBLISH.CLIENT_UNPUBLISH_SUCCESS
                : constants.MY_BOARDS.PUBLISH.ADMIN_UNPUBLISH_SUCCESS,
              "success"
            );
            /* Redirecting to `Boards` list page after successfully published content*/
            this.props.history &&
              this.props.history.push({
                pathname: formatRoute(
                  constants.APPLICATION_ROUTE.MY_BOARDS.LIST.ROUTE,
                  {}
                ),
                search: "?filter=all",
              });
          }
        );
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };

  unPublishDailogModalDecline = () => {
    this.setState({
      unPublishDailogModal: false,
      unPublishDailogModalContent: "",
      unPublishDailogModalHeader: "",
      dailogModalConfig: {},
    });
  };

  render() {
    let tokenDetails = decodeJWT(localStorage.getItem("token"));
    let boardDetails =
      this.props.board && this.props.board.boardDetails
        ? this.props.board.boardDetails
        : [];

    let boardContent =
      this.props.board && this.props.board.boardContent
        ? this.props.board.boardContent
        : [];

    let boardGeneralComments =
      this.props.board && this.props.board.boardGeneralComments
        ? this.props.board.boardGeneralComments
        : [];

    let boardContentComments =
      this.props.board && this.props.board.boardContentComments
        ? this.props.board.boardContentComments
        : [];
    let boardContentDates = boardContent.map((b) => b.published_date_group);

    boardContentDates = [...new Set(boardContentDates)];
    let aclPermissionKey;
    let aclResource;
    if (boardDetails?.owner?.id === this.props.home.loginUserSuccess?.id) {
      aclPermissionKey = constants.ACL.PERMISSION_KEY;
      aclResource = constants.ACL.MY_BOARDS_RESOURCE;
    }

    if (boardDetails.type === "shared") {
      switch (boardDetails.mode.short_name) {
        case constants.MY_BOARDS_SHARE_MODE.EDIT.SLUG:
          aclPermissionKey = constants.ACL.BOARD_PERMISSION_KEY;
          aclResource = constants.ACL.MY_BOARDS_RESOURCE_EDIT;
          break;
        case constants.MY_BOARDS_SHARE_MODE.VIEW.SLUG:
          aclPermissionKey = constants.ACL.BOARD_PERMISSION_KEY;
          aclResource = constants.ACL.MY_BOARDS_RESOURCE_VIEW;
          break;
        case constants.MY_BOARDS_SHARE_MODE.COMMENT.SLUG:
          aclPermissionKey = constants.ACL.BOARD_PERMISSION_KEY;
          aclResource = constants.ACL.MY_BOARDS_RESOURCE_COMMENT;
          break;
        case constants.MY_BOARDS_SHARE_MODE.VIEW_COMMENT.SLUG:
          aclPermissionKey = constants.ACL.BOARD_PERMISSION_KEY;
          aclResource = constants.ACL.MY_BOARDS_RESOURCE_VIEW_COMMENT;
          break;
        default:
          aclPermissionKey = constants.ACL.BOARD_PERMISSION_KEY;
          aclResource = constants.ACL.MY_BOARDS_RESOURCE_VIEW;
          break;
      }
    }

    let isShareAccess = permissionCheck(
      aclPermissionKey,
      aclResource?.TITLE,
      aclResource?.ACTIONS.SHARE
    );

    //if theres pin permissions ,then unpin is allowed
    let isUnpinAllowed = permissionCheck(
      aclPermissionKey,
      aclResource?.TITLE,
      aclResource?.ACTIONS.PIN
    );

    let isDeleteAllowed = permissionCheck(
      aclPermissionKey,
      aclResource?.TITLE,
      aclResource?.ACTIONS.DELETE
    );

    let isCommentViewAllowed = permissionCheck(
      aclPermissionKey,
      aclResource?.TITLE,
      aclResource?.ACTIONS.COMMENT_LIST
    );
    let isCommentAddAllowed = permissionCheck(
      aclPermissionKey,
      aclResource?.TITLE,
      aclResource?.ACTIONS.COMMENT_CREATE
    );
    let isCommentEditAllowed = permissionCheck(
      aclPermissionKey,
      aclResource?.TITLE,
      aclResource?.ACTIONS.COMMENT_EDIT
    );
    let isCommentDeleteAllowed = permissionCheck(
      aclPermissionKey,
      aclResource?.TITLE,
      aclResource?.ACTIONS.COMMENT_DELETE
    );
    let isCommentStatusChangeAllowed = permissionCheck(
      aclPermissionKey,
      aclResource?.TITLE,
      aclResource?.ACTIONS.COMMENT_STATUS
    );
    const roleName = decodeJWT(localStorage.getItem("token")).role?.short_name;
    let isDisplayComments = boardDetails.display_comments === 0 ? false : true;
    if (boardDetails.id) DocumentTitle(`${boardDetails.name}`);

    const isPublishableByClient =
      roleName === "lead" ||
      roleName === "client_super_admin" ||
      roleName === "client_admin";

    const isPublishableByAdmin = ["admin", "editor"].includes(
      tokenDetails.role?.short_name
    );

    const isBoardPublished =
      this.props.board &&
      (this.props.board.boardDetails.is_published || this.state.published);

    const getButtonLabel = () => {
      if (isPublishableByClient) {
        return isBoardPublished
          ? constants.MY_BOARDS.PUBLISH.CLIENT_UNPUBLISHED
          : constants.MY_BOARDS.PUBLISH.CLIENT_PUBLISH;
      }
      if (isPublishableByAdmin) {
        return isBoardPublished
          ? constants.MY_BOARDS.PUBLISH.ADMIN_UNPUBLISHED
          : constants.MY_BOARDS.PUBLISH.ADMIN_PUBLISH;
      }
      return "";
    };

    const handleButtonClick = () => {
      if (isBoardPublished) {
        this.boardUnPublish();
      } else {
        this.boardPublish(tokenDetails.role.name);
      }
    };

    return (
      <aside className="dash_board_content inner-sidebar-right-content">
        {boardDetails.id && boardContent && (
          <div className="">
            <div className="row board-details-right-content board-details">
              <aside
                className={
                  "content-wrapper " +
                  (this.props.isRelatedInsightsVisible
                    ? "col-lg-12"
                    : "col-lg-12")
                }
              >
                <div className="board-details-card-wrapper">
                  <div className="board-details-card border-top-0">
                    <div
                      className="sticky-board-details-header"
                      ref={(el) => (this.headerRef.current = el)}
                    >
                      {boardDetails.id && (
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center flex-wrap">
                            <div className="board-name-block d-flex align-items-center">
                              <div className="icon-wrapper">
                                <span className="material-icons-sharp icon ml-0">
                                  push_pin
                                </span>
                              </div>
                              <div
                                className=""
                                style={{ "overflow-wrap": "break-word" }}
                              >
                                <h2 className="mb-0 board-name font-weight-bold text-black2">
                                  {boardDetails.name}
                                </h2>
                              </div>
                            </div>
                            <div className="ml-5">
                              <div className="watch_follow_anchor fs-12">
                                <span className="text-ligntgrey">
                                  Items Available :{" "}
                                </span>
                                <span className="text-black2">
                                  {boardDetails.total_pinned_content_count
                                    ? boardDetails.total_pinned_content_count
                                    : 0}
                                </span>
                              </div>
                            </div>
                            {boardDetails.last_updated_at && (
                              <div className="ml-5">
                                <div className="fs-12">
                                  <span className="text-ligntgrey">
                                    Last Updated :{" "}
                                  </span>
                                  <span className="text-black2">
                                    {ComapnyDetailsDateFormatter(
                                      boardDetails.updated_at,
                                      false
                                    )}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                          <div>
                            {/* showing the Publish or Published button only for an owner of the board */}
                            {boardDetails.owner.id === tokenDetails.user.id &&
                              (isPublishableByClient ||
                                isPublishableByAdmin) && (
                                <AclAction
                                  type={constants.ACL.PERMISSION_KEY}
                                  entity={
                                    constants.ACL.MY_BOARDS_RESOURCE.TITLE
                                  }
                                  action={
                                    constants.ACL.MY_BOARDS_RESOURCE.ACTIONS
                                      .PUBLISH
                                  }
                                >
                                  <button
                                    tbrentitytype={
                                      constants.ACL.ENTITY_TYPE.BUTTON
                                    }
                                    className="btn btn-primary btn-md icon-text-primary-btn icon-fs-14"
                                    type="button"
                                    onClick={handleButtonClick}
                                  >
                                    {getButtonLabel()}
                                  </button>
                                </AclAction>
                              )}
                            {/* {boardDetails.owner.id === tokenDetails.user.id ? ( */}
                          </div>
                        </div>
                      )}
                      <div className="d-flex flex-wrap">
                        <div className="mr-4 pr-3 mt-4">
                          <div className="border-search box-shadow-none-search">
                            <Search
                              handleSearch={this.props.handleSearch}
                              handleInput={this.props.handleInput}
                              value={this.props.keyword}
                              placeHolder={"Search Keyword"}
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-wrap align-items-center mt-4">
                          {isUnpinAllowed && (
                            <div className="d-inline-flex">
                              <span
                                className="d-inline-flex"
                                data-for={`tooltip-display-comments`}
                                data-tip={
                                  isDisplayComments
                                    ? "Disable Comments"
                                    : "Enable Comments"
                                }
                                data-iscapture="true"
                              >
                                <label className="custom-switch-checkbox custom-switch-checkbox-2">
                                  <input
                                    type="checkbox"
                                    data-testid="testCommentToggle"
                                    checked={isDisplayComments}
                                    onChange={this.props.displayCommentsHandler}
                                    disabled={
                                      boardDetails.owner.id !==
                                      tokenDetails.user.id
                                        ? !isDisplayComments
                                        : false
                                    }
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </span>
                              <CustomReactTooltip
                                id={`tooltip-display-comments`}
                                multiline={true}
                              />
                            </div>
                          )}
                          {isCommentViewAllowed && (
                            <div className="text-right ">
                              <button
                                className="btn btn-general-comments"
                                onClick={this.toggleGeneralComments}
                                data-testid="testbtnComment"
                              >
                                General Comments ({boardGeneralComments.length}){" "}
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="d-flex flex-wrap align-items-center ml-4 pl-2 mt-4">
                          {permissionCheck(
                            constants.ACL.PERMISSION_KEY,
                            constants.ACL.MY_BOARDS_RESOURCE.TITLE,
                            constants.ACL.MY_BOARDS_RESOURCE.ACTIONS.SHARE
                          ) &&
                            boardDetails.type === "owner" && (
                              <div className="d-inline-flex align-items-center">
                                <p className="share-info mb-0 d-inline-flex align-items-center">
                                  {boardDetails.shared_users.length > 0 && (
                                    <>Shared with</>
                                  )}
                                  {boardDetails.is_shared_with_all && (
                                    <b
                                      className="ml-1"
                                      onClick={(e) => {
                                        isShareAccess &&
                                          this.props.shareHandler(
                                            e,
                                            boardDetails
                                          );
                                      }}
                                    >
                                      ALL at My Company
                                    </b>
                                  )}
                                  {boardDetails.shared_users &&
                                    boardDetails.shared_users.length > 0 && (
                                      <span
                                        className="ml-1 mr-3 d-inline-flex"
                                        onClick={(e) => {
                                          isShareAccess &&
                                            this.props.shareHandler(
                                              e,
                                              boardDetails
                                            );
                                        }}
                                      >
                                        {boardDetails.shared_users
                                          .slice(0, 3)
                                          .map((s, i) => (
                                            <>
                                              <span
                                                className="material-icons-round icon-fs-18"
                                                key={i}
                                                aria-hidden="true"
                                                data-for={`tooltip-${Slugify(
                                                  s.email
                                                )}`}
                                                data-tip={`${s.first_name} ${s.last_name} (${s.email})`}
                                                data-iscapture="true"
                                              >
                                                person
                                              </span>
                                              <CustomReactTooltip
                                                id={`tooltip-${Slugify(
                                                  s.email
                                                )}`}
                                                multiline={true}
                                              />
                                            </>
                                          ))}
                                      </span>
                                    )}
                                  {boardDetails.owner.id ===
                                    this.props.home.loginUserSuccess.id && (
                                    <AclAction
                                      type={aclPermissionKey}
                                      entity={aclResource?.TITLE}
                                      action={aclResource?.ACTIONS.SHARE}
                                    >
                                      <span
                                        className="action cursor-pointer share-permission-link d-inline-flex align-items-center"
                                        tbrentitytype={
                                          constants.ACL.ENTITY_TYPE.SPAN
                                        }
                                        onClick={(e) => {
                                          this.props.shareHandler(
                                            e,
                                            boardDetails
                                          );
                                        }}
                                      >
                                        <span
                                          className="material-icons icon-fs-18 icon"
                                          data-testid="shareBoard"
                                        >
                                          manage_accounts
                                        </span>
                                        Edit sharing & permissions
                                      </span>
                                    </AclAction>
                                  )}
                                </p>
                              </div>
                            )}
                        </div>

                        <div>
                          {isCommentViewAllowed && isDisplayComments && (
                            <>
                              {this.state.showGeneralComments && (
                                <div className="general-comments-widget">
                                  <div className="d-flex flex-column h-100">
                                    <div className="">
                                      <div className="general-comments border-bottom general-comments-header w-100 d-flex align-items-center">
                                        <span className="material-icons-round text-black2 lg-icon">
                                          question_answer
                                        </span>
                                        <h2
                                          className="mb-0 text-black2 font-weight-bold"
                                          data-testid="addTestComment"
                                        >
                                          General Comments
                                        </h2>

                                        <span
                                          className="icon-wrapper close-board-comments ml-auto"
                                          onClick={() => {
                                            this.toggleGeneralComments();
                                            this.props.notificationItem(null);
                                          }}
                                        >
                                          <span className="material-icons-round icon ml-0">
                                            close
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                    <div className="flex-grow-1">
                                      {
                                        <BoardDetailsComments
                                          id={boardDetails.id}
                                          comments={boardGeneralComments}
                                          writeComments={isCommentAddAllowed}
                                          openCommentsFlag={true}
                                          toggleCommentsHandler={
                                            this.props
                                              .toggleGeneralCommentsHandler
                                          }
                                          writeCommentsFlag={true}
                                          toggleWriteCommentsHandler={
                                            this.props
                                              .toggleWriteGeneralCommentsHandler
                                          }
                                          handleCommentChangeHandler={
                                            this.props
                                              .handleGeneralCommentChangeHandler
                                          }
                                          handleCommentTypeChangeHandler={
                                            this.props
                                              .handleGeneralCommentTypeChangeHandler
                                          }
                                          comment={boardDetails.comment}
                                          is_public={boardDetails.is_public}
                                          handleCommentSaveHandler={
                                            this.props
                                              .handleGeneralCommentSaveHandler
                                          }
                                          showMore={false}
                                          toggleCommentTypeDropdown={
                                            this.props
                                              .toggleGeneralCommentTypeDropdown
                                          }
                                          toggleCommentType={
                                            this.props.toggleGeneralCommentType
                                          }
                                          isCommentViewAllowed={
                                            isCommentViewAllowed
                                          } // Added for unit test case
                                          isCommentAddAllowed={
                                            isCommentAddAllowed
                                          }
                                          isCommentEditAllowed={
                                            isCommentEditAllowed
                                          }
                                          isCommentDeleteAllowed={
                                            isCommentDeleteAllowed
                                          }
                                          isCommentStatusChangeAllowed={
                                            isCommentStatusChangeAllowed
                                          }
                                          showMoreHandler={null}
                                          totalCount={
                                            boardGeneralComments.length
                                          }
                                          type="general"
                                          handleOpenEditComment={
                                            this.props
                                              .handleOpenEditCommentGeneral
                                          }
                                          handleCommentChange={
                                            this.props
                                              .handleCommentChangeGeneral
                                          }
                                          handleDeleteComment={
                                            this.props
                                              .handleDeleteCommentGeneral
                                          }
                                          handleCloseEditComment={
                                            this.props
                                              .handleCloseEditCommentGeneral
                                          }
                                          handleSaveEditComment={
                                            this.props
                                              .handleSaveEditCommentGeneral
                                          }
                                        />
                                      }
                                    </div>
                                  </div>
                                </div>
                              )}

                              <CustomReactTooltip
                                id={`tooltip-${Slugify(
                                  constants.MY_BOARDS.COMMENT
                                    .GENERAL_COMMENTS_TOOLTIP
                                )}`}
                                multiline={true}
                              />
                            </>
                          )}
                        </div>
                        {
                          <div className="btn-group mt-4 ml-4">
                            <div
                              class="alert alert-light p-0 mt-2"
                              role="alert"
                            >
                              {this.props.board &&
                                (this.props.board.boardDetails.is_published !=
                                  0 ||
                                  this.state.published) &&
                                `This Board is published by ${this.props.board.boardDetails.owner.first_name} ${this.props.board.boardDetails.owner.last_name}`}
                              {this.state.published_at ||
                              (this.props.board.boardDetails.published_at &&
                                typeof this.props.board.boardDetails
                                  .published_at == "string")
                                ? ` on ${ComapnyDetailsDateTimeFormatter(
                                    this.props.board.boardDetails
                                      .published_at ||
                                      this.state.published_at ||
                                      new Date()
                                  )}`
                                : ""}
                            </div>
                          </div>
                        }
                      </div>
                    </div>

                    <div className="board-details-body">
                      <DragDropContext
                        onBeforeCapture={(e) => {
                          if (e.draggableId.includes("maincategory")) {
                            this.setState({ isMainItemOpen: false });
                          } else {
                            this.setState({ isSubItemOpen: false });
                          }
                        }}
                        onDragEnd={(result) => {
                          if (result.type === "category") {
                            this.setState({ isMainItemOpen: true });
                          } else {
                            this.setState({ isSubItemOpen: true });
                          }
                          this.state.hasAccess &&
                            this.props.handleOnDragEnd(result);
                        }}
                      >
                        <Droppable
                          droppableId="row-category"
                          type="category"
                          direction="vertical"
                        >
                          {(provided, snapshot) => (
                            <div
                              isDraggingOver={snapshot.isDraggingOver}
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {this.props.boardCategories &&
                                this.props.boardCategories?.map(
                                  (category, index) => (
                                    <Draggable
                                      draggableId={`${category.id?.toString()}_maincategory`}
                                      index={index}
                                      type="category"
                                      key={category.id}
                                      isDragDisabled={!this.state.hasAccess}
                                    >
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className={
                                            this.state.isMainItemOpen
                                              ? `board-list-item`
                                              : `board-list-item-drag-fix`
                                          }
                                          key={`${category.id}_maincategory`}
                                          id={category.id}
                                        >
                                          <MainCategoryItem
                                            {...{
                                              provided,
                                              category,
                                              isUnpinAllowed,
                                              isCommentAddAllowed,
                                              isDisplayComments,
                                              isCommentViewAllowed,
                                              isCommentDeleteAllowed,
                                              isCommentStatusChangeAllowed,
                                              isCommentEditAllowed,
                                              hasAccess: this.state.hasAccess,
                                              isSubItemOpen:
                                                this.state.isSubItemOpen,
                                              isMainItemOpen:
                                                this.state.isMainItemOpen,
                                              parentsProps: this.props,
                                              removeCategoryConfirmation:
                                                this.props
                                                  .removeCategoryConfirmation,
                                              removeContentFromSubcategory:
                                                this.props
                                                  .removeContentFromSubcategory,
                                              showMoreHandler:
                                                this.showMoreHandler,
                                            }}
                                          />
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                                )}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                      {this.props.boardCategories.length === 0 && (
                        <>
                          <h3 className="text-center" data-testid="noItemFound">
                            No Items Found.
                          </h3>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </aside>
            </div>
            <Modal
              isOpen={this.state.showMoreCommentsModal}
              toggle={this.closeShowMoreCommentsModal}
              className={"modal-md custom-modal comments-modal"}
              scrollable={true}
            >
              <ModalBody>
                <div className="bootstrap snippets comment-widget">
                  <div className="">
                    <div className="comment-wrapper">
                      {boardContentComments.length > 0 && (
                        <>
                          <ul className="list-group mt-2">
                            {boardContentComments?.map((comment, key) => (
                              <BoardDetailsComment
                                id={comment.content_id}
                                key={comment.id}
                                comment={comment}
                                toggleCommentTypeDropdown={
                                  this.props
                                    .toggleContentShowMoreCommentTypeDropdown
                                }
                                toggleCommentType={
                                  this.props.toggleContentShowMoreCommentType
                                }
                                isCommentViewAllowed={isCommentViewAllowed} // Added for unit test case
                                isCommentAddAllowed={isCommentAddAllowed}
                                isCommentEditAllowed={isCommentEditAllowed}
                                isCommentDeleteAllowed={isCommentDeleteAllowed}
                                isCommentStatusChangeAllowed={
                                  isCommentStatusChangeAllowed
                                }
                                handleOpenEditComment={
                                  this.props.handleOpenEditCommentContent
                                }
                                handleCommentChange={
                                  this.props.handleCommentChangeContent
                                }
                                handleDeleteComment={
                                  this.props.handleDeleteCommentContent
                                }
                                handleCloseEditComment={
                                  this.props.handleCloseEditCommentContent
                                }
                                handleSaveEditComment={
                                  this.props.handleSaveEditCommentContent
                                }
                              />
                            ))}
                          </ul>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </ModalBody>
            </Modal>
            <DailogNew
              isOpen={this.state.dailogModal}
              accept={this.dailogModalAccept}
              decline={this.dailogModalDecline}
              header={this.state.dailogModalHeader}
              content={this.state.dailogModalContent}
              config={this.state.dailogModalConfig}
              modalStyleType={this.state.dailogModalStyleType}
            />
            <DailogNew
              isOpen={this.state.unPublishDailogModal}
              accept={this.unPublishDailogModalAccept}
              decline={this.unPublishDailogModalDecline}
              header={this.state.unPublishDailogModalHeader}
              content={this.state.unPublishDailogModalContent}
              config={this.state.dailogModalConfig}
              modalStyleType={this.state.dailogModalStyleType}
            />
          </div>
        )}
      </aside>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    board: state.board,
    home: state.home,
    notificationData: state.notification,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    categories: (payload) => {
      dispatch(categories(payload));
    },
    notificationItem: (item) => {
      dispatch(notificationItem(item));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BoardDashboardContent);
