import React from "react";
import { Multiselect } from "react-widgets";
import "react-widgets/dist/css/react-widgets.css";

const CustomMultiSelect = (props) => {
  return (
    <Multiselect
      disabled={props.disabled}
      filter
      data={props.data}
      valueField="id"
      textField="name"
      onChange={(value) => props.handleChange(value)}
      defaultValue={
        props.data && props.data.find((d) => d.is_selected === true)
          ? props.data.filter((d) => d.is_selected === true).map((a) => a.id)
          : []
      }
      value={
        props.data && props.data.find((d) => d.is_selected === true)
          ? props.data.filter((d) => d.is_selected === true).map((a) => a.id)
          : []
      }
      placeholder={props.placeholder ? props.placeholder : ""}
      containerClassName={props.className ? props.className : ""}
    />
  );
};

export default CustomMultiSelect;
