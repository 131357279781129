import { combineReducers } from "redux";
import * as DETAILS_REF from "./reducerReference/customerStudyDetailsReference";

const customerStudyCategories = DETAILS_REF.customerStudyCategories;
const customerStudyDetails = DETAILS_REF.customerStudyDetails;
const categories = DETAILS_REF.categories;
const customerStudyContent = DETAILS_REF.customerStudyContent;

const customerStudyDetailsReducer = combineReducers({
  customerStudyCategories,
  customerStudyDetails,
  categories,
  customerStudyContent,
});

export default customerStudyDetailsReducer;
