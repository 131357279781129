import React, { Component } from "react";
import { connect } from "react-redux";
import { formatRoute } from "react-router-named-routes";
import CustomBreadcrumb from "../Components/CustomBreadcrumb";
import Dailog from "../Components/Dailog";
import Notification from "../Components/Notification";
import { ClientServices } from "../Services/Client";
import { clientRoles, clients } from "../Store/Actions/Client";
import { activeMenuTab } from "../Store/Actions/User";
import constants from "../Utils/constants";
import DocumentTitle from "../Utils/DocumentTitle";
import Toast from "../Utils/Toast";
import CustomDropdownList from "../Components/CustomDropdownList";
import decodeJWT from "../lib/decodeJWT";
import CustomReactTooltip from "../Components/CustomReactTooltip";

class ManageClientUser extends Component {
  state = {
    clientId: "",
    userFirstName: "",
    userLastName: "",
    userEmail: "",
    userContactNumber: "",
    userAddress: "",
    userRoleId: null,
    formErrors: {},
    dailogModal: false,
    dailogModalContent: "",
    dailogModalHeader: "",
    dailogModalConfig: {},
    isFormSubmitted: false,
    apiError: null,
    is_set_password_user: false,
    is_sso_user: false,
  };
  changeRoute = () => {
    let clientId = this.props.match.params.clientId
      ? this.props.match.params.clientId
      : null;
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.CLIENT_USER.LIST.ROUTE,
        { clientId: clientId }
      ),
      search: "?page=1&page_size=10",
    });
  };
  async componentDidMount() {
    const { activeMenuTab, match, setClientRoles } = this.props;

    // Set the active menu tab
    activeMenuTab(constants.APPLICATION_ROUTE.CLIENT_USER.LIST.ROUTE);

    // Extract clientId and userId from route params
    const clientId = match.params.clientId || null;
    const userId = match.params.id || null;

    // Update state with clientId
    this.setState({ clientId });

    try {
      // Fetch and process client user roles
      const res = await ClientServices.getClientUserRoles();
      const response = res?.data || [];
      const roles = response
        .filter((role) => role.short_name !== "client_super_admin") // Remove client_super_admin
        .map((role) => ({
          ...role,
          name: role.short_name === "client_admin" ? "Admin" : role.name, // Update name for client_admin
          is_selected: false,
        }))
        .sort((a, b) => b.id - a.id); // Sort by id in descending order
      // Set roles in props
      setClientRoles(roles);
    } catch (error) {
      // Handle error with Toast
      const errorMessage =
        error?.data?.error || constants.ERROR.SOMETHING_WENT_WRONG;
      Toast(errorMessage, "error");
    }

    let tokenDetails = decodeJWT(localStorage.getItem("token"));

    let clientIdToken = tokenDetails.client?.id ?? null;

    // Prepopulate data if userId is present else getting client details
    if (userId) {
      this.prepopulateData(userId);
    } else {
      this.getClientData(clientIdToken);
    }
  }
  getClientData = (clientId) => {
    ClientServices.getClientDetails(clientId)
      .then((res) => {
        let response = res.data || {};
        this.setState({
          is_set_password_user: response.is_set_password,
          is_sso_user: response.is_sso,
        });
      })
      .catch((error) => {
        const errorMessage =
          error?.data?.error || constants.ERROR.SOMETHING_WENT_WRONG;
        Toast(errorMessage, "error");
      });
  };

  prepopulateData = (userId) => {
    ClientServices.getClientUserDetails(userId)
      .then((res) => {
        const response = res.data?.data || {};
        const {
          first_name = "",
          last_name = "",
          email = "",
          mobile = "",
          address = "",
          role = {},
          is_set_password = 0,
          is_sso = 0,
        } = response;

        this.setState(
          {
            userFirstName: first_name,
            userLastName: last_name,
            userEmail: email,
            userContactNumber: mobile,
            userAddress: address,
            userRoleId: role.id || "",
            isFormSubmitted: false,
            is_set_password_user: is_set_password,
            is_sso_user: is_sso,
            apiError: null,
            formErrors: {},
          },
          () => {
            this.setRole();
          }
        );
      })
      .catch((error) => {
        const errorMessage =
          error?.data?.error || constants.ERROR.SOMETHING_WENT_WRONG;
        Toast(errorMessage, "error");
      });
  };

  handleInputChange = (e) => {
    this.setState(
      {
        ...this.state,
        [e.target.id]: e.target.value,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };
  handleRoleChange = (e) => {
    this.setState(
      {
        userRoleId: e.id,
      },
      () => {
        this.setRole();
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };
  setRole = () => {
    let clientRoles = [...this.props.client.clientRoles];
    clientRoles.forEach((c) => {
      c.is_selected = c.id === this.state.userRoleId ? true : false;
    });
    this.props.setClientRoles(clientRoles);
  };

  isFormValid = () => {
    let formError = false;
    let userFirstNameError = false;
    let userLastNameError = false;
    let userEmailError = false;
    let userContactNumberError = false;
    let userRoleIdError = false;
    if (this.state.userFirstName.trim() === "") {
      userFirstNameError = true;
      formError = true;
    }
    if (this.state.userLastName.trim() === "") {
      userLastNameError = true;
      formError = true;
    }
    if (this.state.userEmail.trim() === "") {
      userEmailError = true;
      formError = true;
    } else {
      var re = constants.REG_EX.EMAIL_REG_EX;
      userEmailError = !re.test(String(this.state.userEmail).toLowerCase());
      if (userEmailError === true) {
        formError = true;
      }
    }
    if (this.state.userContactNumber !== "") {
      var re_phone = constants.REG_EX.PHONE_REG_EX;
      userContactNumberError = !re_phone.test(this.state.userContactNumber);
      if (userContactNumberError === true) {
        formError = true;
      }
    }
    if (this.state.userRoleId === null) {
      userRoleIdError = true;
      formError = true;
    }
    this.setState({
      ...this.state,
      formErrors: {
        ...this.state.formErrors,
        userFirstNameError: userFirstNameError,
        userLastNameError: userLastNameError,
        userEmailError: userEmailError,
        userContactNumberError: userContactNumberError,
        userRoleIdError: userRoleIdError,
      },
      apiError: null,
    });
    return formError;
  };
  handleSubmit = () => {
    this.setState(
      {
        isFormSubmitted: true,
      },
      () => {
        if (!this.isFormValid()) {
          let userId = this.props.match.params.id
            ? this.props.match.params.id
            : null;
          let roles =
            this.props.client && this.props.client.clientRoles
              ? this.props.client.clientRoles
              : [];
          let userRoleId = roles.find(
            (r) => r.short_name === constants.ROLES.CLIENT_USER.MEMBER
          )
            ? roles.find(
                (r) => r.short_name === constants.ROLES.CLIENT_USER.MEMBER
              ).id
            : null;
          let clientId = this.state.clientId;
          let prepareObject = {
            first_name: this.state.userFirstName,
            last_name: this.state.userLastName,
            email: this.state.userEmail,
            mobile: this.state.userContactNumber,
            address: this.state.userAddress,
            role_id:
              this.state.userRoleId !== null
                ? this.state.userRoleId
                : userRoleId,
          };
          if (userId === null) {
            //create
            ClientServices.addClientUser(prepareObject)
              .then((res) => {
                if (res.data.error !== undefined) {
                  this.setState({
                    apiError:
                      res && res.data && res.data.error
                        ? res.data.error
                        : constants.ERROR.SOMETHING_WENT_WRONG,
                  });
                } else {
                  this.saveClientUserSuccess();
                }
              })
              .catch((error) => {
                this.setState({
                  apiError:
                    error && error.data && error.data.error_description
                      ? error.data.error_description
                      : constants.ERROR.SOMETHING_WENT_WRONG,
                });
              });
          } else {
            ClientServices.editClientUser(userId, prepareObject)
              .then((res) => {
                this.saveClientUserSuccess();
              })
              .catch((error) => {
                this.setState({
                  apiError:
                    error && error.data && error.data.error
                      ? error.data.error
                      : constants.ERROR.SOMETHING_WENT_WRONG,
                });
              });
          }
        }
      }
    );
  };
  saveClientUserSuccess = () => {
    let userId = this.props.match.params.id ? this.props.match.params.id : null;

    Toast(
      userId === null
        ? constants.CLIENT_USER.CREATE.SUCCESS
        : constants.CLIENT_USER.EDIT.SUCCESS,
      "success"
    );
    setTimeout(
      () =>
        this.props.history.push({
          pathname: formatRoute(
            constants.APPLICATION_ROUTE.CLIENT_USER.LIST.ROUTE,
            { clientId: this.state.clientId }
          ),
        }),
      1500
    );
  };
  dailogModalAccept = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };
  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };
  resetForm = () => {
    let userId = this.props.match.params.id ? this.props.match.params.id : null;
    if (userId !== null) this.prepopulateData(userId);
    else {
      this.setState({
        userFirstName: "",
        userLastName: "",
        userEmail: "",
        userContactNumber: "",
        userAddress: "",
        userRoleId: "",
        formErrors: {},
        dailogModal: false,
        dailogModalContent: "",
        dailogModalHeader: "",
        dailogModalConfig: {},
        isFormSubmitted: false,
        apiError: null,
      });
    }
  };
  render() {
    let userId = this.props.match.params.id ? this.props.match.params.id : null;
    DocumentTitle(
      userId === null
        ? constants.APPLICATION_ROUTE.CLIENT_USER.CREATE.HEADER_TITLE
        : constants.APPLICATION_ROUTE.CLIENT_USER.EDIT.HEADER_TITLE
    );
    let roles =
      this.props.client && this.props.client.clientRoles
        ? this.props.client.clientRoles
        : [];
    const updatedRoles = roles
      .filter((role) => role.short_name !== "client_super_admin") // Remove client_super_admin
      .map((role) => ({
        ...role,
        name: role.short_name === "client_admin" ? "Admin" : role.name, // Update name for client_admin
      }))
      .sort((a, b) => b.id - a.id); // Sort by id in descending order

    return (
      <aside className="main_content">
        <div className="m-3 mb-0 bg-white px-3 pb-3 br_8 main_content_height">
          <div className="manage-client">
            <div className="">
              <div className={"add-user-card"}>
                <form className={" form-wrapper"}>
                  <div className="card border-0">
                    <div className="card-header title-cancel-header d-flex align-items-center">
                      <h3 className="text-darkblue2 mb-0 font-weight-bold">
                        {userId === null
                          ? constants.APPLICATION_ROUTE.CLIENT_USER.CREATE
                              .HEADER_TITLE
                          : constants.APPLICATION_ROUTE.CLIENT_USER.EDIT
                              .HEADER_TITLE}
                      </h3>
                      <span
                        className="material-icons-round text-darkblue2 ml-auto cursor-pointer"
                        onClick={this.changeRoute}
                      >
                        close
                      </span>
                    </div>
                    <div className="card-body">
                      <div className="card-body-header">
                        <h5 className="mb-0 font-weight-semibold">
                          User Information
                        </h5>
                      </div>
                      {this.state.apiError !== null && (
                        <div className="container-fluid mt-2">
                          <Notification
                            color={"danger"}
                            message={this.state.apiError}
                          />
                        </div>
                      )}
                      <div className="form-field-wrapper">
                        <div className="row">
                          <div className="col-xl-6 offset-xl-3 col-md-8 offset-md-2">
                            <div className={"row"}>
                              <div className="col-lg-6 col-sm-6 form-group position-relative">
                                <label htmlFor="userFirstName">
                                  First Name{" "}
                                  <span className={"mandatory"}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    this.state.formErrors.userFirstNameError ===
                                    true
                                      ? "is-invalid"
                                      : ""
                                  } ${
                                    this.state.formErrors.userFirstNameError ===
                                    false
                                      ? ""
                                      : ""
                                  }`}
                                  id="userFirstName"
                                  placeholder="First Name"
                                  value={this.state.userFirstName}
                                  onChange={this.handleInputChange}
                                />
                                {this.state.formErrors.userFirstNameError && (
                                  <div className="invalid-tooltip">
                                    {constants.FORM.MANDATORY_FIELD}
                                  </div>
                                )}
                              </div>
                              <div className="col-lg-6 col-sm-6 form-group position-relative">
                                <label htmlFor="userLastName">
                                  Last Name{" "}
                                  <span className={"mandatory"}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    this.state.formErrors.userLastNameError ===
                                    true
                                      ? "is-invalid"
                                      : ""
                                  } ${
                                    this.state.formErrors.userLastNameError ===
                                    false
                                      ? ""
                                      : ""
                                  }`}
                                  id="userLastName"
                                  placeholder="Last Name"
                                  value={this.state.userLastName}
                                  onChange={this.handleInputChange}
                                />
                                {this.state.formErrors.userLastNameError && (
                                  <div className="invalid-tooltip">
                                    {constants.FORM.MANDATORY_FIELD}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className={"row"}>
                              <div className="col-lg-6 col-sm-6 form-group position-relative">
                                <label for="segments">
                                  Role <span className={"mandatory"}>*</span>
                                  <span
                                    data-tip=""
                                    data-for="roles-info"
                                    style={{
                                      color: "#1E81E8",
                                      verticalAlign: "middle",
                                    }}
                                    className="material-icons-round icon-fs-14 ml-1 cursor-pointer"
                                  >
                                    info
                                  </span>
                                  <CustomReactTooltip
                                    id="roles-info"
                                    place="right"
                                  >
                                    <dl className="mb-0">
                                      <dt>Admin:</dt>
                                      <dd>Full access with user management.</dd>
                                      <dt className="mb-1">Creator:</dt>
                                      <dd>
                                        Create and manage content but cannot
                                        manage users.
                                      </dd>
                                      <dt className="mb-1">Viewer:</dt>
                                      <dd>Restricted access to view only.</dd>
                                    </dl>
                                  </CustomReactTooltip>
                                </label>
                                <div
                                  className={`${
                                    this.state.formErrors.userRoleIdError
                                      ? "is-invalid"
                                      : ""
                                  } custom-combobox-list`}
                                >
                                  <CustomDropdownList
                                    data={updatedRoles}
                                    handleChange={this.handleRoleChange}
                                    placeholder={"Select Role"}
                                  />
                                </div>
                                {this.state.formErrors.userRoleIdError && (
                                  <div className="invalid-tooltip display-block d-block">
                                    {constants.FORM.MANDATORY_ROLE_FIELD}
                                  </div>
                                )}
                              </div>
                              <div className="col-lg-6 col-sm-6 form-group position-relative">
                                <label for="userEmail">
                                  Email <span className={"mandatory"}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    this.state.formErrors.userEmailError ===
                                    true
                                      ? "is-invalid"
                                      : ""
                                  } ${
                                    this.state.formErrors.userEmailError ===
                                    false
                                      ? ""
                                      : ""
                                  }`}
                                  id="userEmail"
                                  placeholder="Email (example@domain.com)"
                                  value={this.state.userEmail}
                                  onChange={this.handleInputChange}
                                />
                                {this.state.formErrors.userEmailError && (
                                  <div className="invalid-tooltip">
                                    {constants.FORM.INVALID_EMAIL}
                                  </div>
                                )}
                              </div>
                              <div className="col-sm-6 form-group form-group position-relative">
                                <label for="userContactNumber">
                                  Contact No.
                                </label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    this.state.formErrors
                                      .userContactNumberError === true
                                      ? "is-invalid"
                                      : ""
                                  } ${
                                    this.state.formErrors
                                      .userContactNumberError === false
                                      ? ""
                                      : ""
                                  }`}
                                  id="userContactNumber"
                                  placeholder="Contact No. (99999999)"
                                  value={this.state.userContactNumber}
                                  onChange={this.handleInputChange}
                                />
                                {this.state.formErrors
                                  .userContactNumberError && (
                                  <div className="invalid-tooltip">
                                    {constants.FORM.INVALID_CONTACT_NUMBER}
                                  </div>
                                )}
                              </div>
                              <div className="col-sm-6 form-group position-relative">
                                <label htmlFor="noOfTotalUsers">
                                  Email Invitation
                                </label>
                                <div className="d-flex flex-column flex-sm-row mt-2">
                                  <div className=" d-flex align-items-center w-auto">
                                    <input
                                      className="form-check-input custom-checkbox-new ml-0 mt-0 disable"
                                      type="checkbox"
                                      id="setPassword"
                                      checked={this.state.is_set_password_user}
                                      disabled
                                    />
                                    <label
                                      className="form-check-label ml-1 disable"
                                      htmlFor="setPassword"
                                      style={{ fontSize: "12px" }}
                                    >
                                      Set Password
                                    </label>
                                  </div>
                                  <div className=" d-flex align-items-center w-auto">
                                    <input
                                      className="form-check-input custom-checkbox-new ml-0 mt-0 disable"
                                      type="checkbox"
                                      id="sso"
                                      checked={this.state.is_sso_user}
                                      disabled
                                    />
                                    <label
                                      className="form-check-label ml-1 disable"
                                      htmlFor="sso"
                                      style={{ fontSize: "12px" }}
                                    >
                                      SSO
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-6 offset-xl-3 col-md-8 offset-md-2">
                          <div className="text-right form-buttons">
                            <button
                              type="button"
                              className="btn btn-outline-primary modal-btn"
                              onClick={this.resetForm}
                            >
                              Reset
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary modal-btn"
                              onClick={this.handleSubmit}
                            >
                              {userId ? "Update" : "Save"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Dailog
          isOpen={this.state.dailogModal}
          accept={this.dailogModalAccept}
          decline={this.dailogModalDecline}
          header={this.state.dailogModalHeader}
          content={this.state.dailogModalContent}
          config={this.state.dailogModalConfig}
        />
      </aside>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setClients: (allClients) => {
      dispatch(clients(allClients));
    },
    setClientRoles: (allClientRoles) => {
      dispatch(clientRoles(allClientRoles));
    },
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageClientUser);
